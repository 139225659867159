import { Add, Search } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputAdornment,
  OutlinedInput,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  MenuItem,
  Tooltip,
  Tab,
  Tabs,
  InputLabel,
  Select,
  Drawer,
  IconButton,
  Avatar,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { TadUserDetails } from '../../models/user.model';
import { formatDate, getNameFromId } from './../../common/commonUtil';
import { getAllUsers, updateUser } from '../../../service/userService';
import { ReactComponent as DotsIcon } from '../../assets/dashboard-assets/more_vert.svg';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { LookupTypes } from '../../../common/lookupTypes';
import { getInitials, toUpperFirstChar } from '../../../common/commonUtil';
import Notify from '../../common/notify';
import './users-settings.scss';
import UserPermission from './user-permission';
import globalObject from '../../../common/global-variables';
import AddNewUser from './addNew-user';
import ContactCard from '../common/contact-card';
import TadScreenLoader from '../common/tad-screen-loader';

const Users = () => {
  const [users, setUsers] = useState([] as any[]);
  const [rowData, setRowData] = useState([] as any[]);
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedUser, setSelectedUser] = useState({} as TadUserDetails);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [loading, setLoading] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const USER_HEADERS = [
    `Team Member(${rowData.length})`,
    'Operations',
    'Created On',
    'Status',
    'Role',
    'Action',
  ];
  const [selectedRoleId, setSelectedRoleId] = useState(0);
  const [contact, setContact] = useState({} as any);
  const [contactAnchor, setContactAnchor] = useState<null | HTMLElement>(null);

  type tabType = 'AllUsers' | 'RolePermissionTable';
  const [selectedType, setSelectedType] = useState<tabType>('AllUsers');

  useEffect(() => {
    fetchUsers();
  }, []);
  const requestSearch = (searchedVal: string) => {
    setPage(0);
    let c = users;
    c = c.filter(
      (candidate) =>
        ((candidate.firstName || candidate.lastName) &&
          `${candidate.firstName || ''} ${candidate.lastName || ''}`
            .toLowerCase()
            .includes(searchedVal.toLowerCase())) ||
        (candidate.email && candidate.email.toLowerCase().includes(searchedVal.toLowerCase())),
    );
    setRowData(c);
  };
  const filterRoles = (roleId: number) => {
    if (roleId === 0) {
      setRowData(users);
    } else {
      const filteredRoles: any[] = users.filter((candidate) => {
        return candidate.roles.map((e: any) => e.id).includes(roleId);
      });
      setRowData(filteredRoles);
    }
  };
  const handleNotificationClose = () => {
    setShowNotification(false);
  };
  const fetchUsers = () => {
    setLoading(true);
    getAllUsers()
      .then((resp) => {
        setUsers([...resp.data]);
        setRowData([...resp.data]);
        const initialRoles: { [key: number]: string } = {};
        [...resp.data].forEach((user) => {
          initialRoles[user.id] = user?.roles ? user.roles[0]?.id : '';
        });
        setRoles(initialRoles);
        setLoading(false);
      })
      .catch((_err) => setUsers([]));
  };

  // const handleEdit = (user: UserDetails) => {
  //   setSelectedUser(user);
  //   setIsEdit(true);
  //   setOpen(true);
  // };
  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };
  const handleAddUser = () => {
    setSelectedUser({} as TadUserDetails);
    setIsEdit(false);
    setOpen(true);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // const handleLock = async (user: UserDetails) => {
  //   user.locked = !user.locked;
  //   await updateUser(user);
  //   fetchUsers();
  // };
  const handleDelete = async (user: TadUserDetails) => {
    user.active = false;
    await updateUser(user, true);
    setOpenDeleteDialog(false);
    fetchUsers();
  };
  const handleDeleteDialogClose = () => {
    setOpenDeleteDialog(false);
  };

  const [roles, setRoles] = useState<{ [key: number]: string }>({});

  const renderDialog = () => {
    return (
      <Dialog
        open={openDeleteDialog}
        onClose={handleDeleteDialogClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'>
        <DialogTitle id='alert-dialog-title'>
          {'Are you sure, you want to remove user?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>{`User  ${selectedUser.firstName} will no longer have access to TAD`}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteDialogClose}>Cancel</Button>
          <Button onClick={() => handleDelete({ ...selectedUser })} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  const handleAddUserModalClose = (_event: any, reason: string) => {
    if (reason && reason != 'backdropClick') {
      fetchUsers();
      setShowNotification(true);
    }
    setOpen(false);
  };
  return (
    <div style={{ width: '100%', display: 'flex', gap: '1.63rem', flexDirection: 'column' }}>
      <div
        style={{
          height: '8.8125rem',
          width: '100%',
        }}>
        <Paper
          className='new-job-requisition'
          sx={{
            width: '100%',
            height: '8.8125rem',
          }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginLeft: '1.88rem',
              gap: '7px',
            }}>
            <span style={{ fontSize: '1.25rem', fontWeight: 600 }}>Users</span>
            <span
              style={{
                fontSize: '0.875rem',
                fontWeight: 400,
                color: 'var(--Secondary-Grey-1, var(--Secondary-colors-Grey, #868686))',
              }}>
              Invite or manage your users
            </span>
          </div>
          <div
            style={{
              display: 'flex',
              marginRight: '2.5rem',
              alignItems: 'center',
            }}>
            <div>
              <Tooltip title='Add user'>
                <Button
                  className='button'
                  fullWidth
                  sx={{
                    textTransform: 'none',
                    fontWeight: 400,
                    fontSize: '1rem',
                    height: '3.125rem',
                    color: 'white !important',
                    background: '#1976D2 !important',
                  }}
                  onClick={handleAddUser}>
                  <Add style={{ width: '15px', height: '15px', paddingRight: '4px' }} />
                  <div>
                    <InputLabel sx={{ color: 'white' }}>Add Member</InputLabel>
                  </div>
                </Button>
              </Tooltip>
            </div>
          </div>
        </Paper>
      </div>

      <Drawer
        sx={{ width: '21.0625rem', height: '64rem', flexShrink: 0 }}
        anchor='right'
        open={open}
        onClose={() => setOpen(false)}>
        {
          <>
            <AddNewUser user={selectedUser} isEdit={isEdit} handleClose={handleAddUserModalClose} />
          </>
        }
      </Drawer>

      <Paper className='paper' sx={{ width: '100%' }}>
        <Box sx={{ padding: '10px' }}>
          <div
            style={{
              height: '45px',
              display: 'flex',
              marginLeft: '35px',
              borderBottom: '1px solid rgba(150, 150, 150,1)',
              justifyContent: 'space-between',
            }}>
            <Tabs
              className='sub-tabs'
              value={selectedType}
              onChange={(_e, v) => {
                setSelectedType(v);
              }}>
              <Tab
                className='sub-tab'
                style={selectedType == 'AllUsers' ? { color: '#1976D2' } : { color: 'black' }}
                label={'All Users'}
                value={'AllUsers'}
              />
              <Tab
                className='sub-tab'
                style={
                  selectedType == 'RolePermissionTable' ? { color: '#1976D2' } : { color: 'black' }
                }
                label={'Role & Permissions'}
                value={'RolePermissionTable'}
              />
            </Tabs>
            <div style={{ display: 'flex', gap: '1rem' }}>
              {selectedType == 'AllUsers' && (
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Select
                    size='small'
                    displayEmpty
                    value={selectedRoleId}
                    onChange={(e: any) => {
                      setSelectedRoleId(e.target.value);
                      filterRoles(e.target.value);
                    }}
                    sx={{
                      background: 'white',
                      height: '35px',
                      marginBottom: 2,
                      width: '200px',
                    }}
                    renderValue={(selected) => {
                      return (
                        <p>
                          <FilterAltIcon
                            className='filter-Alt-Icon'
                            fontSize='small'
                            sx={{ marginRight: '10px', color: 'rgb(25, 118, 210)' }}
                          />
                          <span className='status'></span>
                          {selected === 0
                            ? 'Filter By: Role'
                            : toUpperFirstChar(getNameFromId(selected, LookupTypes.TAD_ROLE))}
                        </p>
                      );
                    }}>
                    <MenuItem value={0}>None</MenuItem>
                    {globalObject.lookupvalues[LookupTypes.TAD_ROLE].map(
                      (source: any, index: number) => (
                        <MenuItem key={index} value={source.id}>
                          {toUpperFirstChar(source.name)}
                        </MenuItem>
                      ),
                    )}
                  </Select>
                </div>
              )}

              <OutlinedInput
                className='input-field'
                sx={{ background: 'white', height: '35px' }}
                id='table-search'
                type='text'
                placeholder='Search...'
                onChange={(event) => requestSearch(event.target.value)}
                endAdornment={
                  <InputAdornment position='end'>
                    <Search sx={{ color: '#1976D2' }} />
                  </InputAdornment>
                }
              />
            </div>
          </div>
          {selectedType == 'AllUsers' ? (
            <div style={{ padding: '20px', marginTop: '10px' }}>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}></div>
              <TableContainer>
                <Table aria-label='simple table'>
                  <TableHead>
                    <TableRow>
                      {USER_HEADERS.map((userHeader) => (
                        <TableCell className='user-text' key={userHeader}>
                          {userHeader}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(rowsPerPage > 0
                      ? rowData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      : rowData
                    ).map((user) => (
                      <TableRow
                        key={user.id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            {user.profilePicture !== null || user.profilePicture?.length > 0 ? (
                              <Avatar
                                style={{ marginRight: '10px' }}
                                alt='Remy Sharp'
                                src={user.profilePicture}
                              />
                            ) : (
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  width: '40px',
                                  height: '40px',
                                  borderRadius: '50%',
                                  backgroundColor: '#1976D2',
                                  color: 'white',
                                  fontSize: '1rem',
                                  marginRight: '10px',
                                }}>
                                {getInitials(user)}
                              </div>
                            )}
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                              }}>
                              <span
                                className='custom-text'
                                style={{ cursor: 'pointer' }}
                                onClick={(e) => {
                                  setContact(user);
                                  setContactAnchor(e.currentTarget);
                                }}>
                                {user.firstName + ' ' + user.lastName}
                              </span>
                              <span
                                style={{
                                  color: 'var(--Secondary-colors-Grey, #868686)',
                                  fontFamily: 'Roboto',
                                  fontSize: 'var(--Font-size-P, 1rem)',
                                  fontStyle: 'normal',
                                  fontWeight: 400,
                                  lineHeight: 'normal',
                                }}>
                                {user.email}
                              </span>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell>
                          {getNameFromId(user.operations, LookupTypes.OPERATION)}
                        </TableCell>
                        <TableCell>{formatDate(user.createdOn)}</TableCell>
                        <TableCell>
                          <Select
                            fullWidth
                            sx={{
                              color: 'var(--Primary-Dark-grey-500, #333)',
                              fontFamily: 'var(--Font, Roboto)',
                              fontSize: 'var(--Font-size-small, 0.8125rem)',
                              fontStyle: 'normal',
                              fontWeight: 400,
                              lineHeight: '1.25rem',
                              letterSpacing: '0.04063rem',
                              borderRadius: '0.3125rem',
                              background:
                                user.active.toString() === 'true'
                                  ? 'rgba(122, 224, 139, 0.59)'
                                  : 'var(--Secondary-colors-Grey-3, #F1F4F8)',
                            }}
                            value={user.active}
                            onChange={(e) => {
                              user.active = e.target.value === 'true' ? true : false;
                              setLoading(true);
                              updateUser(user)
                                .then(() => {
                                  setLoading(false);
                                })
                                .catch(() => {
                                  user.active = !user.active;
                                  setLoading(false);
                                });
                            }}
                            displayEmpty>
                            <MenuItem value='true'>ACTIVE</MenuItem>
                            <MenuItem value='false'>IN ACTIVE</MenuItem>
                          </Select>
                        </TableCell>
                        <TableCell>
                          <Select
                            fullWidth
                            displayEmpty
                            value={roles[user.id]}
                            onChange={(e) => {
                              const newRole = e.target.value;
                              setRoles((prev) => ({ ...prev, [user.id]: String(newRole) }));
                              const selectedRole = globalObject.lookupvalues[
                                LookupTypes.TAD_ROLE
                              ].find((r: any) => r.id === e.target.value);
                              setLoading(true);
                              user.roles = selectedRole;
                              updateUser(user, true)
                                .then(() => setLoading(false))
                                .catch(() => {});
                            }}
                            sx={{
                              fontFamily: 'var(--Font, Roboto)',
                              fontSize: 'var(--Font-size-small, 0.8125rem)',
                              fontStyle: 'normal',
                              fontWeight: 400,
                              lineHeight: '1.25rem',
                              letterSpacing: '0.04063rem',
                              height: '2.5rem',
                              borderRadius: '0.625rem',
                              border: '1px solid #C1C0C0',
                              background: ' #FFF',
                            }}>
                            <MenuItem value='' disabled selected>
                              Roles
                            </MenuItem>
                            {globalObject.lookupvalues[LookupTypes.TAD_ROLE].map((role: any) => (
                              <MenuItem key={role.id} value={role.id}>
                                {toUpperFirstChar(role.name)}
                              </MenuItem>
                            ))}
                          </Select>
                        </TableCell>

                        {/* <TableCell>
                          <IconButton onClick={() => handleEdit(user)}>
                            <Edit />
                          </IconButton>
                          <IconButton
                            onClick={() => {
                              setOpenDeleteDialog(true);
                              setSelectedUser(user);
                            }}>
                            <Delete />
                          </IconButton>

                          <IconButton onClick={() => handleLock({ ...user })}>
                            {user.locked ? (
                              <Tooltip title='Un-block user'>
                                <Lock sx={{ color: '#DC4C64' }} />
                              </Tooltip>
                            ) : (
                              <Tooltip title='Block user'>
                                <LockOpen />
                              </Tooltip>
                            )}
                          </IconButton>
                        </TableCell> */}
                        <TableCell>
                          <IconButton
                            onClick={() => {
                              setSelectedUser(user);
                              setIsEdit(true);
                              setOpen(true);
                            }}>
                            <DotsIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                        count={rowData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                          inputProps: {
                            'aria-label': 'rows per page',
                          },
                          native: true,
                        }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        className='pagination'
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>

              <Notify
                severity='success'
                open={showNotification}
                onClose={handleNotificationClose}
                message='User added/updated successfully!'></Notify>
              {openDeleteDialog && renderDialog()}
            </div>
          ) : (
            <UserPermission />
          )}
        </Box>
      </Paper>
      <TadScreenLoader loading={loading} />
      <ContactCard
        contact={contact}
        anchorEl={contactAnchor}
        onClose={() => {
          setContactAnchor(null);
          setContact(null);
        }}
      />
    </div>
  );
};
export default Users;
