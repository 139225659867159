import React, { useEffect, useState } from 'react';
import {
  Paper,
  Typography,
  Autocomplete,
  TextField,
  Select,
  Chip,
  RadioGroup,
  FormControlLabel,
  Radio,
  MenuItem,
  Button,
  // IconButton,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import NewLocationAutoComplete from '../common/new-location-autocomplete';
import TextEditorField from '../common/text-editor-field';
import { TadSjdDetails } from '../../models/tad-sjd-form-model';
import { FormikProps, useFormik } from 'formik';
import * as Yup from 'yup';
import globalObject from '../../../common/global-variables';
import { LookupTypes } from '../../../common/lookupTypes';
import CountryCodes from '../common/country-codes';
import { getTadSkills } from '../../../service/lookupService';
import dateFormat from 'dateformat';
import dayjs from 'dayjs';
import { ReactComponent as AddIcon } from '../../assets/sjd/add.svg';
import { ReactComponent as DoneIcon } from '../../assets/sjd/done.svg';
// import { ReactComponent as MoreIcon } from '../../assets/dashboard-assets/more_vert.svg';
import './../source-job-definition/job-definition-details.scss';
import { getLabel, parseLocation } from '../../common/commonUtil';
import { getIdFromName, getNameFromId } from '../../../common/commonUtil';
import useNavigate from '../../../common/useNavigate';
import { TADROLES } from '../../../constants/common';
import { getAllUsers } from '../../../service/userService';
import ContactCard from '../common/contact-card';

interface JobDefinitionDetailsProps {
  save?: (values: TadSjdDetails) => void;
  mode?: 'edit-page' | 'details-page';
  sjdDetails: TadSjdDetails | undefined;
}

interface OptionType {
  id: number;
  name: string;
}

const JobDefinitionDetails: React.FC<JobDefinitionDetailsProps> = ({
  sjdDetails,
  mode = 'edit-page',
  save,
}: JobDefinitionDetailsProps) => {
  const [submitted, setSubmitted] = useState(false);
  const [skills, setSkills] = useState([] as any[]);
  const [selectedFullTimeFlag, setSelectedFullTimeFlag] = useState('IN');
  const [selectedConsultantFlag, setSelectedConsultantFlag] = useState('IN');
  const [selectedInternshipFlag, setSelectedInternshipFlag] = useState('IN');
  const edit = mode === 'edit-page';
  const [navigate] = useNavigate();
  const [contactAnchor, setContactAnchor] = useState<null | HTMLElement>(null);
  const [contact, setContact] = useState({} as any);
  const [hiringManagers, setHiringManagers] = useState([] as any[]);
  const [recruitingManagers, setRecruitingManagers] = useState([] as any[]);

  const handleLocationSelect = (locations: string[] = []) => {
    locations.length > 0
      ? formik.setFieldValue('jobLocation', `[${locations.join('][')}]`)
      : formik.setFieldValue('jobLocation', '');
  };

  const handleChipDelete = (type: any, array: any[], chipToDelete: any) => {
    if (type === 'jobLocation') {
      const newValue = array.filter((chip: any) => chip !== chipToDelete);
      newValue.length > 0
        ? formik.setFieldValue(type, `[${newValue.join('][')}]`)
        : formik.setFieldValue(type, '');
    } else {
      const newValue = array.filter((chip: any) => chip !== chipToDelete);
      formik.setFieldValue(type, newValue);
    }
  };

  const requisitionSchema = Yup.object().shape({
    name: Yup.string()
      .max(1000, 'Name should not exceed 1000 characters')
      .required('Name is required'),
    domainId: Yup.number().required('Domain is required'),
    primarySkills: Yup.array().of(Yup.object()).min(1, 'primary skills is required').required(),
    secondarySkills: Yup.array().of(Yup.object()).min(1, 'secondary skills is required').required(),
    // description: Yup.string()
    //   .test('description', 'Description is required', (value) => value !== '<p><br></p>')
    //   .max(1000, 'Description should not exceed 1000 characters')
    //   .required('Description is required'),
    // jobLocation: Yup.string().required('Job Location is required'),
    // priorityId: Yup.number()
    //   .integer('The value must be a number')
    //   .typeError('The value must be a number')
    //   .min(0, 'Value must be positive')
    //   .required('priority is required'),
    // operationId: Yup.number()
    //   .integer('The value must be a number')
    //   .typeError('The value must be a number')
    //   .min(0, 'Value must be positive')
    //   .required('operation is required'),
    // hiringManagers: Yup.array().of(Yup.object()).min(1, 'Hiring Manager is required').required(),
    // jobTypeId: Yup.array().of(Yup.object()).min(1, 'Employement type is required').required(),
    // workingModelId: Yup.number()
    //   .integer('The value must be a number')
    //   .typeError('The value must be a number')
    //   .min(0, 'Value must be positive')
    //   .required('working model is required'),
    // openPositions: Yup.number()
    //   .integer('The value must be a number')
    //   .typeError('The value must be a number')
    //   .moreThan(-1, 'The value must be positive')
    //   .required('open positions is required'),
    // clientId: Yup.number()
    //   .integer('The value must be a number')
    //   .typeError('The value must be a number')
    //   .min(0, 'Value must be positive')
    //   .required('client is required'),
    // endDate: Yup.string().required('target date is required'),
    // visaType: Yup.string().max(100, 'Visa Type should not exceed 100 characters').nullable(),
  });

  const formik = useFormik({
    initialValues: {
      id: sjdDetails?.id,
      name: sjdDetails?.name,
      description: sjdDetails?.description,
      jobLocation: sjdDetails?.jobLocation,
      domainId: sjdDetails?.domainId,
      priorityId: sjdDetails?.priorityId,
      operationId: sjdDetails?.operationId,
      hiringManagers: sjdDetails?.hiringManagers,
      recruitingManager: sjdDetails?.recruitingManager,
      qualificationId: sjdDetails?.qualificationId,
      workingModelId: sjdDetails?.workingModelId,
      jobTypeId: sjdDetails?.jobTypeId,
      payFullTimeRate: sjdDetails?.payFullTimeRate,
      payConsultantRate: sjdDetails?.payConsultantRate,
      payInternshipRate: sjdDetails?.payInternshipRate,
      primarySkills: sjdDetails?.primarySkills,
      secondarySkills: sjdDetails?.secondarySkills,
      openPositions: sjdDetails?.openPositions,
      clientId: sjdDetails?.clientId,
      endDate: sjdDetails?.endDate,
      sponsorshipRequired: sjdDetails?.sponsorshipRequired,
      visaType: sjdDetails?.visaType,
    },
    onSubmit: (values) => {
      save !== undefined && save(values);
      setSubmitted(false);
    },
    validationSchema: requisitionSchema,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  useEffect(() => {
    getTadSkills().then((res) => setSkills(res.data));
  }, []);

  useEffect(() => {
    getAllUsers(getIdFromName(TADROLES.HIRING_MANAGER, LookupTypes.TAD_ROLE))
      .then((res) => setHiringManagers(res.data))
      .catch(() => {
        console.log('err');
      });
    getAllUsers(getIdFromName(TADROLES.RECRUITER_MANAGER, LookupTypes.TAD_ROLE))
      .then((res) => setRecruitingManagers(res.data))
      .catch(() => {
        console.log('err');
      });
  }, []);

  useEffect(() => {
    const payFullTimeRate = formik.values.payFullTimeRate;
    const payConsultantRate = formik.values.payConsultantRate;
    const payInternshipRate = formik.values.payInternshipRate;
    if (
      (typeof payFullTimeRate === 'string' && payFullTimeRate.trim()) ||
      (typeof payConsultantRate === 'string' && payConsultantRate.trim()) ||
      (typeof payInternshipRate === 'string' && payInternshipRate.trim())
    ) {
      payFullTimeRate && setSelectedFullTimeFlag(payFullTimeRate.split(' ')[0]);
      payConsultantRate && setSelectedConsultantFlag(payConsultantRate.split(' ')[0]);
      payInternshipRate && setSelectedInternshipFlag(payInternshipRate.split(' ')[0]);
    }
  }, [
    formik.values.payFullTimeRate,
    formik.values.payConsultantRate,
    formik.values.payInternshipRate,
    2,
  ]);

  function getError(formik: FormikProps<any>, fieldName: string) {
    if ((submitted || formik.touched[fieldName]) && formik.errors[fieldName])
      return formik.errors[fieldName]?.toString();
    return null;
  }

  const handleSkillToggle = (type: string, skill: any) => {
    let currentSkills: any;
    if (type === 'primarySkills') {
      currentSkills = formik.values.primarySkills;
    } else {
      currentSkills = formik.values.secondarySkills;
    }
    const isSelected = currentSkills.some((s: any) => s.skill.id === skill.id);
    if (isSelected) {
      formik.setFieldValue(
        type,
        currentSkills.filter((s: any) => s.skill.id !== skill.id),
      );
    } else {
      formik.setFieldValue(type, [...currentSkills, { skill }]);
    }
  };

  return (
    <>
      <div style={{ height: '100%', width: '100%' }}>
        <Paper className='paper' sx={{ width: '100%' }}>
          {mode === 'details-page' && (
            <div className='header-container'>
              <div className='header-content-title'>
                <span>Job Details</span>
              </div>
              {/* <div className='header-content-icon'>
                <IconButton
                  onClick={() => {
                    edit == false ? setEdit(true) : setEdit(false);
                  }}>
                  <MoreIcon />
                </IconButton>
              </div> */}
            </div>
          )}
          <div style={{ padding: '2.69rem 3rem 2.19rem 3rem' }}>
            <div className='field-container'>
              {mode === 'edit-page' ? (
                <div style={{ width: '50%' }}>
                  <Typography sx={{ fontWeight: 600 }}>Job Title *</Typography>
                  <span className='feildHeader'>
                    A concise label that summarizes the primary role
                  </span>
                </div>
              ) : (
                <div style={{ width: '35%' }}>
                  <Typography sx={{ fontWeight: 600 }}> Title: </Typography>
                </div>
              )}
              {edit ? (
                <div style={{ width: '50%' }}>
                  <TextField
                    className='input-field'
                    fullWidth
                    value={formik.values.name}
                    onChange={(e) => formik.setFieldValue('name', e.target.value)}
                    error={Boolean(formik.errors.name && formik.touched.name)}></TextField>
                  <div className='mt-0 form-err-message'>{getError(formik, 'name')}</div>
                </div>
              ) : (
                <span className='text-label'>{getLabel(formik.values.name)}</span>
              )}
            </div>
            <div className='field-container'>
              {mode === 'edit-page' ? (
                <div style={{ width: '50%' }}>
                  <Typography sx={{ fontWeight: 600 }}>Roles & Responsibilities</Typography>
                  <span className='feildHeader'>
                    Role Synopsis, Key Responsibilities & Qualifications
                  </span>
                </div>
              ) : (
                <div style={{ width: '35%' }}>
                  <Typography sx={{ fontWeight: 600 }}>Roles & Responsibilities: </Typography>
                </div>
              )}
              <div style={{ width: '50%' }}>
                {edit ? (
                  <TextEditorField
                    width='100%'
                    value={formik.values.description ? formik.values.description : ''}
                    selectedValue={(value) => formik.setFieldValue('description', value)}
                    error={
                      formik.errors.description && formik.touched.description
                        ? formik.errors.description
                        : undefined
                    }
                  />
                ) : (
                  <span className='text-label'>
                    {getLabel(formik.values.description?.replace(/<[^>]+>/g, ''))}
                  </span>
                )}
              </div>
            </div>
            <div className='field-container'>
              {mode === 'edit-page' ? (
                <div style={{ width: '50%' }}>
                  <Typography sx={{ fontWeight: 600 }}>Job Location</Typography>
                  <span className='feildHeader'>Pick one or multiple options</span>
                </div>
              ) : (
                <div style={{ width: '35%' }}>
                  <Typography sx={{ fontWeight: 600 }}>Job Location: </Typography>
                </div>
              )}
              {edit ? (
                <div style={{ width: '50%' }}>
                  <div className='input-field' style={{ marginBottom: '0.62rem' }}>
                    <NewLocationAutoComplete
                      onSelect={handleLocationSelect}
                      initialValue={
                        formik.values.jobLocation ? parseLocation(formik.values.jobLocation) : []
                      }
                      error={Boolean(formik.errors.jobLocation && formik.touched.jobLocation)}
                    />
                  </div>
                  <div className='form-err-message'>{getError(formik, 'jobLocation')}</div>
                  {formik.values.jobLocation &&
                    parseLocation(formik.values.jobLocation)?.map((location, index) => (
                      <Chip
                        key={index}
                        label={location}
                        style={{
                          backgroundColor: '#D9D9D9',
                          borderRadius: '0.63rem',
                          marginRight: '0.5rem',
                        }}
                        onDelete={() => {
                          formik.values.jobLocation &&
                            handleChipDelete(
                              'jobLocation',
                              parseLocation(formik.values.jobLocation),
                              location,
                            );
                        }}
                      />
                    ))}
                </div>
              ) : (
                <span>
                  {formik.values.jobLocation && parseLocation(formik.values.jobLocation).join(', ')}
                </span>
              )}
            </div>
            <div className='field-container'>
              {mode === 'edit-page' ? (
                <div style={{ width: '50%' }}>
                  <Typography sx={{ fontWeight: 600 }}>Domain *</Typography>
                </div>
              ) : (
                <div style={{ width: '35%' }}>
                  <Typography sx={{ fontWeight: 600 }}>Domain: </Typography>
                </div>
              )}
              {edit ? (
                <div style={{ width: '50%' }}>
                  <Select
                    fullWidth
                    className='input-field'
                    value={formik.values.domainId || ''}
                    onChange={(e) => formik.setFieldValue('domainId', e.target.value)}
                    error={Boolean(formik.errors.domainId && formik.touched.domainId)}
                    sx={{
                      height: 'var(--Font-size-h2, 2.5rem)',
                    }}
                    size='small'>
                    <MenuItem value='' disabled>
                      Choose...
                    </MenuItem>
                    {globalObject.lookupvalues[LookupTypes.TAD_DOMAIN].map((domain: any) => (
                      <MenuItem key={domain.id} value={domain.id}>
                        {domain.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <div className='mt-0 form-err-message'>{getError(formik, 'domainId')}</div>
                </div>
              ) : (
                <span className='text-label'>
                  {getNameFromId(formik.values.domainId, LookupTypes.TAD_DOMAIN)}
                </span>
              )}
            </div>
            <div className='field-container'>
              {mode === 'edit-page' ? (
                <div style={{ width: '50%' }}>
                  <Typography sx={{ fontWeight: 600 }}>Priority</Typography>
                </div>
              ) : (
                <div style={{ width: '35%' }}>
                  <Typography sx={{ fontWeight: 600 }}>Priority: </Typography>
                </div>
              )}
              {edit ? (
                <div style={{ width: '50%' }}>
                  <Autocomplete
                    fullWidth
                    className='input-field'
                    id='combo-box-demo'
                    options={globalObject.lookupvalues[LookupTypes.TAD_PRIORITY] as OptionType[]}
                    value={
                      formik.values.priorityId
                        ? globalObject.lookupvalues[LookupTypes.TAD_PRIORITY].find(
                            (option: OptionType) => option.id === formik.values.priorityId,
                          )
                        : null
                    }
                    size='small'
                    limitTags={1}
                    getOptionLabel={(option: any) => option.name}
                    filterSelectedOptions
                    onBlur={() => formik.setFieldTouched('priorityId', true, true)}
                    renderInput={(params) => (
                      <TextField
                        placeholder='Choose...'
                        {...params}
                        error={Boolean(formik.errors.priorityId && formik.touched.priorityId)}
                        label=''
                      />
                    )}
                    onChange={(_e, val) => {
                      formik.setFieldValue('priorityId', val ? val.id : undefined);
                    }}
                  />
                  <div className='mt-0 form-err-message'>{getError(formik, 'priorityId')}</div>
                </div>
              ) : (
                <span className='text-label'>
                  {getNameFromId(formik.values.priorityId, LookupTypes.TAD_PRIORITY)}
                </span>
              )}
            </div>
            <div className='field-container'>
              {mode === 'edit-page' ? (
                <div style={{ width: '50%' }}>
                  <Typography sx={{ fontWeight: 600 }}>Operations</Typography>
                  <span className='feildHeader'>Choose India or US</span>
                </div>
              ) : (
                <div style={{ width: '35%' }}>
                  <Typography sx={{ fontWeight: 600 }}>Operations: </Typography>
                </div>
              )}
              {edit ? (
                <div style={{ width: '50%' }}>
                  <Autocomplete
                    className='input-field'
                    fullWidth
                    id='combo-box-demo'
                    options={globalObject.lookupvalues[LookupTypes.TAD_OPERATION] as OptionType[]}
                    value={
                      formik.values.operationId
                        ? globalObject.lookupvalues[LookupTypes.TAD_OPERATION].find(
                            (option: OptionType) => option.id === formik.values.operationId,
                          )
                        : null
                    }
                    size='small'
                    limitTags={1}
                    getOptionLabel={(option: any) => option.name}
                    filterSelectedOptions
                    onBlur={() => formik.setFieldTouched('operationId', true, true)}
                    renderInput={(params) => (
                      <TextField
                        placeholder='Choose...'
                        {...params}
                        error={Boolean(formik.errors.operationId && formik.touched.operationId)}
                        label=''
                      />
                    )}
                    onChange={(_e, val) => {
                      formik.setFieldValue('operationId', val ? val.id : undefined);
                    }}
                  />
                  <div className='mt-0 form-err-message'>{getError(formik, 'operationId')}</div>
                </div>
              ) : (
                <span className='text-label'>
                  {getNameFromId(formik.values.operationId, LookupTypes.TAD_OPERATION)}
                </span>
              )}
            </div>
            <div style={{ marginBottom: '1.87rem' }}>
              <hr style={{ background: '#868686' }} />
            </div>
            <div className='field-container'>
              {mode === 'edit-page' ? (
                <div style={{ width: '50%' }}>
                  <Typography sx={{ fontWeight: 600 }}>Hiring Manager</Typography>
                </div>
              ) : (
                <div style={{ width: '35%' }}>
                  <Typography sx={{ fontWeight: 600 }}>Hiring Manager: </Typography>
                </div>
              )}
              {edit ? (
                <div style={{ width: '50%' }}>
                  <div className='input-field'>
                    <Autocomplete
                      fullWidth
                      id='combo-box-demo'
                      options={hiringManagers}
                      value={formik.values.hiringManagers || []}
                      size='small'
                      limitTags={1}
                      getOptionLabel={(option: any) => `${option.firstName} ${option.lastName}`}
                      filterSelectedOptions
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      onBlur={() => formik.setFieldTouched('hiringManagers', true, true)}
                      renderInput={(params) => (
                        <TextField
                          placeholder='Choose...'
                          {...params}
                          error={Boolean(
                            formik.errors.hiringManagers && formik.touched.hiringManagers,
                          )}
                          label=''
                        />
                      )}
                      onChange={(_e, val) => {
                        formik.setFieldValue('hiringManagers', val ? val : undefined);
                      }}
                      multiple
                    />
                  </div>
                  <div className='mt-0 form-err-message'>{getError(formik, 'hiringManagers')}</div>
                </div>
              ) : (
                <>
                  {formik.values.hiringManagers?.map((s: any) => (
                    <span
                      key={s}
                      style={{
                        cursor: 'pointer',
                        color: '#1976d2',
                        textDecoration: 'underline',
                        marginRight: '0.5rem',
                      }}
                      onClick={(e) => {
                        setContactAnchor(e.currentTarget);
                        setContact(s);
                      }}>
                      {`${s.firstName} ${s.lastName}`}
                    </span>
                  ))}
                </>
              )}
            </div>
            <div className='field-container'>
              {mode === 'edit-page' ? (
                <div style={{ width: '50%' }}>
                  <Typography sx={{ fontWeight: 600 }}>Recruiting Manager *</Typography>
                </div>
              ) : (
                <div style={{ width: '35%' }}>
                  <Typography sx={{ fontWeight: 600 }}>Recuriting Manager: </Typography>
                </div>
              )}
              {edit ? (
                <div style={{ width: '50%' }}>
                  <div className='input-field'>
                    <Autocomplete
                      fullWidth
                      id='combo-box-demo'
                      options={recruitingManagers}
                      value={formik.values.recruitingManager || {}}
                      size='small'
                      limitTags={1}
                      getOptionLabel={(option: any) =>
                        `${option.firstName} ${option.lastName}` || ''
                      }
                      filterSelectedOptions
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      onBlur={() => formik.setFieldTouched('recruitingManager', true, true)}
                      renderInput={(params) => (
                        <TextField
                          placeholder='Choose...'
                          {...params}
                          error={Boolean(
                            formik.errors.recruitingManager && formik.touched.recruitingManager,
                          )}
                          label=''
                        />
                      )}
                      onChange={(_e, val) => {
                        formik.setFieldValue('recruitingManager', val ? val : undefined);
                      }}
                    />
                  </div>
                  <div className='mt-0 form-err-message'>
                    {getError(formik, 'recruitingManager')}
                  </div>
                </div>
              ) : (
                <>
                  {formik.values.recruitingManager && (
                    <span
                      style={{
                        cursor: 'pointer',
                        color: '#1976d2',
                        textDecoration: 'underline',
                        marginRight: '0.5rem',
                      }}
                      onClick={(e) => {
                        setContactAnchor(e.currentTarget);
                        setContact(formik.values.recruitingManager);
                      }}>
                      {`${formik.values.recruitingManager.firstName} ${formik.values.recruitingManager.lastName}`}
                    </span>
                  )}
                </>
              )}
            </div>

            <div className='field-container'>
              {mode === 'edit-page' ? (
                <div style={{ width: '50%' }}>
                  <Typography sx={{ fontWeight: 600 }}>Employment Type</Typography>
                  <span className='feildHeader'>Pick one or multiple options</span>
                </div>
              ) : (
                <div style={{ width: '35%' }}>
                  <Typography sx={{ fontWeight: 600 }}>Employment Type: </Typography>
                </div>
              )}
              {edit ? (
                <div style={{ width: '50%' }}>
                  <Autocomplete
                    className='input-field'
                    id='combo-box-demo'
                    options={globalObject.lookupvalues[LookupTypes.TAD_JOB_TYPE]}
                    value={formik.values.jobTypeId || []}
                    size='small'
                    limitTags={1}
                    getOptionLabel={(option: any) => option.name}
                    filterSelectedOptions
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    onBlur={() => formik.setFieldTouched('jobTypeId', true, true)}
                    // defaultValue={values?.hiringManagers ? values.hiringManagers : undefined}
                    renderInput={(params) => (
                      <TextField
                        placeholder='Choose...'
                        {...params}
                        error={Boolean(formik.errors.jobTypeId && formik.touched.jobTypeId)}
                        label=''
                      />
                    )}
                    onChange={(_e, val) => {
                      formik.setFieldValue('jobTypeId', val ? val : undefined);
                    }}
                    multiple
                  />
                  <div className='mt-0 form-err-message'>{getError(formik, 'jobTypeId')}</div>
                  {formik.values?.jobTypeId?.map((jobType: any) => (
                    <Chip
                      key={jobType.id}
                      label={jobType.name}
                      style={{
                        backgroundColor: '#D9D9D9',
                        borderRadius: '0.63rem',
                        marginRight: '0.63rem',
                      }}
                      onDelete={() => {
                        formik.values.jobTypeId &&
                          handleChipDelete('jobTypeId', formik.values?.jobTypeId, jobType);
                      }}
                    />
                  ))}
                </div>
              ) : (
                <span className='text-label'>
                  {formik.values.jobTypeId?.map((e) => e.name).join(',')}
                </span>
              )}
            </div>
            <div className='field-container'>
              {mode === 'edit-page' ? (
                <div style={{ width: '50%' }}>
                  <Typography sx={{ fontWeight: 600 }}>Working Model</Typography>
                  <span className='feildHeader'>Choose Remote or Hybrid or Onsite</span>
                </div>
              ) : (
                <div style={{ width: '35%' }}>
                  <Typography sx={{ fontWeight: 600 }}>Working Model: </Typography>
                </div>
              )}
              {edit ? (
                <div style={{ width: '50%' }}>
                  <Autocomplete
                    fullWidth
                    className='input-field'
                    id='combo-box-demo'
                    options={
                      globalObject.lookupvalues[LookupTypes.TAD_WORKING_MODEL] as OptionType[]
                    }
                    value={
                      formik.values.workingModelId
                        ? globalObject.lookupvalues[LookupTypes.TAD_WORKING_MODEL].find(
                            (option: OptionType) => option.id === formik.values.workingModelId,
                          )
                        : null
                    }
                    size='small'
                    limitTags={1}
                    getOptionLabel={(option: any) => option.name}
                    filterSelectedOptions
                    onBlur={() => formik.setFieldTouched('workingModelId', true, true)}
                    renderInput={(params) => (
                      <TextField
                        placeholder='Choose...'
                        {...params}
                        error={Boolean(
                          formik.errors.workingModelId && formik.touched.workingModelId,
                        )}
                        label=''
                      />
                    )}
                    onChange={(_e, val) => {
                      formik.setFieldValue('workingModelId', val ? val.id : undefined);
                    }}
                  />
                  <div className='mt-0 form-err-message'>{getError(formik, 'workingModelId')}</div>
                </div>
              ) : (
                <span className='text-label'>
                  {getNameFromId(formik.values.workingModelId, LookupTypes.TAD_WORKING_MODEL)}
                </span>
              )}
            </div>

            <div
              style={{
                // gap: '24.56rem',
                alignItems: 'center',
                marginBottom: '1.87rem',
              }}>
              <hr style={{ background: '#868686' }} />
            </div>
            <div
              className='field-container'
              style={{ justifyContent: mode == 'details-page' ? 'flex-start' : 'space-between' }}>
              {mode === 'edit-page' ? (
                <div style={{ width: '50%' }}>
                  <Typography sx={{ fontWeight: 600 }}>Preferred Qualification</Typography>
                  <span className='feildHeader'>
                    Choose required qualification of the candidate
                  </span>
                </div>
              ) : (
                <div style={{ width: '35%' }}>
                  <Typography sx={{ fontWeight: 600 }}>Preferred Qualification: </Typography>
                </div>
              )}
              {edit ? (
                <div style={{ width: '50%' }}>
                  <Autocomplete
                    fullWidth
                    className='input-field'
                    id='combo-box-demo'
                    options={
                      globalObject.lookupvalues[LookupTypes.TAD_QUALIFICATION] as OptionType[]
                    }
                    value={
                      formik.values.qualificationId
                        ? globalObject.lookupvalues[LookupTypes.TAD_QUALIFICATION].find(
                            (option: OptionType) => option.id === formik.values.qualificationId,
                          )
                        : null
                    }
                    size='small'
                    limitTags={1}
                    getOptionLabel={(option: any) => option.name}
                    filterSelectedOptions
                    onBlur={() => formik.setFieldTouched('qualificationId', true, true)}
                    renderInput={(params) => (
                      <TextField
                        placeholder='Choose...'
                        {...params}
                        error={Boolean(
                          formik.errors.qualificationId && formik.touched.qualificationId,
                        )}
                        label=''
                      />
                    )}
                    onChange={(_e, val) => {
                      formik.setFieldValue('qualificationId', val ? val.id : undefined);
                    }}
                  />
                </div>
              ) : (
                <span className='text-label'>
                  {getNameFromId(formik.values.qualificationId, LookupTypes.TAD_QUALIFICATION)}
                </span>
              )}
            </div>
            {formik.values.jobTypeId?.some(
              (job: any) => job.name.toLowerCase() === 'full time',
            ) && (
              <div className='field-container'>
                {mode === 'edit-page' ? (
                  <div style={{ width: '50%' }}>
                    <Typography sx={{ fontWeight: 600 }}>Salary (Full-time)</Typography>
                    <span className='feildHeader'>Choose how you prefer to pay for this job</span>
                  </div>
                ) : (
                  <div style={{ width: '35%' }}>
                    <Typography sx={{ fontWeight: 600 }}>Salary (Full-time): </Typography>
                  </div>
                )}
                {edit ? (
                  <div
                    style={{
                      width: '50%',
                      display: 'flex',
                      alignItems: 'center',
                    }}>
                    <div style={{ display: 'flex', width: '100%' }}>
                      <CountryCodes
                        selectedCountry={selectedFullTimeFlag}
                        setSelectedCountry={(value) => setSelectedFullTimeFlag(value)}
                        code={true}
                      />
                      <TextField
                        className='salaryType'
                        style={{ borderRadius: '0px 0px 0px 0px' }}
                        inputProps={{ maxLength: 10 }}
                        value={formik.values.payFullTimeRate?.replace(/\D/g, '') || ''}
                        onChange={(e) =>
                          formik.setFieldValue(
                            'payFullTimeRate',
                            `${selectedFullTimeFlag} ${e.target.value}`,
                          )
                        }
                        fullWidth
                        id='phoneNumber'
                        size='small'
                        name='phoneNumber'
                      />
                      <TextField
                        className='salaryContact'
                        sx={{ width: '17%', color: 'black' }}
                        value={'Annually'}
                        disabled
                        fullWidth
                        id='phoneNumber'
                        size='small'
                        name='phoneNumber'
                      />
                    </div>
                  </div>
                ) : (
                  <span className='text-label'>
                    {formik.values.payFullTimeRate?.replace(/\D/g, '')}
                  </span>
                )}
              </div>
            )}
            {formik.values.jobTypeId?.some(
              (job: any) => job.name.toLowerCase() === 'consultant',
            ) && (
              <div className='field-container'>
                {mode === 'edit-page' ? (
                  <div style={{ width: '50%' }}>
                    <Typography sx={{ fontWeight: 600 }}>Salary (Consultant)</Typography>
                    <span className='feildHeader'>Choose how you prefer to pay for this job</span>
                  </div>
                ) : (
                  <div style={{ width: '35%' }}>
                    <Typography sx={{ fontWeight: 600 }}>Salary (Consultant): </Typography>
                  </div>
                )}
                {edit ? (
                  <div
                    style={{
                      display: 'flex',
                      width: '50%',
                      alignItems: 'center',
                    }}>
                    <div style={{ display: 'flex', width: '100%' }}>
                      <CountryCodes
                        selectedCountry={
                          formik.values.payConsultantRate
                            ? `${formik.values.payConsultantRate.split(' ')[0]}`
                            : selectedConsultantFlag
                        }
                        setSelectedCountry={(value) => setSelectedConsultantFlag(value)}
                        code={true}
                      />
                      <TextField
                        className='salaryType'
                        placeholder='0'
                        style={{ borderRadius: '0px 0px 0px 0px' }}
                        inputProps={{ maxLength: 5 }}
                        value={formik.values.payConsultantRate?.replace(/\D/g, '') || ''}
                        onChange={(e) =>
                          formik.setFieldValue(
                            'payConsultantRate',
                            `${selectedConsultantFlag} ${e.target.value}`,
                          )
                        }
                        fullWidth
                        id='phoneNumber'
                        size='small'
                        name='phoneNumber'
                      />
                      <TextField
                        className='salaryContact'
                        sx={{ width: '17%', color: 'black' }}
                        value={'Hourly'}
                        onChange={(e) => formik.setFieldValue('payTypeId', e.target.value)}
                        disabled
                        fullWidth
                        id='phoneNumber'
                        size='small'
                        name='phoneNumber'
                      />
                    </div>
                  </div>
                ) : (
                  <span className='text-label'>{formik.values.payConsultantRate}</span>
                )}
              </div>
            )}
            {formik.values.jobTypeId?.some(
              (job: any) => job.name.toLowerCase() === 'internship',
            ) && (
              <div className='field-container'>
                {mode === 'edit-page' ? (
                  <div style={{ width: '50%' }}>
                    <Typography sx={{ fontWeight: 600 }}>Salary (Internship)</Typography>
                    <span className='feildHeader'>Choose how you prefer to pay for this job</span>
                  </div>
                ) : (
                  <div style={{ width: '35%' }}>
                    <Typography sx={{ fontWeight: 600 }}>Salary (Internship):</Typography>
                  </div>
                )}
                {edit ? (
                  <div
                    style={{
                      display: 'flex',
                      width: '50%',
                      alignItems: 'center',
                    }}>
                    <div style={{ display: 'flex', width: '100%' }}>
                      <CountryCodes
                        selectedCountry={
                          formik.values.payInternshipRate
                            ? `${formik.values.payInternshipRate.split(' ')[0]}`
                            : selectedInternshipFlag
                        }
                        setSelectedCountry={(value) => setSelectedInternshipFlag(value)}
                        code={true}
                      />
                      <TextField
                        className='salaryType'
                        placeholder='0'
                        style={{ borderRadius: '0px 0px 0px 0px' }}
                        sx={{ width: '68%' }}
                        inputProps={{ maxLength: 7 }}
                        value={formik.values.payInternshipRate?.replace(/\D/g, '') || ''}
                        onChange={(e) =>
                          formik.setFieldValue(
                            'payInternshipRate',
                            `${selectedInternshipFlag} ${e.target.value}`,
                          )
                        }
                        fullWidth
                        id='phoneNumber'
                        size='small'
                        name='phoneNumber'
                      />
                      <TextField
                        className='salaryContact'
                        sx={{ width: '17%', color: 'black' }}
                        value={'Monthly'}
                        onChange={(e) => formik.setFieldValue('payTypeId', e.target.value)}
                        disabled
                        fullWidth
                        id='phoneNumber'
                        size='small'
                        name='phoneNumber'
                      />
                    </div>
                  </div>
                ) : (
                  <span className='text-label'>{formik.values.payInternshipRate}</span>
                )}
              </div>
            )}

            <div
              style={{
                // gap: '24.56rem',
                alignItems: 'center',
                marginBottom: '1.87rem',
              }}>
              <hr style={{ background: '#868686' }} />
            </div>
            <div
              className='field-container'
              style={{ justifyContent: mode == 'details-page' ? 'flex-start' : 'space-between' }}>
              {mode === 'edit-page' ? (
                <div style={{ width: '50%' }}>
                  <Typography sx={{ fontWeight: 600 }}>Primary Skills *</Typography>
                  <span className='feildHeader'>Add skills suggested by AI</span>
                </div>
              ) : (
                <div style={{ width: '35%' }}>
                  <Typography sx={{ fontWeight: 600 }}>Primary Skills: </Typography>
                </div>
              )}
              {edit ? (
                <div style={{ width: '50%' }}>
                  <div style={{ marginBottom: '0.62rem' }}>
                    <Autocomplete
                      id='combo-box-demo'
                      options={skills}
                      value={formik.values.primarySkills?.map((skill: any) => skill.skill) || []}
                      size='small'
                      getOptionLabel={(option: any) => option.name}
                      filterSelectedOptions
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      renderInput={(params) => (
                        <TextField
                          placeholder='Enter a skill'
                          sx={{ minHeight: '2.5rem' }}
                          {...params}
                          error={Boolean(
                            formik.errors.primarySkills && formik.touched.primarySkills,
                          )}
                          label=''
                        />
                      )}
                      onChange={(_e, val) => {
                        formik.setFieldValue(
                          'primarySkills',
                          val ? val.map((skill: any) => ({ skill })) : [],
                        );
                      }}
                      multiple
                    />
                  </div>
                  <div className='mt-0 form-err-message'>{getError(formik, 'primarySkills')}</div>
                  <div style={{ width: '100%' }}>
                    <Typography sx={{ fontWeight: 600 }}>Skill Suggestions</Typography>
                    {skills.slice(0, 7).map((skill) => (
                      <Chip
                        key={skill.id}
                        label={skill.name}
                        style={{
                          backgroundColor: '#D9D9D9',
                          borderRadius: '0.63rem',
                          marginRight: '0.63rem',
                          marginBottom: '0.63rem',
                        }}
                        avatar={
                          formik.values?.primarySkills?.some(
                            (s: any) => s.skill.id === skill.id,
                          ) ? (
                            <DoneIcon />
                          ) : (
                            <AddIcon />
                          )
                        }
                        onClick={() => handleSkillToggle('primarySkills', skill)}
                      />
                    ))}
                  </div>
                </div>
              ) : (
                <span className='text-label'>
                  {formik.values.primarySkills?.map((s: any) => s.skill.name).join(', ')}
                </span>
              )}
            </div>
            <div
              className='field-container'
              style={{ justifyContent: mode == 'details-page' ? 'flex-start' : 'space-between' }}>
              {mode === 'edit-page' ? (
                <div style={{ width: '50%' }}>
                  <Typography sx={{ fontWeight: 600 }}>Secondary Skills *</Typography>
                  <span className='feildHeader'>Add skills suggested by AI</span>
                </div>
              ) : (
                <div style={{ width: '35%' }}>
                  <Typography sx={{ fontWeight: 600 }}>Secondary Skills: </Typography>
                </div>
              )}
              {edit ? (
                <div style={{ width: '50%' }}>
                  <div style={{ marginBottom: '0.62rem' }}>
                    <Autocomplete
                      id='combo-box-demo'
                      options={skills}
                      value={formik.values.secondarySkills?.map((skill: any) => skill.skill) || []}
                      size='small'
                      getOptionLabel={(option: any) => option.name}
                      filterSelectedOptions
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      renderInput={(params) => (
                        <TextField
                          sx={{ minHeight: '2.5rem' }}
                          placeholder='Enter a skill'
                          {...params}
                          error={Boolean(
                            formik.errors.secondarySkills && formik.touched.secondarySkills,
                          )}
                          label=''
                        />
                      )}
                      onChange={(_e, val) => {
                        formik.setFieldValue(
                          'secondarySkills',
                          val ? val.map((skill: any) => ({ skill })) : [],
                        );
                      }}
                      multiple
                    />
                  </div>
                  <div className='mt-0 form-err-message'>{getError(formik, 'secondarySkills')}</div>
                  <div style={{ width: '100%' }}>
                    <Typography sx={{ fontWeight: 600 }}>Skill Suggestions</Typography>
                    {skills.slice(-7).map((skill) => (
                      <Chip
                        key={skill.id}
                        label={skill.name}
                        style={{
                          backgroundColor: '#D9D9D9',
                          borderRadius: '0.63rem',
                          marginRight: '0.63rem',
                          marginBottom: '0.63rem',
                        }}
                        avatar={
                          formik.values?.secondarySkills?.some(
                            (s: any) => s.skill.id === skill.id,
                          ) ? (
                            <DoneIcon />
                          ) : (
                            <AddIcon />
                          )
                        }
                        onClick={() => handleSkillToggle('secondarySkills', skill)}
                      />
                    ))}
                  </div>
                </div>
              ) : (
                <span className='text-label'>
                  {formik.values.secondarySkills?.map((s: any) => s.skill.name).join(', ')}
                </span>
              )}
            </div>
            <div
              className='field-container'
              style={{ justifyContent: mode == 'details-page' ? 'flex-start' : 'space-between' }}>
              {mode === 'edit-page' ? (
                <div style={{ width: '50%' }}>
                  <Typography sx={{ fontWeight: 600 }}>Open Positions</Typography>
                </div>
              ) : (
                <div style={{ width: '35%' }}>
                  <Typography sx={{ fontWeight: 600 }}>Open Positions: </Typography>
                </div>
              )}
              {edit ? (
                <div style={{ width: '50%' }}>
                  <div className='input-field'>
                    <TextField
                      type='number'
                      fullWidth
                      value={formik.values.openPositions || ''}
                      onChange={(e) =>
                        formik.setFieldValue('openPositions', parseInt(e.target.value))
                      }
                      error={Boolean(formik.errors.openPositions && formik.touched.openPositions)}
                      sx={{
                        height: 'var(--Font-size-h2, 2.5rem)',
                      }}></TextField>
                  </div>
                  <div className='mt-0 form-err-message'>{getError(formik, 'openPositions')}</div>
                </div>
              ) : (
                <span className='text-label'>{formik.values.openPositions}</span>
              )}
            </div>
            <div
              style={{
                // gap: '6.56rem',
                alignItems: 'center',
                marginBottom: '1.87rem',
              }}>
              <hr style={{ background: '#868686' }} />
            </div>
            <div
              className='field-container'
              style={{ justifyContent: mode == 'details-page' ? 'flex-start' : 'space-between' }}>
              {mode === 'edit-page' ? (
                <div style={{ width: '50%' }}>
                  <Typography sx={{ fontWeight: 600 }}>Client</Typography>
                </div>
              ) : (
                <div style={{ width: '35%' }}>
                  <Typography sx={{ fontWeight: 600 }}>Client: </Typography>
                </div>
              )}
              {edit ? (
                <div style={{ width: '50%' }}>
                  <Autocomplete
                    fullWidth
                    className='input-field'
                    id='combo-box-demo'
                    options={globalObject.lookupvalues[LookupTypes.TAD_CLIENT] as OptionType[]}
                    value={
                      formik.values.clientId
                        ? globalObject.lookupvalues[LookupTypes.TAD_CLIENT].find(
                            (option: OptionType) => option.id === formik.values.clientId,
                          )
                        : null
                    }
                    size='small'
                    limitTags={1}
                    getOptionLabel={(option: any) => option.name}
                    filterSelectedOptions
                    onBlur={() => formik.setFieldTouched('clientId', true, true)}
                    renderInput={(params) => (
                      <TextField
                        placeholder='Choose...'
                        {...params}
                        error={Boolean(formik.errors.clientId && formik.touched.clientId)}
                        label=''
                      />
                    )}
                    onChange={(_e, val) => {
                      formik.setFieldValue('clientId', val ? val.id : undefined);
                    }}
                  />
                  <div className='mt-0 form-err-message'>{getError(formik, 'clientId')}</div>
                </div>
              ) : (
                <span className='text-label'>
                  {getNameFromId(formik.values.clientId, LookupTypes.TAD_CLIENT)}
                </span>
              )}
            </div>
            <div
              className='field-container'
              style={{ justifyContent: mode == 'details-page' ? 'flex-start' : 'space-between' }}>
              {mode === 'edit-page' ? (
                <div style={{ width: '50%' }}>
                  <Typography sx={{ fontWeight: 600 }}>Target Date</Typography>
                </div>
              ) : (
                <div style={{ width: '35%' }}>
                  <Typography sx={{ fontWeight: 600 }}>Target Date: </Typography>
                </div>
              )}
              {edit ? (
                <div
                  className='input-field'
                  style={{ height: 'var(--Font-size-h2, 2.5rem)', width: '50%' }}>
                  <DatePicker
                    className='datepickers'
                    value={formik.values.endDate ? dayjs(formik.values.endDate) : ''}
                    onChange={(value) => {
                      formik.setFieldValue(
                        'endDate',
                        dateFormat(value?.toString(), 'isoDateTime'),
                        true,
                      );
                    }}
                    slotProps={{
                      textField: {
                        error: Boolean(formik.errors.endDate && formik.touched.endDate),

                        sx: {
                          width: '100%',
                          height: 'var(--Font-size-h2, 2.5rem)',
                        },
                      },
                    }}
                    sx={{
                      width: '100%',
                      height: 'var(--Font-size-h2, 2.5rem)',
                    }}></DatePicker>
                  <div className='mt-0 form-err-message'>{getError(formik, 'endDate')}</div>
                </div>
              ) : (
                <span className='text-label'>
                  {formik.values.endDate &&
                    dateFormat(new Date(formik.values.endDate), 'dd/mm/yyyy')}
                </span>
              )}
            </div>
            <div
              style={{
                // gap: '24.56rem',
                alignItems: 'center',
                marginBottom: '1.87rem',
              }}>
              <hr style={{ background: '#868686' }} />
            </div>
            <div
              className='field-container'
              style={{ justifyContent: mode == 'details-page' ? 'flex-start' : 'space-between' }}>
              {mode === 'edit-page' ? (
                <div style={{ width: '50%' }}>
                  <Typography sx={{ fontWeight: 600 }}>Sponsorship Required?</Typography>
                </div>
              ) : (
                <div style={{ width: '35%' }}>
                  <Typography sx={{ fontWeight: 600 }}>Sponsorship Required: </Typography>
                </div>
              )}
              {edit ? (
                <div style={{ height: 'var(--Font-size-h2, 2.5rem)', width: '50%' }}>
                  <RadioGroup
                    row
                    aria-labelledby='demo-controlled-radio-buttons-group'
                    name='controlled-radio-buttons-group'
                    value={formik.values.sponsorshipRequired || false}
                    onChange={(e) => formik.setFieldValue('sponsorshipRequired', e.target.value)}>
                    <FormControlLabel value={false} control={<Radio />} label='No' />
                    <FormControlLabel value={true} control={<Radio />} label='Yes' />
                  </RadioGroup>
                  <div className='mt-0 form-err-message'>
                    {getError(formik, 'sponsorshipRequired')}
                  </div>
                </div>
              ) : (
                <span className='text-label'>
                  {formik.values.sponsorshipRequired ? 'Yes' : 'No'}
                </span>
              )}
            </div>
            <div
              className='field-container'
              style={{ justifyContent: mode == 'details-page' ? 'flex-start' : 'space-between' }}>
              {mode === 'edit-page' ? (
                <div style={{ width: '50%' }}>
                  <Typography sx={{ fontWeight: 600 }}>Visa Type</Typography>
                  <span className='feildHeader'>
                    Check for Right to work with specific visa type
                  </span>
                </div>
              ) : (
                <div style={{ width: '35%' }}>
                  <Typography sx={{ fontWeight: 600 }}>Visa Type: </Typography>
                </div>
              )}
              {edit ? (
                <div style={{ width: '50%' }}>
                  <Autocomplete
                    fullWidth
                    className='visaType'
                    id='combo-box-demo'
                    options={globalObject.lookupvalues[LookupTypes.TAD_VISA_TYPE] as OptionType[]}
                    value={
                      formik.values.visaType
                        ? globalObject.lookupvalues[LookupTypes.TAD_VISA_TYPE].find(
                            (option: OptionType) => option.id === formik.values.visaType,
                          )
                        : null
                    }
                    size='small'
                    sx={{
                      background: 'var(--Secondary-colors-Grey-3, #F1F4F8)',
                    }}
                    limitTags={1}
                    getOptionLabel={(option: any) => option.name}
                    filterSelectedOptions
                    onBlur={() => formik.setFieldTouched('visaType', true, true)}
                    renderInput={(params) => (
                      <TextField
                        placeholder='Choose...'
                        {...params}
                        error={Boolean(formik.errors.visaType && formik.touched.visaType)}
                        label=''
                      />
                    )}
                    onChange={(_e, val) => {
                      formik.setFieldValue('visaType', val ? val.id : undefined);
                    }}
                  />

                  <div className='mt-0 form-err-message'>{getError(formik, 'visaType')}</div>
                </div>
              ) : (
                <span className='text-label'>
                  {getNameFromId(formik.values.visaType, LookupTypes.TAD_VISA_TYPE)}
                </span>
              )}
            </div>
          </div>
          {mode === 'edit-page' && (
            <div style={{ padding: '1.87rem 1.88rem', borderTop: '1px solid #868686' }}>
              <div
                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Button
                  className='button'
                  onClick={() => {
                    navigate([
                      {
                        label: 'JOB DEFINITIONS',
                        link: '/source-job-definition',
                      },
                    ]);
                  }}
                  sx={{
                    width: '7.375rem',
                    height: '2.5rem',
                    borderRadius: '5px',
                    border: '1px solid #2D7DFE',
                    backgroundColor: '#FFF',
                    color: 'black',
                    textTransform: 'none',
                    fontSize: '1rem',
                    fontWeight: 400,
                  }}>
                  Cancel
                </Button>

                <div style={{ display: 'flex', gap: '15px' }}>
                  <Button
                    type='submit'
                    className='button'
                    onClick={() => {
                      setSubmitted(true);
                      formik.handleSubmit();
                    }}
                    sx={{
                      textTransform: 'none',
                      fontWeight: 400,
                      fontSize: '1rem',
                      width: '7.375rem',
                      height: '2.5rem',
                      color: 'white !important',
                      background: '#2D7DFE !important',
                    }}>
                    Next
                  </Button>
                </div>
              </div>
            </div>
          )}
        </Paper>
        <ContactCard
          contact={contact}
          anchorEl={contactAnchor}
          onClose={() => {
            setContact(null);
            setContactAnchor(null);
          }}
        />
      </div>
    </>
  );
};

export default JobDefinitionDetails;
