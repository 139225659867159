import React, { useEffect, useState } from 'react';
import { Done, Add } from '@mui/icons-material';
import { Box, Typography, InputBase, IconButton, Divider } from '@mui/material';
import { ReactComponent as Search } from '../../../assets/candidate-assets/search.svg';
import { getAllTadSjd, tagCandidateToSjd } from '../../../service/tadSjdService';
import globalObject from '../../../../common/global-variables';
import { TadCandidateDetailsModel } from '../../../models/tad-candidate-form-model';
import { getNameFromId } from '../../../common/commonUtil';
import { LookupTypes } from '../../../../common/lookupTypes';
import TadScreenLoader from '../../common/tad-screen-loader';

interface Props {
  candidate: TadCandidateDetailsModel;
}

function AddCandidateToJobDrawer({ candidate }: Props) {
  const [search, setSearch] = useState('');
  const [selectedSjds, setSelectedSjds] = useState([] as any[]);
  const [allSjdData, setAllSjdData] = useState([] as any[]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getAllTadSjd(globalObject.userObject.id)
      .then((res) => {
        setAllSjdData(res.data);
        setLoading(false);
      })
      .catch((error) => console.error('Error fetching sjd:', error));
  }, []);

  const handleTagCandidate = (jd: any) => {
    tagCandidateToSjd({
      sjdId: jd.id,
      candidateId: candidate.id,
      resumeId: candidate.resumeId,
    })
      .then((response) => {
        console.log(response.data.message);
      })
      .catch((error) => {
        console.error('Error tagging candidate to sjd:', error);
      });
  };

  const handleAddUser = (jd: any) => {
    if (!selectedSjds.some((user) => (user === jd) != null && jd.id)) {
      setSelectedSjds([...selectedSjds, jd]);
    }
    handleTagCandidate(jd);
  };

  const filteredData = allSjdData.filter((jd: any) =>
    jd.name.toLowerCase().includes(search.toLowerCase()),
  );

  const DrawerList = (
    <Box role='presentation' sx={{ width: '43.75rem', height: '64rem' }}>
      <Box
        sx={{
          padding: '3.19rem 1.88rem 0rem 1.69rem',
          display: 'flex',
          justifyContent: 'space-between',
        }}>
        <Typography sx={{ fontWeight: 600, fontSize: '1.2rem' }}>
          Add Candidate to a Job Definition
        </Typography>
      </Box>

      <Divider sx={{ margin: '1rem 0', backgroundColor: '#868686' }} />

      <Box
        sx={{ display: 'flex', alignItems: 'center', marginBottom: '1rem', marginLeft: '1.06rem' }}>
        <Search />
        <InputBase
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          sx={{ ml: '10px', width: '100%' }}
          placeholder='Search'
          inputProps={{ 'aria-label': 'Search a keyword' }}
        />
      </Box>
      <Divider />

      <Typography
        sx={{
          margin: '2rem 0 1.25rem 1.69rem',
          fontWeight: 400,
          fontSize: '1rem',
          color: '#000000',
        }}>
        Recent Job Definitions
      </Typography>

      <Box sx={{ paddingX: '1.5rem' }}>
        {filteredData.map((jd) => (
          <Box
            key={jd?.id}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: '0.63rem',
              padding: '0.5rem',
              backgroundColor: '#F1F4F8',
              borderRadius: '4px',
              '&:hover': { backgroundColor: '#E0E7EE' },
            }}>
            <Box sx={{ display: 'flex', flex: 1, justifyContent: 'space-between' }}>
              <Typography sx={{ color: '#1976D2', textDecoration: 'underline', width: '20%' }}>
                {jd?.name}
              </Typography>
              <Typography sx={{ width: '15%', textAlign: 'center' }}>
                {`${jd?.minExperienceLevel} - ${jd?.maxExperienceLevel} Years`}
              </Typography>
              <Typography sx={{ width: '15%', textAlign: 'center' }}>
                {getNameFromId(jd?.clientId, LookupTypes.TAD_CLIENT)}
              </Typography>
              <Typography sx={{ width: '25%', textAlign: 'center' }}>{jd.jobLocation}</Typography>
            </Box>
            <IconButton onClick={() => handleAddUser(jd)}>
              {selectedSjds.some((sjd) => sjd?.id === jd?.id) ? <Done /> : <Add />}
            </IconButton>
          </Box>
        ))}
      </Box>

      <Divider sx={{ margin: '0.5rem 0', backgroundColor: '#868686' }} />

      <Box
        display='flex'
        justifyContent='flex-end'
        sx={{ marginTop: '2rem', paddingRight: '1.5rem' }}>
        {/* <Button
          sx={{
            width: '7.375rem',
            height: '2.5rem',
            borderRadius: '5px',
            border: '1px solid #2D7DFE',
            backgroundColor: '#FFF',
            color: 'black',
            textTransform: 'none',
            fontSize: '1rem',
            fontWeight: 400,
            marginRight: '1rem',
          }}>
          Cancel
        </Button>
        <Button
          sx={{
            width: '7.375rem',
            height: '2.5rem',
            borderRadius: '5px',
            backgroundColor: '#2D7DFE',
            color: 'white',
            textTransform: 'none',
            fontSize: '1rem',
            fontWeight: 400,
          }}>
          Add
        </Button> */}
      </Box>
      <TadScreenLoader loading={loading} />
    </Box>
  );

  return <div>{DrawerList}</div>;
}

export default AddCandidateToJobDrawer;
