import { InputLabel, AvatarGroup, Avatar, Button } from '@mui/material';
import React from 'react';

const NotificationBar = () => {
  return (
    <div
      className='paper'
      style={{
        height: '143px',
        // marginBottom: '30px',
        display: 'flex',
        flexDirection: 'column',
        gap: '18px',
      }}>
      <div style={{ paddingLeft: '1rem' }}>
        <div style={{ marginTop: '21px' }}>
          <span style={{ fontWeight: 600 }}>Upcoming Interviews</span>
        </div>
        <div
          style={{
            display: 'flex',
            marginTop: '18px',
            justifyContent: 'space-between',
          }}>
          <div>
            <a href='Gopal Krishna'>
              <InputLabel id='name' sx={{ color: 'blue' }}>
                Gopal Krishna
              </InputLabel>
            </a>
            <span>Developer</span>
          </div>
          <div>
            <InputLabel className='field-label' id='name'>
              Time
            </InputLabel>
            <span>Developer</span>
          </div>
          <div>
            <InputLabel className='field-label' id='name'>
              Company
            </InputLabel>{' '}
            <span>Developer</span>
          </div>
          <div>
            <InputLabel className='field-label' id='name'>
              Job Location
            </InputLabel>{' '}
            <span>Developer</span>
          </div>
          <div>
            <InputLabel className='field-label' id='name'>
              Hiring Manager
            </InputLabel>{' '}
            <span>Developer</span>
          </div>
          <div>
            <InputLabel className='field-label' id='name'>
              Hiring for
            </InputLabel>{' '}
            <span>Developer</span>
          </div>
          <div>
            <InputLabel className='field-label' id='name'>
              Mode of Interview
            </InputLabel>{' '}
            <span>Developer</span>
          </div>
          <div>
            <InputLabel className='field-label' id='name'>
              Interviewers
            </InputLabel>{' '}
            <AvatarGroup max={4} sx={{ justifyContent: 'flex-end', height: '30px', width: '30px' }}>
              <Avatar
                alt='Remy Sharp'
                src='/static/images/avatar/1.jpg'
                sx={{ height: '30px', width: '30px' }}
              />
              <Avatar
                alt='Travis Howard'
                src='/static/images/avatar/2.jpg'
                sx={{ height: '30px', width: '30px' }}
              />
            </AvatarGroup>
          </div>
          <div>
            <InputLabel className='field-label' id='name'>
              Recruiters
            </InputLabel>{' '}
            <AvatarGroup
              className='avatars'
              max={3}
              sx={{ justifyContent: 'flex-end', height: '30px', width: '30px' }}>
              <Avatar
                alt='Remy Sharp'
                src='/static/images/avatar/1.jpg'
                sx={{ height: '30px', width: '30px' }}
              />
              <Avatar
                alt='Travis Howard'
                src='/static/images/avatar/2.jpg'
                sx={{ height: '30px', width: '30px' }}
              />
              <Avatar
                alt='Remy Sharp'
                src='/static/images/avatar/1.jpg'
                sx={{ height: '30px', width: '30px' }}
              />
              <Avatar
                alt='Travis Howard'
                src='/static/images/avatar/2.jpg'
                sx={{ height: '30px', width: '30px' }}
              />
            </AvatarGroup>
          </div>
          <div style={{ gap: '20px', display: 'flex' }}>
            <Button
              className='button'
              sx={{
                width: '7.375rem',
                height: '2.5rem',
                borderRadius: '5px',
                border: '1px solid #2D7DFE',
                backgroundColor: '#FFF',
                textTransform: 'none',
              }}>
              View Profile
            </Button>
            <Button
              className='button'
              sx={{
                width: '8.25rem',
                height: '2.5rem',
                marginRight: '10px',
                color: 'white !important',
                background: '#2D7DFE !important',
                textTransform: 'none',
              }}>
              Join Interview
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationBar;
