import axiosClient from '../../common/axiosClient';

export async function tadAddApprovals(request: any) {
  return axiosClient.post('/approvals', request);
}

export async function tadgetAllApprovals(userId?: any) {
  return axiosClient.get('/approvals', { params: { userId } });
}

export async function getprojectsByClientId(clientId?: any) {
  return axiosClient.get('/approvals/clientId', { params: { clientId } });
}

export async function tadUpdateApprovals(request: any) {
  return axiosClient.patch('/approvals', request);
}

export async function tadUpdateProjectClient(request: any) {
  return axiosClient.patch('/approvals/project', request);
}

export async function tadDeleteClientApprovals(clientId: any) {
  return axiosClient.delete('/approvals', { params: { clientId } });
}

export async function tadAddProjectsToClient(request: any) {
  return axiosClient.post('/approvals/add-projects', request);
}

export async function tadDeleteClientProjects(projectId: any, clientId: any) {
  return axiosClient.delete('/approvals/delete-projects', { params: { projectId, clientId } });
}
