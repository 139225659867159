import '../../components/dashboard/new-dashboard.scss';
import {
  Grid,
  Paper,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  styled,
  Box,
  Tab,
  Tabs,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';
import { PieChart, useDrawingArea } from '@mui/x-charts';
import { useRef, useState } from 'react';
import TadTableSorting from '../common/tad-table-sorting';
// import DashboardOverlay from '../dashboard/dashboard-overview';
import { ReactComponent as AddOutlineIcon } from '../../assets/sjd/Add_OutlineIcon.svg';
import { ReactComponent as SearchIcon } from '../../assets/candidate-assets/search.svg';
import { ReactComponent as Course } from '../../assets/candidate-assets/image 61.svg';
import { ReactComponent as SuperVisor } from '../../assets/candidate-assets/supervisor_account.svg';
import { ReactComponent as AddPerson } from '../../assets/candidate-assets/person_add (1).svg';
import { ReactComponent as MoreVertIcon } from '../../assets/dashboard-assets/more_vert.svg';

interface Props {
  selectedType: any;
}

const cardStyle = {
  width: '19rem',
  height: '10rem',
  borderRadius: '0.3125rem',
  border: '2px solid #C1C0C0',
  marginLeft: '2rem',
};

const CustomPieChart = ({
  data,
  width = 100,
  height = 100,
  innerRadius = 30,
  label,
}: {
  data: { value: number; color: string }[];
  width?: number;
  height?: number;
  innerRadius?: number;
  label: string;
}) => {
  const StyledText = styled('text')(({ theme }) => ({
    fill: theme.palette.text.primary,
    textAnchor: 'middle',
    dominantBaseline: 'central',
    fontSize: 14,
  }));

  function PieCenterLabel({ children }: { children: React.ReactNode }) {
    const { width, height, left, top } = useDrawingArea();
    return (
      <StyledText x={left + width / 2} y={top + height / 2}>
        {children}
      </StyledText>
    );
  }

  return (
    <PieChart series={[{ data, innerRadius }]} width={width} height={height}>
      <PieCenterLabel>{label}</PieCenterLabel>
    </PieChart>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const NewTadManagerDashboard = ({ selectedType }: Props) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const originalData = [{ value: 5 }];
  const totalSum = originalData.reduce((acc, item) => acc + item.value, 0);
  const completedValue = totalSum * 0.5;
  const uncompletedValue = totalSum - completedValue;

  const newData = [
    { value: uncompletedValue, color: '#EEEEEE' },
    { value: completedValue, color: '#4BB543' },
  ];

  const newDataInd = [
    { value: uncompletedValue, color: '#EEEEEE' },
    { value: completedValue, color: '#1976D2' },
  ];

  const newDataUS = [
    { value: uncompletedValue, color: '#EEEEEE' },
    { value: completedValue, color: ' #F26C20' },
  ];

  const newDataPracticipation = [
    { value: uncompletedValue, color: '#EEEEEE' },
    { value: completedValue, color: ' #DC3857' },
  ];

  const coursesData = [
    { name: 'AWS Certified Cloud Practitioner', completion: '63%', employees: 88 },
    { name: 'Google Cloud Practitioner', completion: '22%', employees: 58 },
    { name: 'Program Management', completion: '15%', employees: 22 },
    { name: 'Project Management Professional', completion: '6%', employees: 13 },
    { name: 'Data Science Fundamentals', completion: '45%', employees: 75 },
    { name: 'Advanced Python Programming', completion: '38%', employees: 50 },
    { name: 'Cybersecurity Essentials', completion: '29%', employees: 40 },
    { name: 'Machine Learning Basics', completion: '72%', employees: 60 },
    { name: 'Digital Marketing Strategies', completion: '58%', employees: 95 },
    { name: 'Blockchain for Beginners', completion: '20%', employees: 27 },
    { name: 'Cloud Infrastructure', completion: '65%', employees: 88 },
    { name: 'Big Data Analytics', completion: '33%', employees: 45 },
    { name: 'Agile Project Management', completion: '55%', employees: 70 },
    { name: 'DevOps Fundamentals', completion: '47%', employees: 62 },
  ];

  const headers = [
    { id: 1, label: 'ALL' },
    { id: 2, label: 'Certifications' },
    { id: 3, label: 'Courses' },
    { id: 4, label: 'Assigned To' },
  ];

  const references: any[] = [];
  headers.forEach(() => references.push(useRef<HTMLDivElement>(null)));
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    const elementPos = references[newValue].current.getBoundingClientRect().top;
    const tabsPos = document.getElementById('page-tabs')?.getBoundingClientRect().top;
    const offsetPos = elementPos + window.pageYOffset - (!tabsPos ? 0 : tabsPos + 5);
    window.scrollTo({
      top: offsetPos,
      behavior: 'smooth',
    });
    setSelectedTab(newValue);
  };

  function setSjdRows(value: any[]) {
    console.log(value);
    throw new Error('Function not implemented.');
  }

  function setSjdList(value: any[]) {
    console.log(value);

    throw new Error('Function not implemented.');
  }

  const data = [
    {
      id: 1,
      course: 'AWS Certified Cloud Practitioner',
      duration: '90 minutes',
      source: 'aws.amazon.com',
      type: 'Certificate',
      addedBy: 'Srinivas Desai',
      assignedTo: '14',
    },
    {
      id: 2,
      course: 'Azure Fundamentals',
      duration: '60 minutes',
      source: 'azure.microsoft.com',
      type: 'Certificate',
      addedBy: 'John Doe',
      assignedTo: '8',
    },
    {
      id: 3,
      course: 'Google Cloud Associate',
      duration: '120 minutes',
      source: 'cloud.google.com',
      type: 'Certificate',
      addedBy: 'Jane Smith',
      assignedTo: '20',
    },
    {
      id: 4,
      course: 'CompTIA Security+',
      duration: '150 minutes',
      source: 'comptia.org',
      type: 'Certification',
      addedBy: 'Michael Brown',
      assignedTo: '5',
    },
  ];

  return (
    <>
      <div className='dashboard-header'>
        {/* <DashboardOverlay open={true} /> */}
        <span className='heading' style={{ marginLeft: '2rem' }}>
          Hello John, Good Morning!
        </span>
      </div>
      <div className='main-content' style={{ display: 'flex' }}>
        <div style={{ width: '55%' }}>
          <Grid container spacing={2} sx={{ marginTop: '1.5rem' }}>
            <div style={{ display: 'flex', gap: '8rem' }}>
              <Grid item xs={12} sm={6} md={4} lg={2}>
                <Paper sx={cardStyle}>
                  <Typography
                    sx={{
                      color: 'var(--Primary-Dark-grey-500, #333)',
                      fontFamily: 'Roboto',
                      fontSize: '1rem',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      lineHeight: 'normal',
                      margin: '1rem 2rem 0rem 1rem',
                    }}>
                    Total Certifications
                  </Typography>
                  <Typography
                    sx={{
                      color: 'var(--Primary-Blue-500, #1976D2)',
                      fontFamily: 'Roboto',
                      fontSize: '2rem',
                      fontStyle: 'normal',
                      fontWeight: 600,
                      lineHeight: 'normal',
                      margin: '3rem 1rem 0rem 1rem',
                    }}>
                    75
                  </Typography>
                </Paper>
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={2}>
                <Paper sx={cardStyle}>
                  <Typography
                    sx={{
                      color: 'var(--Primary-Dark-grey-500, #333)',
                      fontFamily: 'Roboto',
                      fontSize: '1rem',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      lineHeight: 'normal',
                      margin: '1rem 2rem 0rem 1rem',
                    }}>
                    Total Courses
                  </Typography>
                  <Typography
                    sx={{
                      color: 'var(--Primary-Blue-500, #1976D2)',
                      fontFamily: 'Roboto',
                      fontSize: '2rem',
                      fontStyle: 'normal',
                      fontWeight: 600,
                      lineHeight: 'normal',
                      margin: '3rem 1rem 0rem 1rem',
                    }}>
                    22
                  </Typography>
                </Paper>
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={2}>
                <Paper sx={cardStyle}>
                  <div>
                    <Typography
                      sx={{
                        fontWeight: 400,
                        lineHeight: 'normal',
                        marginLeft: '1rem',
                        marginTop: '5%',
                        width: '4rem',
                        whiteSpace: 'wrap',
                      }}>
                      Completion
                    </Typography>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',

                        width: '100%',
                        marginLeft: '40%',
                      }}>
                      <CustomPieChart
                        data={newData}
                        width={150}
                        height={100}
                        innerRadius={40}
                        label='50%'
                      />
                    </div>
                    <Typography
                      sx={{
                        fontWeight: 400,
                        lineHeight: 'normal',
                        marginLeft: '1rem',
                        marginBottom: '1rem',
                      }}>
                      Target: 50%
                    </Typography>
                  </div>
                </Paper>
              </Grid>
            </div>
            <div
              style={{
                display: 'flex',
                gap: '8rem',
                paddingTop: '1%',
                marginTop: '2%',
              }}>
              <Grid item xs={12} sm={6} md={4} lg={2}>
                <Paper sx={cardStyle}>
                  <Typography
                    sx={{
                      fontWeight: 400,
                      lineHeight: 'normal',
                      marginLeft: '1rem',
                      marginTop: '5%',
                      width: '4rem',
                      whiteSpace: 'wrap',
                    }}>
                    IndOperations
                  </Typography>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'right',
                      width: '100%',
                      marginLeft: '40%',
                    }}>
                    <CustomPieChart
                      data={newDataInd}
                      width={150}
                      height={100}
                      innerRadius={40}
                      label='58.4%'
                    />
                  </div>
                  <Typography
                    sx={{
                      fontWeight: 400,
                      lineHeight: 'normal',
                      marginLeft: '1rem',
                      marginBottom: '1rem',
                    }}>
                    Target: 50%
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={2}>
                <Paper sx={cardStyle}>
                  <Typography
                    sx={{
                      fontWeight: 400,
                      lineHeight: 'normal',
                      marginLeft: '1rem',
                      marginTop: '5%',
                      width: '4rem',
                      whiteSpace: 'wrap',
                    }}>
                    USOperations
                  </Typography>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'right',
                      width: '100%',
                      marginLeft: '40%',
                    }}>
                    <CustomPieChart
                      data={newDataUS}
                      width={150}
                      height={100}
                      innerRadius={40}
                      label='37.5%'
                    />
                  </div>
                  <Typography
                    sx={{
                      fontWeight: 400,
                      lineHeight: 'normal',
                      marginLeft: '1rem',
                      marginBottom: '1rem',
                    }}>
                    Target: 50%
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={2}>
                <Paper sx={cardStyle}>
                  <Typography
                    sx={{
                      fontWeight: 400,
                      lineHeight: 'normal',
                      marginLeft: '1rem',
                      marginTop: '5%',
                      width: '4rem',
                      whiteSpace: 'wrap',
                    }}>
                    Participation
                  </Typography>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'right',
                      width: '100%',
                      marginLeft: '40%',
                    }}>
                    <CustomPieChart
                      data={newDataPracticipation}
                      width={150}
                      height={100}
                      innerRadius={40}
                      label='26%'
                    />
                  </div>
                  <Typography
                    sx={{
                      fontWeight: 400,
                      lineHeight: 'normal',
                      marginLeft: '1rem',
                      marginBottom: '1rem',
                    }}>
                    Target: 50%
                  </Typography>
                </Paper>
              </Grid>
            </div>
          </Grid>
        </div>

        <div className='content-right' style={{ display: 'flex', alignItems: 'center' }}>
          <Grid item xs={12} lg={6}>
            <Paper
              sx={{
                padding: '1rem',
                border: '1px solid #e0e0e0',
                boxShadow: 3,
                borderRadius: 2,
                width: '45rem',
                height: '20rem',
                marginTop: '1.3rem',
              }}>
              <Typography
                sx={{
                  fontWeight: 600,
                  lineHeight: 'normal',
                  marginLeft: '1rem',
                  marginBottom: '0.5rem',
                }}>
                Course Completion %
              </Typography>
              <hr style={{ color: '#C1C0C0', margin: '0.5rem 0' }} />

              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      align='left'
                      sx={{ padding: '0.5rem 0', fontWeight: 'bold', color: '#555' }}>
                      Course Name
                    </TableCell>
                    <TableCell
                      align='center'
                      sx={{ padding: '0.5rem 0', fontWeight: 'bold', color: '#555' }}>
                      Completion %
                    </TableCell>
                    <TableCell
                      align='center'
                      sx={{ padding: '0.5rem 0', fontWeight: 'bold', color: '#555' }}>
                      Employees
                    </TableCell>
                  </TableRow>
                </TableHead>
              </Table>

              <Box sx={{ maxHeight: '13rem', overflowY: 'auto' }}>
                <Table sx={{ width: '100%', fontSize: '0.875rem' }}>
                  <TableBody>
                    {coursesData.map((course, index) => (
                      <TableRow key={index}>
                        <TableCell align='left' sx={{ padding: '0.5rem' }}>
                          <a href='#' style={{ textDecoration: 'underline', color: '#1976D2' }}>
                            {course.name}
                          </a>
                        </TableCell>
                        <TableCell align='center' sx={{ padding: '0.5rem' }}>
                          {course.completion}
                        </TableCell>
                        <TableCell align='center' sx={{ padding: '0.5rem' }}>
                          {course.employees}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Paper>
          </Grid>
        </div>
      </div>
      <div style={{ width: '100%', textAlign: 'center' }}>
        <div style={{ marginBottom: '-7px', marginLeft: '-18px' }}>
          <div style={{ position: 'relative' }}>
            <Tabs
              className='tab'
              value={selectedTab}
              onChange={handleChange}
              sx={{
                display: 'inline-block',
              }}>
              {headers.map((header, index) => (
                <Tab
                  className='selected-tab'
                  sx={{
                    color: 'var(--Body-P, var(--Primary-Dark-grey-500, #333))',
                    fontWeight: 400,
                    fontSize: '1rem',
                    textTransform: 'none',
                    display: 'inline-block',
                  }}
                  key={header.id}
                  value={index}
                  label={header.label}
                />
              ))}
            </Tabs>

            <hr
              style={{
                color: '#C1C0C0',
                margin: 0,
                marginBottom: '3.63rem',
                paddingBottom: '2rem',
              }}></hr>
          </div>
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', padding: '1rem 2rem' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ fontSize: '1.25rem', marginRight: '1.56rem', fontWeight: 600 }}>
            All ({data.length})
          </span>
          <IconButton>
            <AddOutlineIcon />
          </IconButton>
        </div>
        <div style={{ display: 'flex', alignItems: 'baseline', gap: '0.5rem' }}>
          <div>
            <span style={{ color: '#1976D2', textDecoration: 'underline', cursor: 'pointer' }}>
              Filter By
            </span>
          </div>
          <TextField
            placeholder='Search'
            variant='outlined'
            sx={{
              width: '21.188rem',
              height: '40px',
              borderRadius: '0.625rem',
              border: '1px solid #C1C0C0',
              background: 'var(--Secondary-colors-White, #FFF)',
              flexShrink: 0,
              padding: 0,
              '& .MuiOutlinedInput-root': {
                padding: '0 0.5rem',
                height: '100%',
                boxSizing: 'border-box',
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TadTableSorting
            sortingData={(value) => {
              setSjdRows(value);
              setSjdList(value);
            }}
            data={[]}
          />
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
          marginLeft: '1.94rem',
          marginRight: '2rem',
        }}>
        {data.map((item) => (
          <div
            key={item.id}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              border: '1px solid #ddd',
              borderRadius: '8px',
              backgroundColor: '#fff',
              gap: '6rem',
              padding: '2.56rem 1.25rem 2.5rem 3rem',
            }}>
            {/* Course */}
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Course style={{ width: '6.5rem', height: '3.9375rem' }} />
            </div>

            {/* Course Name */}
            <div>
              <Typography
                variant='h6'
                style={{
                  color: 'var(--Heading-1, #333)',
                  fontFamily: 'Roboto',
                  fontSize: 'var(--Font-size-h6, 19px)',
                  fontStyle: 'normal',
                  fontWeight: '600',
                  lineHeight: 'normal',
                  width: '13rem',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}>
                {item.course}
              </Typography>

              <Typography variant='body2' color='textSecondary'>
                {item.duration}
              </Typography>
            </div>

            {/* Source */}
            <div>
              <Typography
                variant='h6'
                style={{
                  color: '#999',
                  fontFamily: 'Roboto',
                  fontSize: '1rem',
                  fontStyle: 'normal',
                  fontWeight: '400',
                  lineHeight: 'normal',
                  width: '13rem',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}>
                Source
              </Typography>

              <a
                href={item.source}
                style={{ color: 'var(--Primary-Blue-500, #1976D2)', fontWeight: 400 }}>
                {item.source}
              </a>
            </div>

            {/* Type */}
            <div>
              <Typography
                variant='h6'
                style={{
                  color: '#999',
                  fontFamily: 'Roboto',
                  fontSize: '1rem',
                  fontStyle: 'normal',
                  fontWeight: '400',
                  lineHeight: 'normal',
                  width: '13rem',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}>
                Type
              </Typography>

              <Typography
                variant='body2'
                style={{
                  color: 'var(--Primary-Dark-grey-500, #333)',
                  fontSize: '1rem',
                  fontStyle: 'normal',
                  fontWeight: '400',
                }}>
                {item.type}
              </Typography>
            </div>

            {/* Added By */}
            <div>
              <Typography
                variant='h6'
                style={{
                  color: '#999',
                  fontFamily: 'Roboto',
                  fontSize: '1rem',
                  fontStyle: 'normal',
                  fontWeight: '400',
                  lineHeight: 'normal',
                  width: '13rem',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}>
                Added By
              </Typography>

              <a
                href='www.google.com'
                style={{ color: 'var(--Primary-Blue-500, #1976D2)', fontWeight: 400 }}>
                {item.addedBy}
              </a>
            </div>

            {/* Assigned To */}
            <div>
              <Typography
                variant='h6'
                style={{
                  color: '#999',
                  fontFamily: 'Roboto',
                  fontSize: '1rem',
                  fontStyle: 'normal',
                  fontWeight: '400',
                  lineHeight: 'normal',
                  width: '13rem',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}>
                Assigned to
              </Typography>

              <div style={{ display: 'flex', alignItems: 'center' }}>
                <SuperVisor />
                <a
                  href='https://www.google.com'
                  style={{
                    color: 'var(--Primary-Blue-500, #1976D2)',
                    fontWeight: 400,
                    marginLeft: '8px',
                  }}>
                  {item.assignedTo}
                </a>
              </div>
            </div>

            {/* Add Person */}
            <div>
              <AddPerson />
            </div>

            {/* More Options */}
            <div>
              <MoreVertIcon />
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default NewTadManagerDashboard;
