import React, { useEffect, useState } from 'react';
// import PageHeader from '../../../components/common/page-header/page-header';
import {
  Avatar,
  Checkbox,
  Chip,
  IconButton,
  InputAdornment,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
// import TadTable from '../common/tad-table';
import { SelectChangeEvent } from '@mui/material/Select';
import { ReactComponent as CallIcon } from '../../assets/candidate-assets/call.svg';
import { ReactComponent as Explicit } from '../../assets/candidate-assets/explicit.svg';
import { ReactComponent as LightBulb } from '../../assets/candidate-assets/lightbulb.svg';
import { ReactComponent as AttachMoney } from '../../assets/candidate-assets/attach_money.svg';
import { ReactComponent as Location } from '../../assets/candidate-assets/location_on.svg';
import { ReactComponent as Monetization } from '../../assets/candidate-assets/monetization_on.svg';
import { ReactComponent as Star } from '../../assets/candidate-assets/star_outline.svg';
import { ReactComponent as Trending } from '../../assets/candidate-assets/trending_up.svg';
import { ReactComponent as Accountbalance } from '../../assets/candidate-assets/account_balance.svg';
import { ReactComponent as DownloadIcon } from '../../assets/candidate-assets/download.svg';
import { ReactComponent as RemoveIcon } from '../../assets/candidate-assets/Group.svg';
import { ReactComponent as ShareIcon } from '../../assets/candidate-assets/share.svg';
import { ReactComponent as MoveToNextIcon } from '../../assets/candidate-assets/next_week.svg';
import { ReactComponent as DuplicateIcon } from '../../assets/candidate-assets/file_copy.svg';
import { ReactComponent as DropDownArrow } from '../../assets/sjd/dropdownarrow.svg';
import { ReactComponent as SearchIcon } from '../../assets/candidate-assets/search.svg';
// import { ReactComponent as WidgetsIcon } from '../../assets/candidate-assets/widgets.svg';
// import { ReactComponent as FormatListIcon } from '../../assets/candidate-assets/format_list_bulleted.svg';
// import { ReactComponent as ExportIcon } from '../../assets/candidate-assets/save_alt.svg';
// import { ReactComponent as SortIcon } from '../../assets/candidate-assets/sort_by_alpha.svg';
import { ReactComponent as CalenderIcon } from '../../assets/candidate-assets/calendar_today.svg';
import { ReactComponent as MoreVertIcon } from '../../assets/dashboard-assets/more_vert.svg';
import { ReactComponent as AddOutlineIcon } from '../../assets/sjd/Add_OutlineIcon.svg';
import './../candidate/candidate-table.scss';
import useNavigate from '../../../common/useNavigate';
import TadTableFilter from '../common/tad-table-filter';
import TadPageHeader from '../common/page-header/tad-page-header';
import TadTableSorting from '../common/tad-table-sorting';
import { tadGetAllCandidate } from '../../service/tadCandidateService';
import dayjs from 'dayjs';
import { getInitials, getNameFromId } from '../../../common/commonUtil';
import { LookupTypes } from '../../../common/lookupTypes';
import globalObject from '../../../common/global-variables';
import { lightBlue } from '@mui/material/colors';
import ContactCard from '../common/contact-card';
import TadScreenLoader from '../common/tad-screen-loader';
// import { CandidateStatus } from '../../../common/candidateStatus';

const CandidateTable = () => {
  const headers = [
    { id: 1, label: 'Candidate' },
    { id: 2, label: 'Job Definition' },
    { id: 3, label: 'PR Rating & Score' },
    { id: 4, label: 'Location & Mobile' },
    { id: 5, label: 'Experience & Notice' },
    { id: 6, label: 'Skills & Salary' },
    { id: 7, label: 'Created On' },
    { id: 8, label: 'Modified On' },
    { id: 9, label: 'Uploaded By' },
    { id: 10, label: 'Status' },
    // { id: 11, label: 'Actions' },
  ];

  const [data, setData] = useState([] as any[]);
  const [candidateRows, setcandidateRows] = useState([] as any[]);
  const [selectedCandidates, setSelectedCandidates] = useState<string[]>([]);
  const [candidateAnchorEl, setCandidateAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [rowsToDisplay, setRowsToDisplay] = useState(5);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchTeam, setSearchTeam] = useState('');
  const [selectedJobs, setSelectedJobs] = useState<string[]>([]);
  const [selectedNoticePeriod, setSelectedNoticePeriod] = useState<string[]>([]);
  const [selectedLocation, setSelectedLocation] = useState<string[]>([]);
  const [selectedTech, setSelectedTech] = useState<string[]>([]);
  const [navigate] = useNavigate();
  const [filterView, setFilterView] = useState(false);
  // const [tabIconChange, setTabIconChange] = useState(false);
  // const [tabListIconChange, setTabListIconChange] = useState(true);
  const [loading, setLoading] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState<number[]>(
    headers.map((header: any) => header.id),
  );
  const [contact, setContact] = useState({} as any);
  const [contactAnchor, setContactAnchor] = useState<null | HTMLElement>(null);

  // const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   if (event.target.checked) {
  //     setSelectedCandidates(data.map((candidate) => candidate.id));
  //   } else {
  //     setSelectedCandidates([]);
  //   }
  // };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleSelect = (candidateId: string) => {
    if (selectedCandidates.includes(candidateId)) {
      setSelectedCandidates(selectedCandidates.filter((id) => id !== candidateId));
    } else {
      setSelectedCandidates([...selectedCandidates, candidateId]);
    }
  };

  useEffect(() => {
    setLoading(true);
    tadGetAllCandidate(true)
      .then((res: any) => {
        setData(res.data);
        setcandidateRows(res.data);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  }, []);

  const handleCreatedByUsers = (id: any) => {
    return globalObject.users?.find((intern: any) => intern.id === id);
  };

  const CandidateActionsList = [
    {
      name: 'Download Resume',
      icon: DownloadIcon,
    },
    { name: 'Move to next stage', icon: MoveToNextIcon },
    { name: 'Share', icon: ShareIcon },
    { name: 'Find Duplicates', icon: DuplicateIcon },
    { name: 'Remove', icon: RemoveIcon },
  ];

  // const actionList = [
  //   { name: 'Sort By', icon: SortIcon },
  //   { name: 'Export to Excel', icon: ExportIcon },
  //   { name: 'Remove all Candidates', icon: RemoveIcon },
  // ];
  // const showCandidatesActionsList = (event: React.MouseEvent<HTMLElement>) => {
  //   setCandidateAnchorEl(event.currentTarget);
  // };

  const closeCandidatesActionsMenu = () => {
    setCandidateAnchorEl(null);
  };

  // const showActionsList = (event: React.MouseEvent<HTMLElement>) => {
  //   setAnchorEl(event.currentTarget);
  // };
  const closeActionsMenu = () => {
    setAnchorEl(null);
  };

  const handleLoadMore = () => {
    setRowsToDisplay((prev) => {
      const newRowsToDisplay = Math.min(prev + rowsPerPage, candidateRows.length);
      setRowsPerPage(newRowsToDisplay);
      return newRowsToDisplay;
    });
  };

  const handleChangeRowsPerPage = (event: SelectChangeEvent<number>) => {
    const newRowsPerPage = parseInt(event.target.value as string, 10);
    setRowsPerPage(newRowsPerPage);

    const newRowsToDisplay = Math.min(newRowsPerPage, data.length);
    setRowsToDisplay(newRowsToDisplay);
    if (newRowsToDisplay == -1) {
      setcandidateRows(data);
    }
  };
  const handleSearch = (search?: string) => {
    let c = data;
    if (search && search.length) {
      c = c.filter(
        (candidate: any) =>
          (candidate.name && candidate.name.toLowerCase().includes(search.toLowerCase())) ||
          (candidate.email && candidate.email.toLowerCase().includes(search.toLowerCase())),
      );
    }
    setcandidateRows(c);
  };

  // const statusColorMapping: { [key: string]: string } = {
  //   'Profile Uploaded': '#B8D5F1',
  //   'QC Rated': '#C7E8C5',
  //   'Profiler Rated': '#C7E8C5',
  //   'Online Test Scheduled': '#B8D5F1',
  //   'Online Test cleared': '#C7E8C5',
  //   'Tech Interview-1 Scheduled': '#B8D5F1',
  //   'Tech Interview-1 cleared': '#C7E8C5',
  //   'Tech Interview-2 Scheduled': '#B8D5F1',
  //   'Tech Interview-2 cleared': '#C7E8C5',
  //   'Tech Interview-3 Scheduled': '#B8D5F1',
  //   'Tech Interview-3 cleared': '#C7E8C5',
  //   'Tech Interview-4 Scheduled': '#B8D5F1',
  //   'Tech Interview-4 cleared': '#C7E8C5',
  //   'Tech Interview-5 Scheduled': '#B8D5F1',
  //   'Tech Interview-5 cleared': '#C7E8C5',
  //   'Client Interview Scheduled': '#B8D5F1',
  //   'Client Interview Cleared': '#C7E8C5',
  //   'HR Interview Scheduled': '#B8D5F1',
  //   'HR Interview Cleared': '#C7E8C5',
  //   'Document Verification Started': '#C7E8C5',
  //   'Document Verification Completed': '#C7E8C5',
  //   'Offer approved': '#B8D5F1',
  //   'Offer released': '#B8D5F1',
  //   'Offer accepted': '#C7E8C5',
  //   Joined: '#C7E8C5',
  //   Rejected: '#F4C1CB',
  //   'Not Interested': '#F4C1CB',
  //   Callback: '#B8D5F1',
  //   'On Hold': '#FEE6B8',
  // };

  // const statusOptions = globalObject.lookupvalues[LookupTypes.CANDIDATE_STATUS]?.map(
  //   (status: any) => ({
  //     ...status,
  //     color: statusColorMapping[status.name],
  //   }),
  // );

  const byJobDefinitionData = [
    { id: '0', name: 'Dot Net Developer', count: '4' },
    { id: '1', name: 'Java Developer', count: '5' },
    { id: '2', name: 'Python Developer', count: '1' },
  ];

  const handleSelectJob = (jobId: string) => {
    setSelectedJobs((prev) => {
      if (prev.includes(jobId)) {
        return prev.filter((id) => id !== jobId);
      } else {
        return [...prev, jobId];
      }
    });
  };

  const byLocationData = [
    { id: '0', name: 'Hyderabad', count: '4' },
    { id: '1', name: 'Bangalore', count: '5' },
    { id: '2', name: 'Pune', count: '1' },
  ];
  const handleSelectLocation = (locationId: string) => {
    setSelectedLocation((prev) => {
      if (prev.includes(locationId)) {
        return prev.filter((id) => id !== locationId);
      } else {
        return [...prev, locationId];
      }
    });
  };

  const byTechnologyData = [
    { id: '0', name: 'Dot Net Developer', count: '4' },
    { id: '1', name: 'Java Advanced', count: '5' },
    { id: '2', name: 'Python fullStack', count: '1' },
  ];
  const handleSelectTech = (TechId: string) => {
    setSelectedTech((prev) => {
      if (prev.includes(TechId)) {
        return prev.filter((id) => id !== TechId);
      } else {
        return [...prev, TechId];
      }
    });
  };

  const byNoticePeriodData = [
    { id: '0', name: 'Immediate', count: '1' },
    { id: '1', name: '60days', count: '2' },
    { id: '2', name: '30days', count: '2' },
    { id: '3', name: '45days', count: '1' },
  ];

  const handleSelectNoticePeriod = (Id: string) => {
    setSelectedNoticePeriod((prev) => {
      if (prev.includes(Id)) {
        return prev.filter((id) => id !== Id);
      } else {
        return [...prev, Id];
      }
    });
  };

  const getSkills = (skills: any[]): React.ReactNode => {
    const skillsText = skills.map((s: any) => s.name).join(', ');
    return (
      <>
        <Tooltip title={skillsText}>
          <span>
            {skills.length > 2
              ? `${skills
                  .slice(0, 2)
                  .map((s: any) => s.name)
                  .join(', ')}...`
              : skillsText
              ? skillsText
              : '-'}
          </span>
        </Tooltip>
      </>
    );
  };

  return (
    <div className='main-div'>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div>
          <TadPageHeader />
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '0.5rem',
          }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ fontSize: '1.25rem', marginRight: '1.56rem', fontWeight: 600 }}>
              Candidates ({candidateRows.length})
            </span>
            <IconButton
              onClick={() =>
                navigate([
                  {
                    label: 'Add Candidate',
                    link: '/candidate/add-candidate',
                  },
                ])
              }>
              <AddOutlineIcon />
            </IconButton>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', gap: '1.5rem' }}>
            <div>
              <span
                style={{ color: '#1976D2', textDecoration: 'underline', cursor: 'pointer' }}
                onClick={() => setFilterView(!filterView)} // add here Filter By text
              ></span>
            </div>
            <TadTableFilter
              headers={headers}
              selectedHeaders={(value) => setSelectedColumns(value)}
            />
            <div>
              <TadTableSorting
                data={candidateRows}
                sortingData={(value: any) => setcandidateRows(value)}
              />
            </div>
            {/* <IconButton 
            // onClick={showActionsList}
             style={{ marginLeft: '10px' }}>
              <MoreVertIcon />
            </IconButton> */}
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={closeActionsMenu}>
              {/* {actionList.map((action, index) => (
                <MenuItem key={index} onClick={() => closeActionsMenu()}>
                  <ListItemIcon>
                    <action.icon style={{ width: '20px', height: '20px' }} />
                  </ListItemIcon>
                  <ListItemText primary={action.name} />
                </MenuItem>
              ))} */}
            </Menu>
          </div>
        </div>

        <div style={{ display: 'flex', width: '100%' }} className='paper'>
          {filterView && (
            <div style={{ marginRight: '20px', width: '18.75rem' }}>
              <Paper style={{ width: '100%' }}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginLeft: '1.25rem',
                    marginRight: '1.25rem',
                    paddingTop: '20px',
                  }}>
                  <span className='header-label'>Filter By</span>
                  <span
                    className='header-label'
                    style={{
                      color: 'var(--Hyperlink, var(--Primary-Blue-500, #1976D2))',
                    }}>
                    Clear All
                  </span>
                </div>
                <div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginLeft: '1.25rem',
                      marginRight: '1.25rem',
                      paddingTop: '20px',
                    }}>
                    <span
                      style={{ color: 'var(--Primary-Dark-grey-500, #333)', fontWeight: '600' }}>
                      Notice Period
                    </span>
                    <span
                      style={{
                        fontFamily: 'Roboto',
                        color: 'var(--Hyperlink, var(--Primary-Blue-500, #1976D2))',
                        textDecoration: 'underline',
                      }}>
                      Clear All
                    </span>
                  </div>
                  <hr
                    style={{
                      color: '#C1C0C0',
                      marginLeft: '1.25rem',
                      marginRight: '1.25rem',
                    }}></hr>
                  <div>
                    <TextField
                      value={searchTeam}
                      onChange={(e) => {
                        setSearchTeam(e.target.value);
                      }}
                      placeholder='Search'
                      variant='outlined'
                      sx={{
                        width: '16.1875rem',
                        height: '2.1875rem',
                        borderRadius: '0.625rem',
                        border: '1px solid #C1C0C0',
                        background: 'var(--Secondary-colors-White, #FFF)',
                        flexShrink: 0,
                        marginRight: '1.25rem',
                        marginLeft: '1.25rem',
                        padding: 0,
                        '& .MuiOutlinedInput-root': {
                          padding: '0 0.5rem',
                          height: '100%',
                          boxSizing: 'border-box',
                        },
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>
                            <IconButton>
                              <SearchIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  <div>
                    <List>
                      {byNoticePeriodData.map((notice) => (
                        <ListItem key={notice.id}>
                          <Checkbox
                            checked={selectedNoticePeriod.includes(notice.id)}
                            onChange={() => handleSelectNoticePeriod(notice.id)}
                            inputProps={{ 'aria-label': `select job ${notice.name}` }}
                          />
                          <Typography variant='body1'>
                            {notice.name} ({notice.count})
                          </Typography>
                        </ListItem>
                      ))}
                    </List>
                  </div>
                  <span
                    style={{
                      color: 'var(--Hyperlink, var(--Primary-Blue-500, #1976D2))',
                      textDecoration: 'underline',
                      fontWeight: '400',
                      marginLeft: '60px',
                    }}>
                    More...
                  </span>
                </div>
                <div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginLeft: '1.25rem',
                      marginRight: '1.25rem',
                      paddingTop: '20px',
                    }}>
                    <span
                      style={{ color: 'var(--Primary-Dark-grey-500, #333)', fontWeight: '600' }}>
                      Job Definition
                    </span>
                    <span
                      style={{
                        fontFamily: 'Roboto',
                        color: 'var(--Hyperlink, var(--Primary-Blue-500, #1976D2))',
                        textDecoration: 'underline',
                      }}>
                      Clear All
                    </span>
                  </div>
                  <hr
                    style={{
                      color: '#C1C0C0',
                      marginLeft: '1.25rem',
                      marginRight: '1.25rem',
                    }}></hr>
                  <div>
                    <TextField
                      value={searchTeam}
                      onChange={(e) => {
                        setSearchTeam(e.target.value);
                      }}
                      placeholder='Search'
                      variant='outlined'
                      sx={{
                        width: '16.1875rem',
                        height: '2.1875rem',
                        borderRadius: '0.625rem',
                        border: '1px solid #C1C0C0',
                        background: 'var(--Secondary-colors-White, #FFF)',
                        flexShrink: 0,
                        marginRight: '1.25rem',
                        marginLeft: '1.25rem',
                        padding: 0,
                        '& .MuiOutlinedInput-root': {
                          padding: '0 0.5rem',
                          height: '100%',
                          boxSizing: 'border-box',
                        },
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>
                            <IconButton>
                              <SearchIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  <div>
                    <List>
                      {byJobDefinitionData.map((job) => (
                        <ListItem key={job.id}>
                          <Checkbox
                            checked={selectedJobs.includes(job.id)}
                            onChange={() => handleSelectJob(job.id)}
                            inputProps={{ 'aria-label': `select job ${job.name}` }}
                          />
                          <Typography variant='body1'>
                            {job.name} ({job.count})
                          </Typography>
                        </ListItem>
                      ))}
                    </List>
                  </div>
                  <span
                    style={{
                      color: 'var(--Hyperlink, var(--Primary-Blue-500, #1976D2))',
                      textDecoration: 'underline',
                      fontWeight: '400',
                      marginLeft: '60px',
                    }}>
                    More...
                  </span>
                </div>

                <div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginLeft: '1.25rem',
                      marginRight: '1.25rem',
                      paddingTop: '20px',
                    }}>
                    <span
                      style={{ color: 'var(--Primary-Dark-grey-500, #333)', fontWeight: '600' }}>
                      Technology
                    </span>
                    <span
                      style={{
                        fontFamily: 'Roboto',

                        textDecoration: 'underline',
                      }}>
                      Clear All
                    </span>
                  </div>
                  <hr
                    style={{
                      color: '#C1C0C0',
                      marginLeft: '1.25rem',
                      marginRight: '1.25rem',
                    }}></hr>
                  <div>
                    <List>
                      {byTechnologyData.map((tech) => (
                        <ListItem
                          key={tech.id}
                          style={{ display: 'flex', marginBottom: '0.62rem' }}>
                          <Checkbox
                            checked={selectedTech.includes(tech.id)}
                            onChange={() => handleSelectTech(tech.id)}
                            inputProps={{ 'aria-label': `select location ${tech.name}` }}
                          />
                          <Typography variant='body1'>
                            {tech.name} ({tech.count})
                          </Typography>
                        </ListItem>
                      ))}
                    </List>
                    <span
                      style={{
                        color: 'var(--Hyperlink, var(--Primary-Blue-500, #1976D2))',
                        textDecoration: 'underline',
                        fontWeight: '400',
                        marginLeft: '60px',
                      }}>
                      More...
                    </span>
                  </div>
                </div>
                <div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginLeft: '1.25rem',
                      marginRight: '1.25rem',
                      paddingTop: '20px',
                    }}>
                    <span
                      style={{ color: 'var(--Primary-Dark-grey-500, #333)', fontWeight: '600' }}>
                      Location
                    </span>
                    <span
                      style={{
                        fontFamily: 'Roboto',

                        textDecoration: 'underline',
                      }}>
                      Clear All
                    </span>
                  </div>
                  <hr
                    style={{
                      color: '#C1C0C0',
                      marginLeft: '1.25rem',
                      marginRight: '1.25rem',
                    }}></hr>
                  <div>
                    <List>
                      {byLocationData.map((location) => (
                        <ListItem key={location.id} style={{ display: 'flex' }}>
                          <Checkbox
                            checked={selectedLocation.includes(location.id)}
                            onChange={() => handleSelectLocation(location.id)}
                            inputProps={{ 'aria-label': `select location ${location.name}` }}
                          />
                          <Typography variant='body1'>
                            {location.name} ({location.count})
                          </Typography>
                        </ListItem>
                      ))}
                    </List>
                    <span className='more'>More...</span>
                  </div>
                </div>

                <div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginLeft: '1.25rem',
                      marginRight: '1.25rem',
                      paddingTop: '20px',
                    }}>
                    <span
                      style={{ color: 'var(--Primary-Dark-grey-500, #333)', fontWeight: '600' }}>
                      Status
                    </span>
                    <span
                      style={{
                        fontFamily: 'Roboto',

                        textDecoration: 'underline',
                      }}>
                      Clear All
                    </span>
                  </div>
                  <hr
                    style={{
                      color: '#C1C0C0',
                      marginLeft: '1.25rem',
                      marginRight: '1.25rem',
                    }}></hr>
                  <div>
                    <List>
                      {byLocationData.map((location) => (
                        <ListItem key={location.id} style={{ display: 'flex' }}>
                          <Checkbox
                            checked={selectedLocation.includes(location.id)}
                            onChange={() => handleSelectLocation(location.id)}
                            inputProps={{ 'aria-label': `select location ${location.name}` }}
                          />
                          <Typography variant='body1'>
                            {location.name} ({location.count})
                          </Typography>
                        </ListItem>
                      ))}
                    </List>
                    <span className='more'>More...</span>
                  </div>
                </div>
              </Paper>
            </div>
          )}
          <Paper
            style={{
              width: filterView ? '76%' : '90%',
              height: '100%',
              flexGrow: 1,
              padding: '0.8rem 0rem 0rem 0.8rem',
            }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div
                style={{
                  height: '40px',
                  width: '100%',
                  marginTop: '0.5rem',
                }}>
                <TextField
                  onChange={(e) => {
                    handleSearch(e.target.value);
                  }}
                  placeholder='Search'
                  variant='outlined'
                  sx={{
                    width: 'calc(22.1875rem + 2px)',
                    height: '40px',
                    borderRadius: '0.625rem',
                    border: '1px solid #C1C0C0',
                    background: 'var(--Secondary-colors-White, #FFF)',
                    flexShrink: 0,
                    padding: 0,
                    '& .MuiOutlinedInput-root': {
                      padding: '0 0.5rem',
                      height: '100%',
                      boxSizing: 'border-box',
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <IconButton>
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              {/* <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginRight: '1rem',
                }}>
                <div
                  style={{
                    borderRadius: '0.125rem',
                    display: 'flex',
                    width: ' var(--Font-size-h3, 2.0625rem)',
                    height: '1.875rem',
                    background: !tabIconChange ? 'var(--Primary-Blue-500, #1976D2)' : '',
                    marginRight: '0.5rem',
                    justifyContent: 'center',
                  }}
                  onClick={() => {
                    setTabIconChange(false);
                    setTabListIconChange(true);
                  }}>
                  <IconButton>
                    <WidgetsIcon style={{ color: !tabIconChange ? 'white' : 'black' }} />
                  </IconButton>
                </div>
                <div
                  style={{
                    justifyContent: 'center',
                    display: 'flex',
                    borderRadius: '0.125rem',
                    width: ' var(--Font-size-h3, 2.0625rem)',
                    height: '1.875rem',
                    background: !tabListIconChange ? 'var(--Primary-Blue-500, #1976D2)' : '',
                  }}
                  onClick={() => {
                    setTabListIconChange(false);
                    setTabIconChange(true);
                  }}>
                  <IconButton>
                    <FormatListIcon style={{ color: !tabListIconChange ? 'white' : 'black' }} />
                  </IconButton>
                </div>
              </div> */}
            </div>
            <TableContainer>
              <Table style={{ height: '100%', minWidth: filterView ? '80%' : '100%' }}>
                <TableHead style={{ color: 'var(--Primary-Dark-grey-500, #333)' }}>
                  <TableRow>
                    {headers
                      .filter((column) => selectedColumns.includes(column.id))
                      .map((column) => (
                        <TableCell
                          key={column.id}
                          sx={{ fontSize: 'medium', padding: '12px 16px' }}>
                          {column.label}
                        </TableCell>
                      ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {candidateRows.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={headers.length + 1} sx={{ textAlign: 'center' }}>
                        No Records To Display
                      </TableCell>
                    </TableRow>
                  ) : (
                    candidateRows.slice(0, rowsToDisplay).map((candidate, index) => (
                      <TableRow key={index}>
                        {selectedColumns.map((columnId) => {
                          switch (columnId) {
                            case 1:
                              return (
                                <TableCell
                                  sx={{ minWidth: '7rem' }}
                                  key={columnId}
                                  className='cell'>
                                  <p style={{ display: 'flex', flexDirection: 'column' }}>
                                    <span className='feildHeader'>{`CAN${candidate.id}`}</span>
                                    <Link
                                      sx={{ cursor: 'pointer' }}
                                      onClick={() => {
                                        navigate([
                                          {
                                            label: candidate.name,
                                            link: candidate.sjdCandidateInfo?.[0]?.sjdId
                                              ? `/candidate/${btoa(
                                                  candidate.sjdCandidateInfo?.[0]?.sjdId,
                                                )}/${btoa(candidate.id)}`
                                              : `/candidate/${btoa(candidate.id)}`,
                                          },
                                        ]);
                                      }}>
                                      {candidate.name}
                                    </Link>
                                  </p>
                                </TableCell>
                              );
                            case 2:
                              return (
                                <TableCell
                                  className='cell'
                                  key={columnId}
                                  sx={{ minWidth: '7rem' }}>
                                  {candidate.sjdCandidateInfo?.[0]?.sjdId != null ? (
                                    <p style={{ display: 'flex', flexDirection: 'column' }}>
                                      <span className='feildHeader'>
                                        {candidate.sjdCandidateInfo?.[0]?.sjdId != null
                                          ? `JD${candidate.sjdCandidateInfo?.[0]?.sjdId}`
                                          : ''}
                                      </span>
                                      <Link
                                        sx={{ cursor: 'pointer' }}
                                        onClick={() => {
                                          navigate([
                                            {
                                              label: candidate.sjdCandidateInfo?.[0]?.sjdName,
                                              link: `/job-definition-details/${btoa(
                                                candidate.sjdCandidateInfo[0]?.sjdId,
                                              )}/${btoa('false')}`,
                                            },
                                          ]);
                                        }}>
                                        {candidate.sjdCandidateInfo?.[0]?.sjdName}
                                      </Link>
                                    </p>
                                  ) : (
                                    <h3 style={{ marginLeft: '2rem' }}>-</h3>
                                  )}
                                </TableCell>
                              );
                            case 3:
                              return (
                                <TableCell
                                  className='cell'
                                  key={columnId}
                                  sx={{ minWidth: '7rem' }}>
                                  <span className='class-icon'>
                                    <Star />
                                    <span className='table-text'>
                                      PR{' '}
                                      {candidate.sjdCandidateInfo?.[0]?.profilerRating
                                        ? candidate.sjdCandidateInfo?.[0]?.profilerRating
                                        : 0}
                                    </span>
                                  </span>
                                  <span className='class-icon'>
                                    <Star />
                                    <span className='table-text'>
                                      SS{' '}
                                      {candidate.sjdCandidateInfo?.[0]?.suitabilityScore
                                        ? candidate.sjdCandidateInfo?.[0]?.suitabilityScore
                                        : 0}
                                    </span>
                                  </span>
                                  <span className='class-icon'>
                                    <Trending />
                                    <span className='table-text'>
                                      CG{' '}
                                      {candidate.careerGrowthScore
                                        ? candidate.careerGrowthScore
                                        : 0}
                                    </span>
                                  </span>
                                </TableCell>
                              );
                            case 4:
                              return (
                                <TableCell
                                  className='cell'
                                  key={columnId}
                                  sx={{ minWidth: '7rem' }}>
                                  <span className='class-icon'>
                                    <Location />
                                    <span className='table-text'>{candidate.location}</span>
                                  </span>
                                  <div className='class-icon'>
                                    <CallIcon />
                                    <a
                                      href={`tel:${candidate.phoneNumber}`}
                                      style={{ cursor: 'pointer', color: '#1976d2' }}>
                                      {candidate.phoneNumber}
                                    </a>
                                  </div>
                                </TableCell>
                              );
                            case 5:
                              return (
                                <TableCell
                                  className='cell'
                                  key={columnId}
                                  sx={{ minWidth: '10rem' }}>
                                  <span className='class-icon'>
                                    <Explicit />
                                    <span className='table-text'>
                                      {candidate.totalExperience ? candidate.totalExperience : 0}
                                    </span>
                                  </span>
                                  <span className='class-icon'>
                                    <Accountbalance />
                                    <span className='table-text'>
                                      {candidate.lastEmployer ? candidate.lastEmployer : '-'}
                                    </span>
                                  </span>
                                  <span className='class-icon'>
                                    <CalenderIcon />
                                    <span className='table-text'>{candidate.noticePeriod}</span>
                                  </span>
                                </TableCell>
                              );
                            case 6:
                              return (
                                <TableCell
                                  className='cell'
                                  key={columnId}
                                  sx={{ minWidth: '10rem' }}>
                                  <span className='class-icon'>
                                    <LightBulb />

                                    <span className='table-text'>
                                      {getSkills(candidate.skills)}
                                    </span>
                                  </span>
                                  <span className='class-icon'>
                                    <AttachMoney />
                                    {candidate.currentCtc ? (
                                      <span className='table-text'>
                                        {candidate.currentCtc} (Current)
                                      </span>
                                    ) : (
                                      '-'
                                    )}
                                  </span>
                                  <span className='class-icon'>
                                    <Monetization />
                                    {candidate.expectedCtc ? (
                                      <span className='table-text'>
                                        {candidate.expectedCtc} (Expected)
                                      </span>
                                    ) : (
                                      '-'
                                    )}
                                  </span>
                                </TableCell>
                              );
                            case 7:
                              return (
                                <TableCell key={columnId} sx={{ minWidth: '7rem' }}>
                                  {dayjs(candidate.createdOn).format('DD/MM/YYYY hh:mm A')}
                                </TableCell>
                              );
                            case 8:
                              return (
                                <TableCell key={columnId} sx={{ minWidth: '7rem' }}>
                                  {dayjs(candidate.modifiedOn).format('DD/MM/YYYY hh:mm A')}
                                </TableCell>
                              );
                            case 9:
                              return (
                                <TableCell key={columnId} sx={{ minWidth: '7rem' }}>
                                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    {handleCreatedByUsers(candidate.createdBy)?.profilePicture !==
                                      null ||
                                    handleCreatedByUsers(candidate.createdBy)?.profilePicture >
                                      0 ? (
                                      <Avatar
                                        sx={{
                                          bgcolor: lightBlue[700],
                                          height: 30,
                                          width: 30,
                                          cursor: 'pointer',
                                        }}
                                        onClick={(e) => {
                                          setContact(handleCreatedByUsers(candidate.createdBy));
                                          setContactAnchor(e.currentTarget);
                                        }}
                                        src={
                                          handleCreatedByUsers(candidate.createdBy).profilePicture
                                        }></Avatar>
                                    ) : (
                                      <Avatar
                                        sx={{
                                          bgcolor: lightBlue[700],
                                          height: 30,
                                          width: 30,
                                          cursor: 'pointer',
                                        }}
                                        onClick={(e) => {
                                          setContact(handleCreatedByUsers(candidate.createdBy));
                                          setContactAnchor(e.currentTarget);
                                        }}>
                                        {getInitials(handleCreatedByUsers(candidate.createdBy))}
                                      </Avatar>
                                    )}

                                    {/* {handleCreatedByUsers(candidate.createdBy)?.profilePicture !==
                                      null ||
                                    handleCreatedByUsers(candidate.createdBy)?.profilePicture >
                                      0 ? (
                                      <img
                                        className='user-icon'
                                        src={
                                          handleCreatedByUsers(candidate.createdBy)?.profilePicture
                                        }
                                      />
                                    ) : (
                                      <div
                                        className='user-icon'
                                        style={{
                                          backgroundColor: 'rgb(25, 118, 210)',
                                          color: 'white',
                                        }}>
                                        {getInitials(handleCreatedByUsers(candidate.createdBy))}
                                      </div>
                                    )} */}
                                  </div>
                                </TableCell>
                              );
                            case 10:
                              return (
                                <TableCell
                                  key={columnId}
                                  sx={{
                                    minWidth: '7rem',
                                  }}>
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                      alignItems: 'flex-start',
                                    }}>
                                    <Chip
                                      label={getNameFromId(
                                        candidate.sjdCandidateInfo?.[0]?.candidateStatusId,
                                        LookupTypes.TAD_CANDIDATE_STATUS,
                                      )}
                                      style={{
                                        borderRadius: '0.3125rem',
                                        backgroundColor: 'var(--Primary-Blue-100, #B8D5F1)',
                                        color: '#333333',
                                        fontWeight: '400',
                                        textTransform: 'uppercase',
                                        fontSize: '0.875rem',
                                        marginBottom: '0.5rem',
                                      }}
                                    />
                                    {candidate.sjdCandidateInfo?.[0]?.candidateSubStatus && (
                                      <Chip
                                        style={{
                                          borderRadius: '0.3125rem',
                                          color: '#333333',
                                          fontWeight: '400',
                                          backgroundColor:
                                            candidate.sjdCandidateInfo?.[0]?.candidateSubStatus ===
                                            'Rejected'
                                              ? 'var(--Status-Red-100, #F4C1CB)'
                                              : candidate.sjdCandidateInfo?.[0]
                                                  ?.candidateSubStatus === 'On Hold'
                                              ? 'var(--Status-Yellow-100, #FEE6B8)'
                                              : '',
                                        }}
                                        label={candidate.sjdCandidateInfo?.[0]?.candidateSubStatus}
                                      />
                                    )}
                                  </div>
                                </TableCell>
                              );
                            case 11:
                              return (
                                <TableCell key={columnId} sx={{ minWidth: '7rem' }}>
                                  <div style={{ display: 'flex', alignItems: 'center' }}>
                                    {/* <div style={{ display: 'flex', flexDirection: 'column' }}>
                                      <Chip
                                        label='Reject'
                                        style={{
                                          borderRadius: '0.3125rem',
                                          backgroundColor: '#FFFFFF',
                                          color: '#000000',
                                          border: '1px solid #000000',
                                          padding: '10px 15px',
                                        }}
                                      />
                                      <Chip
                                        label='Move to Next Stage'
                                        style={{
                                          borderRadius: '0.3125rem',
                                          backgroundColor: '#FFFFFF',
                                          color: '#000000',
                                          border: '1px solid #000000',
                                          padding: '10px 15px',
                                          marginTop: '20px',
                                        }}
                                      />
                                    </div> */}
                                    <IconButton
                                      // onClick={showCandidatesActionsList}
                                      style={{ marginLeft: '20px' }}>
                                      <MoreVertIcon />
                                    </IconButton>
                                    <Menu
                                      anchorEl={candidateAnchorEl}
                                      open={Boolean(candidateAnchorEl)}
                                      onClose={closeCandidatesActionsMenu}>
                                      {CandidateActionsList.map((action, index) => (
                                        <MenuItem key={index} onClick={closeCandidatesActionsMenu}>
                                          <ListItemIcon>
                                            {/* <action.icon
                                              style={{ width: '20px', height: '20px' }}
                                            /> */}
                                          </ListItemIcon>
                                          <ListItemText primary={action.name} />
                                        </MenuItem>
                                      ))}
                                    </Menu>
                                  </div>
                                </TableCell>
                              );
                            default:
                              return null;
                          }
                        })}
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginTop: '1%',
          }}>
          <div style={{ textAlign: 'end', width: '50%' }}>
            {rowsToDisplay < candidateRows.length && rowsPerPage !== -1 && (
              <span
                onClick={handleLoadMore}
                style={{ color: '#1976D2', cursor: 'pointer', textDecoration: 'underline' }}>
                Load more...
              </span>
            )}
          </div>
          <div style={{ width: '45%', display: 'flex', alignItems: 'center' }}>
            <label htmlFor='rows-per-page' style={{ paddingLeft: '88%' }}>
              Rows
            </label>
            <Select
              sx={{
                alignItems: 'center',
                '& .MuiSelect-icon': {
                  top: 'calc(50% - 0.3em)',
                },
              }}
              value={rowsPerPage}
              onChange={handleChangeRowsPerPage}
              IconComponent={DropDownArrow}
              style={{ marginLeft: '10px', height: '1.5rem' }}>
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={-1}>All</MenuItem>
            </Select>
          </div>
        </div>
        <TadScreenLoader loading={loading} />
        <ContactCard
          contact={contact}
          anchorEl={contactAnchor}
          onClose={() => {
            setContactAnchor(null);
            setContact(null);
          }}
        />
      </div>
    </div>
  );
};

export default CandidateTable;
