import { Tabs, Tab } from '@mui/material';
import { useState } from 'react';
import { isUserRoleIn } from '../../../common/commonUtil';
import { TADMODULE } from '../../../constants/common';
import { isTadPermissions } from '../../common/commonUtil';
import { ROLES } from '../../constants/common';

interface Props {
  selectedTab: (selecteTab: string) => void;
}

const TadLmsHeader = ({ selectedTab }: Props) => {
  const [selectedType, setSelectedType] = useState(() => {
    const hasDashboard = isTadPermissions(TADMODULE.LMS);
    const hasLmsReport = isTadPermissions(TADMODULE.LMS);
    const hasCourseCatalogue = isTadPermissions(TADMODULE.LMS);
    const hasAdminRole = isUserRoleIn([ROLES.ADMIN]);
    if (!isUserRoleIn([ROLES.MANAGER])) {
      return hasAdminRole && !hasLmsReport
        ? hasDashboard
          ? 'Dashboard'
          : hasCourseCatalogue
          ? 'Course Catalogue'
          : null
        : 'Lms Report';
    } else {
      return isUserRoleIn([ROLES.MANAGER]) && !hasDashboard
        ? hasCourseCatalogue
          ? 'Course Catalogue'
          : hasLmsReport
          ? 'Lms Report'
          : null
        : 'Dashboard';
    }
  });

  return (
    <div style={{ height: '30px' }}>
      <Tabs
        value={selectedType}
        onChange={(_e, v) => {
          setSelectedType(v);
          selectedTab(v);
        }}>
        {isTadPermissions(TADMODULE.LMS) && (
          <Tab className='q-tab' label={'Dashboard'} value={'Dashboard'} />
        )}
        {isTadPermissions(TADMODULE.LMS) && (
          <Tab className='q-tab' label={'Course Catalogue'} value={'Course Catalogue'} />
        )}
        {isTadPermissions(TADMODULE.LMS) && (
          <Tab className='q-tab' label={'LMS Report'} value={'Lms Report'} />
        )}
      </Tabs>
    </div>
  );
};

export default TadLmsHeader;
