import { useState } from 'react';
import { isUserRoleIn } from '../../../common/commonUtil';
import { MODULE, ROLES, SubModuleTypes } from '../../../constants/common';
import PageHeader from '../../../components/common/page-header/page-header';
import NewTadManagerDashboard from './NewTadManagerDashboard';
import { isTadPermissions } from '../../common/commonUtil';
import TadLmsHeader from './TadLmsHeader';
import DashboardOverlay from '../dashboard/dashboard-overview';
const NewTadLmsCourseDashboard = () => {
  const [selectedTab, setSelectedTab] = useState(() => {
    const hasDashboard = isTadPermissions(MODULE.LMS, SubModuleTypes.NEWTADLMS_DASHBOARD);
    const hasLmsReport = isTadPermissions(MODULE.LMS);
    const hasCourseCatalogue = isTadPermissions(MODULE.LMS);
    const hasAdminRole = isUserRoleIn([ROLES.ADMIN]);
    if (!isUserRoleIn([ROLES.MANAGER])) {
      return hasAdminRole && !hasLmsReport
        ? hasDashboard
          ? 'Dashboard'
          : hasCourseCatalogue
          ? 'Course Catalogue'
          : null
        : 'Lms Report';
    } else {
      return isUserRoleIn([ROLES.MANAGER]) && !hasDashboard
        ? hasCourseCatalogue
          ? 'Course Catalogue'
          : hasLmsReport
          ? 'Lms Report'
          : null
        : 'Dashboard';
    }
  });

  return (
    <>
      <PageHeader title='LMS' />
      <DashboardOverlay open={true} header={'lms'} />
      {<TadLmsHeader selectedTab={setSelectedTab} />}
      {<NewTadManagerDashboard selectedType={selectedTab} />}
    </>
  );
};
export default NewTadLmsCourseDashboard;
