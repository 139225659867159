import React, { useEffect, useState } from 'react';
import { Gauge, gaugeClasses } from '@mui/x-charts/Gauge';
import {
  AlertColor,
  Button,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import { ReactComponent as CancelIcon } from '../../assets/dashboard-assets/cancel.svg';
import { ReactComponent as MoreIcon } from '../../assets/dashboard-assets/more_vert.svg';
import { ReactComponent as InfoIcon } from '../../assets/dashboard-assets/info.svg';
import { ReactComponent as CheckCircleIcon } from '../../assets/dashboard-assets/check_circle.svg';
import { getNameFromId, isTadPermissions } from '../../common/commonUtil';
import { LookupTypes } from '../../../common/lookupTypes';
import { validateWithAIAnswers } from '../../service/tadCandidateService';
import { getIdFromName } from '../../../common/commonUtil';
import { tadSaveCandidateAnswers } from '../../service/tadQuestionnaireService';
import Notify from '../../common/notify';
import { TADMODULE } from '../../../constants/common';
import TadScreenLoader from '../common/tad-screen-loader';

interface Props {
  candidate: any;
}

const TadCandidateQuestionnaire = ({ candidate }: Props) => {
  const [selectedAnswers, setSelectedAnswers] = useState<
    Record<
      number,
      {
        index: number | undefined;
        options: any[] | undefined;
        selectedOption: string | undefined;
        expectedAnswer: string | undefined;
        answer: string | undefined;
        question: string | undefined;
        questionType: number | undefined;
      }
    >
  >([]);

  const [loading, setLoading] = useState(false);
  const [questionnaire, setQuestionnaire] = useState([] as any[]);
  const [questions, setQuestions] = useState([] as any[]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [validatedAIAnswers, setValidatedAIAnswers] = useState([] as any[]);
  const [textscoring, setTextScoring] = useState([] as any[]);
  const [generalTypescoring, setGeneralTypeScoring] = useState([] as any[]);
  const [errors, setErrors] = useState<Record<number, string>>([]);
  const [afterSubmitErrors, setAfterSubmitErrors] = useState<Record<number, string>>([]);
  const [mcqValidationDisplay, setMcqValidationDisplay] = useState([] as any[]);
  const validationData: {
    questionId: number;
    correctOption: string | null;
    wrongOption: string | null;
  }[] = [];
  const [showToast, setShowToast] = useState(false);
  const [toastMsg, setToastMsg] = useState({
    severity: 'success',
    msg: '',
  } as {
    severity: AlertColor;
    msg: string;
  });

  useEffect(() => {
    const questionnaire = candidate?.questionnaire;
    setQuestionnaire(questionnaire);
    setQuestions(questionnaire?.map((questionnaire: any) => questionnaire.questionId));
    questionnaire?.map((questionnaire: any) => {
      if (
        questionnaire.answer != null &&
        questionnaire.questionId?.questionType ===
          getIdFromName('Technical', LookupTypes.TAD_QUESTIONS_TYPE)
      ) {
        const givenAnswer = questionnaire.answer;
        const expectedAnswer = questionnaire.questionId?.expectedAnswer?.split(':')[0].trim();
        if (givenAnswer === expectedAnswer) {
          validationData.push({
            questionId: questionnaire.questionId?.id,
            correctOption: givenAnswer,
            wrongOption: null,
          });
        } else {
          validationData.push({
            questionId: questionnaire.questionId?.id,
            correctOption: expectedAnswer,
            wrongOption: givenAnswer,
          });
        }
      }
    });
    setMcqValidationDisplay(validationData);
    setSelectedTab(
      questionnaire?.map((questionnaire: any) => questionnaire.questionId)[0]?.questionType,
    );
  }, [candidate]);

  const nonMcqCount = questionnaire?.filter(
    (questionnaire: any) =>
      questionnaire.questionId?.questionType !==
        getIdFromName('Technical', LookupTypes.TAD_QUESTIONS_TYPE) &&
      questionnaire.isSatisfied === true,
  )?.length;
  const mcqCount = validationData.filter((data: any) => data.wrongOption === null).length;
  const score = Math.round((5 * (nonMcqCount + mcqCount)) / questions?.length);

  const tabStyles = () => ({
    padding: '0px !important',
    paddingRight: '10px !important',
    paddingLeft: '10px !important',
    height: '5px !important',
    '&.Mui-selected': {
      fontWeight: '600 !important',
    },
    fontSize: '1rem',
    fontStyle: 'normal',
    fontWeight: '400 !important',
    lineHeight: '1.5rem',
    fontFamily: 'Roboto !important',
    color: 'var(--Primary-Dark-grey-500, #333) !important',
    textTransform: 'none !important',
  });

  const getOptionStyle = (display: any, option: any): React.CSSProperties => {
    let style = optionStyle();

    if (option?.label === display?.correctOption || option?.label === display?.wrongOption) {
      if (display?.wrongOption != null) {
        if (display?.wrongOption === option.label) {
          style = { ...style, background: '#F4C1CB' };
        } else if (display?.correctOption === option.label) {
          style = { ...style, background: '#C7E8C5' };
        }
      } else if (display?.correctOption === option.label) {
        style = { ...style, background: '#C7E8C5' };
      }
    } else {
      style = { ...style, background: '#F1F4F8' };
    }

    return style;
  };

  const optionStyle = (): React.CSSProperties => ({
    height: '3.3125rem',
    flexShrink: 0,
    borderRadius: '0.3125rem',
    background: 'var(--Primary-Blue-100, #B8D5F1)',
    display: 'flex',
    alignItems: 'center',
    padding: '0 0.5rem',
    boxSizing: 'border-box',
    justifyContent: 'space-between',
  });
  const optionStyleBeforeSubmission = (isCorrect: any): React.CSSProperties => ({
    height: '3.3125rem',
    flexShrink: 0,
    borderRadius: '0.3125rem',

    background: isCorrect
      ? 'var(--Primary-Blue-100, #B8D5F1)'
      : 'var(--Secondary-colors-Grey-3, #F1F4F8)',
    display: 'flex',
    alignItems: 'center',
    padding: '0 0.5rem',
    boxSizing: 'border-box',
    justifyContent: 'space-between',
  });

  const handleOptionClick = (
    questionNumber: number,
    index: number,
    options: any[],
    selectedOption: any,
    expectedAnswer: any,
    answer: any,
    question: any,
    questionType: any,
  ) => {
    setSelectedAnswers((prev) => ({
      ...prev,
      [questionNumber]: {
        index,
        options,
        selectedOption,
        expectedAnswer,
        answer,
        question,
        questionType,
      },
    }));
  };

  const convertSelectedMcqs = (
    selectedMcqs: Record<
      number,
      {
        index: number | undefined;
        options: any[] | undefined;
        selectedOption: any;
        expectedAnswer: string | undefined;
        answer: string | undefined;
        question: string | undefined;
        questionType: number | undefined;
      }
    >,
  ) => {
    const validationData: {
      questionId: number;
      correctOption: string | null;
      wrongOption: string | null;
    }[] = [];
    const filteredMcqs = Object.entries(selectedMcqs)
      .filter(
        ([, value]) =>
          value.questionType === getIdFromName('Technical', LookupTypes.TAD_QUESTIONS_TYPE),
      )
      .map(([questionId, value]) => ({
        questionId: Number(questionId),
        options: value.options,
        option: value.selectedOption,
      }));

    filteredMcqs.forEach((mcq: any) => {
      const correctOption = mcq.options?.find((option: any) => option.isCorrect);
      if (correctOption?.label === mcq.option.label) {
        validationData.push({
          questionId: mcq.questionId,
          correctOption: correctOption.label,
          wrongOption: null,
        });
      } else {
        validationData.push({
          questionId: mcq.questionId,
          correctOption: correctOption?.label,
          wrongOption: mcq.option.label,
        });
      }
    });
    setMcqValidationDisplay(validationData);
  };

  const convertSelectedText = (
    selectedTextAnswers: Record<
      number,
      {
        index: number | undefined;
        expectedAnswer: string | undefined;
        answer: string | undefined;
        question: string | undefined;
        questionType: number | undefined;
      }
    >,
  ) => {
    return Object.entries(selectedTextAnswers)
      .filter(
        ([, value]) =>
          value.questionType ===
            getIdFromName('Technical(contextual)', LookupTypes.TAD_QUESTIONS_TYPE) ||
          value.questionType === getIdFromName('Behavioral', LookupTypes.TAD_QUESTIONS_TYPE), // Filter by questionType
      )
      .map(([questionId, value]) => ({
        questionId: Number(questionId),
        question: value.question,
        userAnswer: value.answer,
        expectedAnswer: value.expectedAnswer,
      }));
  };

  const handleTextScore = (questionId: any, answer: any, aiAnswer: any, questionAnswer: any) => {
    setTextScoring((prev) => {
      const existingIndex = prev.findIndex((item) => item.questionId === questionId);

      if (existingIndex !== -1) {
        const updated = [...prev];
        updated[existingIndex] = { ...updated[existingIndex], answer, aiAnswer, questionAnswer };
        return updated;
      } else {
        return [...prev, { questionId, answer, aiAnswer, questionAnswer }];
      }
    });
  };

  const handleGeneralScore = (questionId: any, answer: any, aiAnswer: any, questionAnswer: any) => {
    setGeneralTypeScoring((prev) => {
      const existingIndex = prev.findIndex((item) => item.questionId === questionId);

      if (existingIndex !== -1) {
        const updated = [...prev];
        updated[existingIndex] = { ...updated[existingIndex], answer, aiAnswer, questionAnswer };
        return updated;
      } else {
        return [...prev, { questionId, answer, aiAnswer, questionAnswer }];
      }
    });
  };

  const submitAnswers = () => {
    const newErrors: Record<number, string> = [];
    const requiredQuestions = questions.filter((q) => q.required);
    requiredQuestions.forEach((question) => {
      const selectedAnswer = selectedAnswers[question.id];
      if (question.questionType === getIdFromName('Technical', LookupTypes.TAD_QUESTIONS_TYPE)) {
        if (!selectedAnswer) {
          newErrors[question.id] = 'Required';
        } else {
          if (newErrors[question.id]) {
            delete newErrors[question.id];
          }
        }
      } else {
        if (!selectedAnswer || !selectedAnswer.answer?.trim()) {
          newErrors[question.id] = 'Required';
        } else {
          if (newErrors[question.id]) {
            delete newErrors[question.id];
          }
        }
      }
    });
    setErrors(newErrors);
    if (Object.keys(newErrors).length == 0) {
      setLoading(true);
      validateWithAIAnswers(convertSelectedText(selectedAnswers)).then((res) => {
        setValidatedAIAnswers(res.data);
        setLoading(false);
      });
      convertSelectedMcqs(selectedAnswers);
    }
  };

  const saveAnswers = () => {
    const newErrors: Record<number, string> = [];
    const requiredQuestions = questions.filter(
      (q) =>
        q.required &&
        q.questionType != getIdFromName('Technical', LookupTypes.TAD_QUESTIONS_TYPE) &&
        q.questionType != getIdFromName('General', LookupTypes.TAD_QUESTIONS_TYPE),
    );

    const GeneralQuestions = questions.filter(
      (q) =>
        q.required && q.questionType === getIdFromName('General', LookupTypes.TAD_QUESTIONS_TYPE),
    );

    requiredQuestions.forEach((question) => {
      const selectedAnswer = textscoring.find((score) => score.questionId === question.id);
      if (!selectedAnswer) {
        newErrors[question.id] = 'Required';
      } else {
        if (newErrors[question.id]) {
          delete newErrors[question.id];
        }
      }
    });

    GeneralQuestions.forEach((question) => {
      const selectedAnswer = generalTypescoring.find((score) => score.questionId === question.id);
      if (!selectedAnswer) {
        newErrors[question.id] = 'Required';
      } else {
        if (newErrors[question.id]) {
          delete newErrors[question.id];
        }
      }
    });

    setAfterSubmitErrors(newErrors);
    if (Object.keys(newErrors).length == 0) {
      const oldQuestionnaire = candidate?.questionnaire;

      const questionnaire1 = oldQuestionnaire
        ?.map((questionnaire: any) => {
          const matchingScore = generalTypescoring.find(
            (score: any) => score.questionId === questionnaire.questionId.id,
          );
          if (matchingScore) {
            return {
              id: questionnaire.id,
              sjdId: questionnaire.sjdId,
              candidateId: questionnaire.candidateId,
              answer: matchingScore.questionAnswer,
              isSatisfied: matchingScore.answer,
              aiFeedback: null,
            };
          }
          return null;
        })
        .filter(Boolean);

      const questionnaire2 = oldQuestionnaire
        ?.map((questionnaire: any) => {
          const matchingScore = textscoring.find(
            (score: any) => score.questionId === questionnaire.questionId.id,
          );
          if (matchingScore) {
            return {
              id: questionnaire.id,
              sjdId: questionnaire.sjdId,
              candidateId: questionnaire.candidateId,
              answer: matchingScore.questionAnswer,
              isSatisfied: matchingScore.answer,
              aiFeedback: matchingScore.aiAnswer,
            };
          }
          return null;
        })
        .filter(Boolean);

      const questionnaire3 = oldQuestionnaire
        ?.map((questionnaire: any) => {
          const matchingScore = mcqValidationDisplay.find(
            (score: any) => score.questionId === questionnaire.questionId.id,
          );
          if (matchingScore) {
            return {
              id: questionnaire.id,
              sjdId: questionnaire.sjdId,
              candidateId: questionnaire.candidateId,
              answer:
                matchingScore.wrongOption === null
                  ? matchingScore.correctOption
                  : matchingScore.wrongOption,
              isSatisfied: null,
              aiFeedback: null,
            };
          }
          return null;
        })
        .filter(Boolean);
      setLoading(true);
      tadSaveCandidateAnswers([...questionnaire1, ...questionnaire2, ...questionnaire3])
        .then((resp) => {
          setLoading(false);
          setShowToast(true);
          setToastMsg({ severity: 'success', msg: `${resp.data.message}` });
          window.location.reload();
        })
        .catch(() => {});
    }
  };

  const getFeedBack = (questionId: any) => {
    return validatedAIAnswers?.find((answer) => answer?.question_id === questionId);
  };

  const questionTypes = Array.from(
    new Set(questions?.map((question: any) => question?.questionType)),
  ).map((questionType: any) => getNameFromId(questionType, LookupTypes.TAD_QUESTIONS_TYPE));

  return (
    <div>
      <Paper>
        <div className='header-container'>
          <div className='header-content-title'>
            <span>Questionnaire</span>
          </div>
          <div className='header-content-icon'>
            <IconButton
            // onClick={() => (edit == false ? setEdit(true) : setEdit(false))}
            >
              <MoreIcon />
            </IconButton>
          </div>
        </div>
        <div
          className='containerStyle'
          style={{
            padding: '1.5rem',
            paddingRight: '15px',
          }}>
          <div style={{ textAlign: 'left' }}></div>
          {score ? (
            <div
              style={{
                border: (() => {
                  if (score === 1 || score === 2) return '1px solid #DC3857';
                  if (score === 3) return '1px solid #1976D2';
                  return '1px solid #4BB543';
                })(),
                boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                borderRadius: '0.3125rem',
                display: 'flex',
                justifyContent: 'space-between',
                backgroundColor: '#FFF',
              }}>
              <div style={{ display: 'flex', gap: '0.5rem' }}>
                <div>
                  <div
                    style={{
                      display: 'flex',
                      marginLeft: '1.38rem',
                      marginTop: '1.25rem',
                      gap: '0.5rem',
                    }}>
                    {score === 1 && <CancelIcon />}
                    {score === 2 && <CancelIcon />}
                    {score === 3 && <InfoIcon />}
                    {score >= 4 && score <= 5 && <CheckCircleIcon />}
                  </div>
                </div>
                <div style={{ marginTop: '1rem' }}>
                  <div>
                    <span
                      style={{
                        color: (() => {
                          if (score === 1 || score === 2) return '#DC3857';
                          if (score === 3) return '#1976D2';
                          return '#4BB543';
                        })(),
                        fontFamily: 'Roboto',
                        fontSize: '1rem',
                        fontWeight: 600,
                        lineHeight: '1.5625rem',
                      }}>
                      {score === 1 && '1: Poor'}
                      {score === 2 && '2: Below Average'}
                      {score === 3 && '3: Average'}
                      {score === 4 && '4: Good'}
                      {score === 5 && '5: Excellent'}
                    </span>
                  </div>
                  <div>
                    <span
                      style={{
                        width: '29.5rem',
                        height: '2.9375rem',
                        fontFamily: 'Roboto',
                        fontSize: '1rem',
                        fontWeight: 400,
                        lineHeight: '1.5625rem',
                      }}>
                      {score === 1 &&
                        'Very limited understanding or proficiency in technical skills.'}
                      {score === 2 &&
                        'Some understanding but lacks key competencies; needs significant improvement.'}
                      {score === 3 &&
                        'Adequate proficiency with room for improvement; basic technical skills are present.'}
                      {score === 4 &&
                        'Strong technical skills with a solid grasp of necessary competencies.'}
                      {score === 5 &&
                        'Exceptional technical skills, showing a high level of expertise.'}
                    </span>
                  </div>
                </div>
              </div>

              <div
                style={{
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  marginTop: '1.5rem',
                  marginRight: '1.56rem',
                  marginBottom: '1.5rem',
                }}>
                <Gauge
                  value={(score / 5) * 100}
                  startAngle={-180}
                  endAngle={180}
                  sx={{
                    width: '4.4375rem',
                    height: '4.4375rem',
                    flexShrink: 0,
                    [`& .${gaugeClasses.valueText}`]: {
                      fontSize: '1rem',
                      color: (() => {
                        if (score <= 3) return '#DC3857';
                        if (score <= 6) return '#1976D2';
                        return '#4BB543';
                      })(),
                      fontFamily: 'Roboto',
                      fontWeight: 600,
                      lineHeight: '1.5625rem',
                      fill: (() => {
                        if (score < 3) return 'rgb(220, 56, 87)';
                        if (score == 3) return 'rgb(220, 56, 87)';
                        if (score == 4 || score == 5) return '#1976D2';
                      })(),
                    },
                    ['& .css-b9rdri-MuiGauge-referenceArc']: {
                      fill: (() => {
                        if (score < 3) return 'rgb(220, 56, 87)'; // red color for low score
                        if (score === 3) return '#1976D2'; // red for exactly 3
                        if (score === 4 || score === 5) return '#4BB543'; // blue for higher scores
                      })(),
                    },
                  }}
                  text={() =>
                    `${Math.round((5 * (nonMcqCount + mcqCount)) / questions?.length)}/${5}`
                  }
                />
              </div>
            </div>
          ) : (
            <span></span>
          )}

          <Tabs
            className='questionnaire-tabs'
            value={selectedTab}
            onChange={(_e, v) => {
              setSelectedTab(v);
            }}>
            {questionTypes.map((header: any, index: any) => (
              <Tab
                sx={tabStyles}
                key={index}
                value={getIdFromName(header, LookupTypes.TAD_QUESTIONS_TYPE)}
                label={
                  header === 'Technical'
                    ? 'Technical (MCQ)'
                    : header === 'Technical(contextual)'
                    ? 'Technical (Contextual)'
                    : header
                }
              />
            ))}
          </Tabs>
          <div style={{ maxHeight: '800px', overflow: 'scroll' }}>
            <div style={{ marginRight: '1rem' }}>
              {questionnaire
                ?.filter((questionnaire) => questionnaire.questionId?.questionType === selectedTab)
                .map((questionnaire: any, index: any) => (
                  <div key={questionnaire.questionId?.id} style={{ marginBottom: '2rem' }}>
                    <span className='text-font'>
                      Q{index + 1}: {questionnaire.questionId?.question}{' '}
                      {questionnaire.questionId?.required === true ? (
                        <span style={{ color: 'red' }}>*</span>
                      ) : (
                        ''
                      )}
                    </span>

                    {getNameFromId(
                      questionnaire.questionId?.answerType,
                      LookupTypes.TAD_ANSWER_TYPE,
                    ) === 'Choice' ? (
                      <div>
                        <div
                          key={index}
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '0.5rem',
                            marginTop: '1rem',
                            pointerEvents: !isTadPermissions(TADMODULE.CANDIDATES, 'write')
                              ? 'none'
                              : 'auto',
                          }}>
                          {questionnaire.questionId?.options.map((option: any, index: any) => {
                            const isSelected =
                              selectedAnswers[questionnaire.questionId?.id]?.index === index;
                            const display = mcqValidationDisplay?.find(
                              (option) => option.questionId === questionnaire.questionId?.id,
                            );
                            return (
                              <>
                                <div
                                  key={index}
                                  style={
                                    display
                                      ? getOptionStyle(display, option)
                                      : optionStyleBeforeSubmission(isSelected)
                                  }
                                  onClick={() =>
                                    handleOptionClick(
                                      questionnaire.questionId?.id,
                                      index,
                                      questionnaire.questionId?.options,
                                      option,
                                      null,
                                      null,
                                      null,
                                      questionnaire.questionId?.questionType,
                                    )
                                  }>
                                  <Typography
                                    variant='body1'
                                    style={{
                                      color: '#333',
                                      fontFamily: 'Roboto',
                                      fontSize: '1rem',
                                      fontWeight: 400,
                                      lineHeight: '1.5625rem',
                                      margin: 0,
                                    }}>
                                    {option.label}. {option.text}
                                  </Typography>

                                  {display?.wrongOption != null ? (
                                    <>
                                      {display?.wrongOption === option.label && <CancelIcon />}
                                      {display?.correctOption === option.label && (
                                        <CheckCircleIcon />
                                      )}
                                    </>
                                  ) : (
                                    display?.correctOption === option.label && <CheckCircleIcon />
                                  )}
                                </div>
                              </>
                            );
                          })}
                        </div>
                        {errors[questionnaire.questionId?.id] && (
                          <span style={{ color: 'red' }}>
                            {errors[questionnaire.questionId?.id]}
                          </span>
                        )}
                      </div>
                    ) : (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '0.5rem',
                          marginTop: '1rem',
                        }}>
                        <TextField
                          style={{
                            backgroundColor:
                              questionnaire.answer === null
                                ? validatedAIAnswers.length > 0
                                  ? selectedAnswers[questionnaire.questionId?.id]
                                    ? '#F1F4F8'
                                    : ''
                                  : ''
                                : '#F1F4F8',
                          }}
                          disabled={
                            isTadPermissions(TADMODULE.CANDIDATES, 'write') &&
                            questionnaire.answer === null
                              ? validatedAIAnswers.length > 0
                                ? selectedAnswers[questionnaire.questionId?.id]
                                  ? true
                                  : false
                                : false
                              : true
                          }
                          fullWidth
                          multiline
                          rows={6}
                          size='small'
                          value={
                            questionnaire.answer === null
                              ? selectedAnswers[questionnaire.questionId?.id]?.answer
                              : questionnaire.answer
                          }
                          onChange={(e) =>
                            handleOptionClick(
                              questionnaire.questionId?.id,
                              index,
                              [],
                              null,
                              questionnaire.questionId?.expectedAnswer,
                              e.target.value,
                              questionnaire.questionId?.question,
                              questionnaire.questionId?.questionType,
                            )
                          }></TextField>
                        {errors[questionnaire.questionId?.id] && (
                          <span style={{ color: 'red' }}>
                            {errors[questionnaire.questionId?.id]}
                          </span>
                        )}
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                          }}>
                          {(getFeedBack(questionnaire.questionId?.id)?.ai_feedback ||
                            (questionnaire.answer !== null &&
                              questionnaire.questionId?.questionType !==
                                getIdFromName('General', LookupTypes.TAD_QUESTIONS_TYPE))) && (
                            <span style={{ marginBottom: '0.5rem', fontWeight: '600' }}>
                              AI Feedback
                            </span>
                          )}

                          {getFeedBack(questionnaire.questionId?.id)?.ai_feedback ||
                            (questionnaire.answer !== null && (
                              <span style={{ marginBottom: '0.5rem' }}>
                                {questionnaire.answer === null
                                  ? getFeedBack(questionnaire.questionId?.id)?.ai_feedback
                                  : questionnaire?.aiAnswer}
                              </span>
                            ))}
                          {(getFeedBack(questionnaire.questionId?.id)?.ai_feedback ||
                            questionnaire.answer !== null) && (
                            <span
                              style={{
                                marginBottom: '0.5rem',
                                fontWeight: '600',
                                marginTop: '0.5rem',
                              }}>
                              Recruiter Feedback
                            </span>
                          )}
                          {(getFeedBack(questionnaire.questionId?.id)?.ai_feedback ||
                            questionnaire.answer !== null) && (
                            <Select
                              disabled={questionnaire.answer === null ? false : true}
                              value={
                                questionnaire.answer !== null
                                  ? questionnaire.isSatisfied
                                    ? 'true'
                                    : 'false'
                                  : textscoring?.find(
                                      (score) => score.questionId === questionnaire.questionId?.id,
                                    )?.answer ?? ''
                              }
                              displayEmpty
                              style={{ color: 'gray' }}
                              onChange={(e) =>
                                handleTextScore(
                                  questionnaire.questionId?.id,
                                  e.target.value === 'true' ? true : false,
                                  getFeedBack(questionnaire.questionId?.id)?.ai_feedback,
                                  selectedAnswers[questionnaire.questionId?.id].answer,
                                )
                              }>
                              <MenuItem value='' disabled>
                                Are you satisfied by the candidate answer?
                              </MenuItem>
                              <MenuItem value={'true'}>Yes</MenuItem>
                              <MenuItem value={'false'}>No</MenuItem>
                            </Select>
                          )}
                          {validatedAIAnswers.length > 0 &&
                            questionnaire.questionId?.questionType ===
                              getIdFromName('General', LookupTypes.TAD_QUESTIONS_TYPE) && (
                              <Select
                                value={
                                  generalTypescoring?.find(
                                    (score) => score.questionId === questionnaire.questionId?.id,
                                  )?.answer ?? ''
                                }
                                displayEmpty
                                style={{ color: 'gray' }}
                                onChange={(e) =>
                                  handleGeneralScore(
                                    questionnaire.questionId?.id,
                                    e.target.value === 'true' ? true : false,
                                    getFeedBack(questionnaire.questionId?.id),
                                    selectedAnswers[questionnaire.questionId?.id].answer,
                                  )
                                }>
                                <MenuItem value='' disabled>
                                  Are you satisfied by the candidate answer?
                                </MenuItem>
                                <MenuItem value={'true'}>Yes</MenuItem>
                                <MenuItem value={'false'}>No</MenuItem>
                              </Select>
                            )}
                          {afterSubmitErrors[questionnaire.questionId?.id] && (
                            <span style={{ color: 'red' }}>
                              {afterSubmitErrors[questionnaire.questionId?.id]}
                            </span>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                ))}
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              width: 'auto',
              marginRight: '1rem',
              marginBottom: '1.5rem',
            }}>
            {questionnaire && questionnaire[0]?.answer === null && (
              <Button
                disabled={!isTadPermissions(TADMODULE.CANDIDATES, 'write')}
                style={{
                  width: '7.5rem',
                  height: 'var(--Font-size-h2, 2.5rem)',
                  flexShrink: 0,
                  borderRadius: '0.3125rem',
                  border: '1px solid #2D7DFE',
                  background: 'var(--Primary-Blue-500, #1976D2)',
                  color: 'white',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  validatedAIAnswers.length > 0 ? saveAnswers() : submitAnswers();
                }}>
                {validatedAIAnswers.length > 0 ? 'Save' : 'Submit'}
              </Button>
            )}
          </div>
        </div>
      </Paper>
      <TadScreenLoader loading={loading} />
      <Notify
        open={showToast}
        severity={toastMsg.severity}
        onClose={() => setShowToast(false)}
        message={toastMsg.msg}></Notify>
    </div>
  );
};

export default TadCandidateQuestionnaire;
