import React, { useState } from 'react';
import {
  Paper,
  Typography,
  Autocomplete,
  TextField,
  Select,
  RadioGroup,
  FormControlLabel,
  Radio,
  MenuItem,
  Button,
  IconButton,
} from '@mui/material';
import {} from '../../../models/tad-sjd-form-model';
import { FormikProps, useFormik } from 'formik';
import * as Yup from 'yup';
import globalObject from '../../../../common/global-variables';
import { LookupTypes } from '../../../../common/lookupTypes';
import { ReactComponent as MoreIcon } from '../../../assets/dashboard-assets/more_vert.svg';
import '../../source-job-definition/job-definition-details.scss';
import { getNameFromId } from '../../../../common/commonUtil';
import useNavigate from '../../../../common/useNavigate';
import {
  TadCandidateAdditionalDetails,
  TadCandidateDetailsModel,
} from '../../../models/tad-candidate-form-model';
import LocationAutoComplete from '../../../../components/common/location-autocomplete';
import CountryCodes from '../../common/country-codes';
import { DatePicker } from '@mui/x-date-pickers';
import dateFormat from 'dateformat';
import dayjs from 'dayjs';

interface AdditionalDetailsProps {
  next?: (value: TadCandidateDetailsModel) => void;
  previous?: () => void;
  mode?: 'edit-page' | 'details-page';
  values: TadCandidateAdditionalDetails;
}

interface OptionType {
  id: number;
  name: string;
}

const AdditionalDetails: React.FC<AdditionalDetailsProps> = ({
  previous = () => {},
  mode = 'edit-page',
  next,
  values,
}: AdditionalDetailsProps) => {
  const [submitted, setSubmitted] = useState(false);
  const [edit, setEdit] = useState(mode === 'edit-page');
  const [navigate] = useNavigate();
  const [selectedFullTimeFlag, setSelectedFullTimeFlag] = useState('IN');

  const requisitionSchema = Yup.object().shape({
    jobTypes: Yup.array()
      .of(Yup.object())
      .min(1, 'Employment type is required')
      .required('Employment type is required'),
    totalExperience: Yup.number()
      .required('Total Experience is required')
      .max(50, 'Total Experience cannot exceed 50 years'),
    noticePeriod: Yup.number()
      .required('Availability to start is required')
      .max(100, 'Please enter a valid notice period (100 days or less).'),
    reasonForChange: Yup.number()
      .integer('The value must be a number')
      .typeError('The value must be a number')
      .min(0, 'Value must be positive')
      .required('Reason for change is required'),
    currentLocation: Yup.string().required('Current Location is required'),
    willingToRelocate: Yup.bool(),
    pinCode: Yup.string()
      .required('Pincode is required')
      .min(6, 'Pincode must be at least 6 digits'),
    sourceChannel: Yup.number()
      .integer('The value must be a number')
      .typeError('The value must be a number')
      .min(0, 'Value must be positive')
      .required('Source is required'),
  });

  const formik = useFormik({
    initialValues: {
      currentClient: values?.currentClient,
      jobTypes: values?.jobTypes || [],
      totalExperience: values?.totalExperience,
      totalExperienceType: values?.totalExperienceType || 1,
      noticePeriod: values?.noticePeriod,
      noticePeriodType: values?.noticePeriodType || 1,
      reasonForChange: values?.reasonForChange,
      currentLocation: values?.currentLocation,
      willingToRelocate: values?.willingToRelocate,
      pinCode: values?.pinCode,
      sourceChannel: values?.sourceChannel,
      relocationBonus: values?.relocationBonus,
      joiningBonus: values?.joiningBonus,
      otherBonus: values?.otherBonus,
      relocationBonusType: values?.relocationBonusType,
      joiningBonusType: values?.joiningBonusType,
      otherBonusType: values?.otherBonusType,
      accommodationRequired: values?.accommodationRequired,
      joiningDate: values?.joiningDate,
    },
    onSubmit: (values) => {
      next && next(values);
      setSubmitted(false);
    },
    validationSchema: requisitionSchema,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const setLocationCallback = (loc?: string) => {
    formik.setFieldValue('currentLocation', loc || '');
  };

  function getError(formik: FormikProps<any>, fieldName: string) {
    if ((submitted || formik.touched[fieldName]) && formik.errors[fieldName])
      return formik.errors[fieldName]?.toString();
    return null;
  }

  return (
    <>
      <div style={{ height: '100%', width: '100%' }}>
        <Paper className='paper' sx={{ width: '100%' }}>
          {mode === 'details-page' && (
            <div className='header-container'>
              <div className='header-content-title'>
                <span>Additional Details</span>
              </div>
              <div className='header-content-icon'>
                <IconButton
                  onClick={() => {
                    edit == false ? setEdit(true) : setEdit(false);
                  }}>
                  <MoreIcon />
                </IconButton>
              </div>
            </div>
          )}
          <div style={{ padding: '2.69rem 3rem 2.19rem 3rem' }}>
            <div className='field-container'>
              {mode === 'edit-page' ? (
                <div style={{ width: '50%' }}>
                  <Typography sx={{ fontWeight: 600 }}>Current Client </Typography>
                </div>
              ) : (
                <div style={{ width: '35%' }}>
                  <Typography sx={{ fontWeight: 600 }}>Current Client: </Typography>
                </div>
              )}
              {edit ? (
                <div style={{ width: '50%' }}>
                  <Autocomplete
                    fullWidth
                    className='visaType'
                    id='combo-box-demo'
                    options={globalObject.lookupvalues[LookupTypes.TAD_CLIENT] as OptionType[]}
                    value={
                      formik.values.currentClient
                        ? globalObject.lookupvalues[LookupTypes.TAD_CLIENT].find(
                            (option: OptionType) => option.id === formik.values.currentClient,
                          )
                        : null
                    }
                    size='small'
                    sx={{
                      background: 'var(--Secondary-colors-White, #FFF)',
                      border: '1px solid var(--Secondary-colors-Grey, #868686)',
                      borderRadius: '0.3125rem',
                    }}
                    limitTags={1}
                    getOptionLabel={(option: any) => option.name}
                    filterSelectedOptions
                    onBlur={() => formik.setFieldTouched('currentClient', true, true)}
                    renderInput={(params) => (
                      <TextField
                        placeholder='Choose...'
                        {...params}
                        error={Boolean(formik.errors.currentClient && formik.touched.currentClient)}
                        label=''
                      />
                    )}
                    onChange={(_e, val) => {
                      formik.setFieldValue('currentClient', val ? val.id : undefined);
                    }}
                  />
                </div>
              ) : (
                <span>
                  {globalObject.lookupvalues[LookupTypes.TAD_CLIENT].find(
                    (option: OptionType) => option.id === formik.values.currentClient,
                  )?.name || 'N/A'}
                </span>
              )}
            </div>

            <div className='field-container'>
              {mode === 'edit-page' ? (
                <div style={{ width: '50%' }}>
                  <Typography sx={{ fontWeight: 600 }}>Employment Type *</Typography>
                  <span className='feildHeader'>Pick one or multiple options</span>
                </div>
              ) : (
                <div style={{ width: '35%' }}>
                  <Typography sx={{ fontWeight: 600 }}>Employment Type:</Typography>
                </div>
              )}
              {edit ? (
                <div style={{ width: '50%' }}>
                  <Autocomplete
                    className='inputFeilds'
                    id='combo-box-demo'
                    options={globalObject.lookupvalues[LookupTypes.TAD_JOB_TYPE]}
                    value={
                      formik.values.jobTypes && formik.values.jobTypes.length > 0
                        ? formik.values.jobTypes
                            .map((jobTypeId: any) =>
                              globalObject.lookupvalues[LookupTypes.TAD_JOB_TYPE].find(
                                (option: OptionType) => option.id === jobTypeId.id,
                              ),
                            )
                            .filter(Boolean)
                        : []
                    }
                    size='small'
                    fullWidth
                    limitTags={1}
                    sx={{
                      background: 'var(--Secondary-colors-White, #FFF)',
                      border: '1px solid var(--Secondary-colors-Grey, #868686)',
                      borderRadius: '0.3125rem',
                    }}
                    getOptionLabel={(option: any) => option.name}
                    filterSelectedOptions
                    onBlur={() => formik.setFieldTouched('jobTypes', true, true)}
                    renderInput={(params) => (
                      <TextField
                        placeholder='Choose...'
                        {...params}
                        error={Boolean(formik.errors.jobTypes && formik.touched.jobTypes)}
                        label=''
                      />
                    )}
                    onChange={(_e, val) => {
                      formik.setFieldValue(
                        'jobTypes',
                        val ? val.map((option: OptionType) => option) : [],
                      );
                    }}
                    multiple
                  />
                  <div className='mt-0 form-err-message'>{getError(formik, 'jobTypes')}</div>
                </div>
              ) : (
                <span>
                  {formik.values.jobTypes && formik.values.jobTypes.length > 0
                    ? formik.values.jobTypes
                        .map(
                          (jobTypeId: any) =>
                            globalObject.lookupvalues[LookupTypes.TAD_JOB_TYPE].find(
                              (option: any) => option.id === jobTypeId,
                            )?.name,
                        )
                        .filter(Boolean)
                        .join(', ')
                    : 'None selected'}
                </span>
              )}
            </div>

            <div style={{ marginBottom: '1.87rem' }}>
              <hr style={{ background: '#868686' }} />
            </div>

            <div className='field-container'>
              {mode === 'edit-page' ? (
                <div style={{ width: '50%' }}>
                  <Typography sx={{ fontWeight: 600 }}>Total Experience *</Typography>
                </div>
              ) : (
                <div style={{ width: '35%' }}>
                  <Typography sx={{ fontWeight: 600 }}>Total Experience: </Typography>
                </div>
              )}

              <div
                style={{
                  width: '50%',
                  display: 'flex',
                  alignItems: 'center',
                }}>
                {edit ? (
                  <div style={{ display: 'flex', width: '100%' }}>
                    <div style={{ width: '80%' }}>
                      <TextField
                        className='salaryType'
                        style={{ borderRadius: '0px 0px 0px 0px' }}
                        value={formik.values.totalExperience || ''}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (/^\d*\.?\d*$/.test(value)) {
                            formik.setFieldValue('totalExperience', value ? value : '');
                          }
                        }}
                        inputProps={{
                          inputMode: 'decimal', // Allows input of decimal numbers on mobile devices
                          pattern: '[0-9]*[.]?[0-9]*', // Pattern for floating-point numbers
                        }}
                        fullWidth
                        id='currentSalary'
                        size='small'
                        name='payFullTimeRate'
                        placeholder={formik.values.totalExperience ? '' : 'Enter your experience'}
                      />
                      <div className='mt-0 form-err-message'>
                        {getError(formik, 'totalExperience')}
                      </div>
                    </div>
                    <Select
                      className='input-field'
                      sx={{
                        width: '20%',
                        height: 'var(--Font-size-h2, 2.5rem)',
                        marginRight: '10px',
                      }}
                      size='small'
                      value={formik.values.totalExperienceType || ''}
                      onChange={(e) => formik.setFieldValue('totalExperienceType', e.target.value)}
                      error={Boolean(
                        formik.errors.totalExperienceType && formik.touched.totalExperienceType,
                      )}>
                      <MenuItem value='' disabled>
                        Choose...
                      </MenuItem>
                      {globalObject.lookupvalues[LookupTypes.TAD_EXPERIENCE_TYPE]
                        .slice()
                        .reverse()
                        .map((experience: any, index: number) => (
                          <MenuItem key={index} value={experience.id}>
                            {experience.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </div>
                ) : (
                  <div>
                    <span>{formik.values.totalExperience || 'N/A'}</span> &nbsp;
                    <span>
                      {globalObject.lookupvalues[LookupTypes.TAD_EXPERIENCE_TYPE].find(
                        (option: any) => option.id === formik.values.totalExperienceType,
                      )?.name || 'N/A'}
                    </span>
                  </div>
                )}
              </div>

              <div className='mt-0 form-err-message'>{getError(formik, 'totalExperienceType')}</div>
            </div>

            <div className='field-container'>
              {mode === 'edit-page' ? (
                <div style={{ width: '50%' }}>
                  <Typography sx={{ fontWeight: 600 }}>Availability to start *</Typography>
                </div>
              ) : (
                <div style={{ width: '35%' }}>
                  <Typography sx={{ fontWeight: 600 }}>Availability to start:</Typography>
                </div>
              )}

              <div
                style={{
                  width: '50%',
                  display: 'flex',
                  alignItems: 'center',
                }}>
                {edit ? (
                  <div style={{ display: 'flex', width: '100%' }}>
                    <div style={{ width: '80%' }}>
                      <TextField
                        className='salaryType'
                        style={{ borderRadius: '0px 0px 0px 0px' }}
                        value={formik.values.noticePeriod}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (/^\d*$/.test(value)) {
                            formik.setFieldValue('noticePeriod', value ? parseInt(value, 10) : '');
                          }
                        }}
                        inputProps={{
                          inputMode: 'numeric',
                          pattern: '[0-9]*',
                        }}
                        fullWidth
                        id='noticePeriod'
                        size='small'
                        name='noticePeriod'
                        placeholder='Enter your notice period'
                      />
                      <div className='mt-0 form-err-message'>
                        {getError(formik, 'noticePeriod')}
                      </div>
                    </div>
                    <Select
                      className='input-field'
                      sx={{
                        width: '20%',
                        height: 'var(--Font-size-h2, 2.5rem)',
                        marginRight: '10px',
                      }}
                      size='small'
                      value={formik.values.noticePeriodType || ''}
                      onChange={(e) => formik.setFieldValue('noticePeriodType', e.target.value)}
                      error={Boolean(
                        formik.errors.noticePeriodType && formik.touched.noticePeriodType,
                      )}>
                      <MenuItem value='' disabled>
                        Choose...
                      </MenuItem>
                      {globalObject.lookupvalues[LookupTypes.TAD_EXPERIENCE_TYPE]
                        .slice()
                        .reverse()
                        .map((experience: any, index: number) => (
                          <MenuItem key={index} value={experience.id}>
                            {experience.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </div>
                ) : (
                  <div>
                    <span>{formik.values.noticePeriod || ''}</span> &nbsp;
                    <span>
                      {globalObject.lookupvalues[LookupTypes.TAD_EXPERIENCE_TYPE].find(
                        (option: any) => option.id === formik.values.noticePeriodType,
                      )?.name || ''}
                    </span>
                  </div>
                )}
              </div>

              <div className='mt-0 form-err-message'>{getError(formik, 'noticePeriodType')}</div>
            </div>

            <div className='field-container'>
              {mode === 'edit-page' ? (
                <div style={{ width: '50%' }}>
                  <Typography sx={{ fontWeight: 600 }}>Reason for change * </Typography>
                </div>
              ) : (
                <div style={{ width: '35%' }}>
                  <Typography sx={{ fontWeight: 600 }}>Reason for change: </Typography>
                </div>
              )}
              {edit ? (
                <div style={{ width: '50%' }}>
                  <Autocomplete
                    fullWidth
                    className='input-field'
                    id='combo-box-demo'
                    options={globalObject.lookupvalues[LookupTypes.TAD_REASONS] as OptionType[]}
                    value={
                      formik.values.reasonForChange
                        ? globalObject.lookupvalues[LookupTypes.TAD_REASONS].find(
                            (option: OptionType) => option.id === formik.values.reasonForChange,
                          )
                        : null
                    }
                    size='small'
                    limitTags={1}
                    getOptionLabel={(option: any) => option.name}
                    filterSelectedOptions
                    onBlur={() => formik.setFieldTouched('reasonForChange', true, true)}
                    renderInput={(params) => (
                      <TextField
                        placeholder='Choose Reason...'
                        {...params}
                        error={Boolean(formik.errors.reasonForChange)}
                        label=''
                      />
                    )}
                    onChange={(_e, val) => {
                      formik.setFieldValue('reasonForChange', val ? val.id : undefined);
                    }}
                  />
                  <div className='mt-0 form-err-message'>{getError(formik, 'reasonForChange')}</div>
                </div>
              ) : (
                <span>{getNameFromId(formik.values.reasonForChange, LookupTypes.TAD_REASONS)}</span>
              )}
            </div>

            <div
              style={{
                // gap: '24.56rem',
                alignItems: 'center',
                marginBottom: '1.87rem',
              }}>
              <hr style={{ background: '#868686' }} />
            </div>

            <div className='field-container'>
              {mode === 'edit-page' ? (
                <div style={{ width: '50%' }}>
                  <Typography sx={{ fontWeight: 600 }}>Current Location * </Typography>
                  <span className='feildHeader'>
                    Provide current location where candidate work or live
                  </span>
                </div>
              ) : (
                <div style={{ width: '35%' }}>
                  <Typography sx={{ fontWeight: 600 }}>Current Location: </Typography>
                </div>
              )}
              {edit ? (
                <div style={{ width: '50%' }}>
                  <LocationAutoComplete
                    onSelect={setLocationCallback}
                    initialValue={formik.values.currentLocation || ''}></LocationAutoComplete>
                  <div className='mt-0 form-err-message'>{getError(formik, 'currentLocation')}</div>
                </div>
              ) : (
                <span>{formik.values.currentLocation || ''}</span>
              )}
            </div>
            <div
              className='field-container'
              style={{ justifyContent: mode === 'details-page' ? 'flex-start' : 'space-between' }}>
              {mode === 'edit-page' ? (
                <div style={{ width: '50%' }}>
                  <Typography sx={{ fontWeight: 600 }}>Willing to relocate?</Typography>
                </div>
              ) : (
                <div style={{ width: '35%' }}>
                  <Typography sx={{ fontWeight: 600 }}>Willing to relocate:</Typography>
                </div>
              )}

              {edit ? (
                <div style={{ height: 'var(--Font-size-h2, 2.5rem)', width: '50%' }}>
                  <RadioGroup
                    row
                    aria-labelledby='demo-controlled-radio-buttons-group'
                    name='controlled-radio-buttons-group'
                    value={formik.values.willingToRelocate}
                    onChange={(e) =>
                      formik.setFieldValue('willingToRelocate', e.target.value === 'true')
                    }>
                    <FormControlLabel value='false' control={<Radio />} label='No' />
                    <FormControlLabel value='true' control={<Radio />} label='Yes' />
                  </RadioGroup>
                  <div className='mt-0 form-err-message'>
                    {getError(formik, 'willingToRelocate')}
                  </div>
                </div>
              ) : (
                <span style={{ width: '50%' }}>
                  {formik.values.willingToRelocate ? 'Yes' : 'No'}
                </span>
              )}
            </div>

            <div
              className='field-container'
              style={{ justifyContent: mode === 'details-page' ? 'flex-start' : 'space-between' }}>
              {mode === 'edit-page' ? (
                <div style={{ width: '50%' }}>
                  <Typography sx={{ fontWeight: 600 }}>Zip / Pincode *</Typography>
                  <span className='feildHeader'>
                    A concise label that summarizes the primary role
                  </span>
                </div>
              ) : (
                <div style={{ width: '35%' }}>
                  <Typography sx={{ fontWeight: 600 }}>Zip / Pincode: </Typography>
                </div>
              )}
              {edit ? (
                <div style={{ width: '50%' }}>
                  <TextField
                    className='input-field'
                    fullWidth
                    sx={{
                      height: 'var(--Font-size-h2, 2.5rem)',
                    }}
                    value={formik.values.pinCode}
                    onChange={(e) => {
                      const value = e.target.value;

                      const numericValue = value.replace(/\D/g, '');
                      if (numericValue.length <= 8) {
                        formik.setFieldValue('pinCode', numericValue);
                      }
                    }}
                    inputProps={{
                      maxLength: 8,
                    }}
                    error={Boolean(formik.errors.pinCode && formik.touched.pinCode)}
                    placeholder='Type Here...'
                  />

                  <div className='mt-0 form-err-message'>{getError(formik, 'pinCode')}</div>
                </div>
              ) : (
                <span style={{ width: '50%' }}>{formik.values.pinCode || 'N/A'}</span>
              )}
            </div>
            <div
              className='field-container'
              style={{ justifyContent: mode == 'details-page' ? 'flex-start' : 'space-between' }}>
              {mode === 'edit-page' ? (
                <div style={{ width: '50%' }}>
                  <Typography sx={{ fontWeight: 600 }}>Source *</Typography>
                </div>
              ) : (
                <div style={{ width: '35%' }}>
                  <Typography sx={{ fontWeight: 600 }}>Source: </Typography>
                </div>
              )}
              {edit ? (
                <div style={{ width: '50%' }}>
                  <Autocomplete
                    fullWidth
                    className='visaType'
                    id='combo-box-demo'
                    options={
                      globalObject.lookupvalues[LookupTypes.TAD_SOURCE_CHANNEL] as OptionType[]
                    }
                    value={
                      formik.values.sourceChannel
                        ? globalObject.lookupvalues[LookupTypes.TAD_SOURCE_CHANNEL].find(
                            (option: OptionType) => option.id === formik.values.sourceChannel,
                          )
                        : null
                    }
                    size='small'
                    sx={{
                      background: 'var(--Secondary-colors-White, #FFF)',
                      border: '1px solid var(--Secondary-colors-Grey, #868686)',
                      borderRadius: '0.3125rem',
                    }}
                    limitTags={1}
                    getOptionLabel={(option: any) => option.name}
                    filterSelectedOptions
                    onBlur={() => formik.setFieldTouched('sourceChannel', true, true)}
                    renderInput={(params) => (
                      <TextField
                        placeholder='Choose Source...'
                        {...params}
                        error={Boolean(formik.errors.sourceChannel && formik.touched.sourceChannel)}
                      />
                    )}
                    onChange={(_e, val) => {
                      formik.setFieldValue('sourceChannel', val ? val.id : undefined);
                    }}
                  />

                  <div className='mt-0 form-err-message'>{getError(formik, 'sourceChannel')}</div>
                </div>
              ) : (
                <span style={{ width: '50%' }}>
                  {getNameFromId(formik.values.sourceChannel, LookupTypes.TAD_SOURCE_CHANNEL)}
                </span>
              )}
            </div>

            <div
              style={{
                // gap: '24.56rem',
                alignItems: 'center',
                marginBottom: '1.87rem',
              }}>
              <hr style={{ background: '#868686' }} />
            </div>

            <div className='field-container'>
              {mode === 'edit-page' ? (
                <div style={{ width: '50%' }}>
                  <Typography sx={{ fontWeight: 600 }}>Relocation Bonus</Typography>
                </div>
              ) : (
                <div style={{ width: '35%' }}>
                  <Typography sx={{ fontWeight: 600 }}>Relocation Bonus:</Typography>
                </div>
              )}

              <div
                style={{
                  width: '50%',
                  display: 'flex',
                  alignItems: 'center',
                }}>
                <div style={{ display: 'flex', width: '100%' }}>
                  {edit ? (
                    <CountryCodes
                      selectedCountry={selectedFullTimeFlag}
                      setSelectedCountry={(value) => setSelectedFullTimeFlag(value)}
                      code={true}
                    />
                  ) : (
                    <span>{selectedFullTimeFlag}</span>
                  )}

                  {edit ? (
                    <TextField
                      className='salaryType'
                      style={{ borderRadius: '0px' }}
                      value={formik.values.relocationBonus?.replace(/\D/g, '') || ''}
                      onChange={(e) =>
                        formik.setFieldValue(
                          'relocationBonus',
                          `${selectedFullTimeFlag} ${e.target.value}`,
                        )
                      }
                      fullWidth
                      id='currentSalary'
                      size='small'
                      name='payFullTimeRate'
                      placeholder='Enter salary'
                    />
                  ) : (
                    <span>{formik.values.relocationBonus}</span>
                  )}

                  {edit ? (
                    <Select
                      className='input-field'
                      sx={{
                        width: '10.625rem',
                        height: 'var(--Font-size-h2, 2.5rem)',
                        marginRight: '10px',
                      }}
                      size='small'
                      value={formik.values.relocationBonusType || 1}
                      onChange={(e) => formik.setFieldValue('relocationBonusType', e.target.value)}
                      error={Boolean(
                        formik.errors.relocationBonusType && formik.touched.relocationBonusType,
                      )}>
                      <MenuItem value='' disabled>
                        Choose...
                      </MenuItem>
                      {globalObject.lookupvalues[LookupTypes.TAD_CANDIDATE_COMPENSATION_BONUS].map(
                        (payType: any, index: any) => (
                          <MenuItem key={index} value={payType.id}>
                            {payType.name}
                          </MenuItem>
                        ),
                      )}
                    </Select>
                  ) : (
                    <span>{formik.values.relocationBonusType}</span>
                  )}
                </div>
              </div>

              <div className='mt-0 form-err-message'>{getError(formik, 'relocationBonus')}</div>
            </div>

            <div className='field-container'>
              {mode === 'edit-page' ? (
                <div style={{ width: '50%' }}>
                  <Typography sx={{ fontWeight: 600 }}>Joining Bonus</Typography>
                </div>
              ) : (
                <div style={{ width: '35%' }}>
                  <Typography sx={{ fontWeight: 600 }}>Joining Bonus:</Typography>
                </div>
              )}

              <div
                style={{
                  width: '50%',
                  display: 'flex',
                  alignItems: 'center',
                }}>
                <div style={{ display: 'flex', width: '100%' }}>
                  {edit ? (
                    <CountryCodes
                      selectedCountry={selectedFullTimeFlag}
                      setSelectedCountry={(value) => setSelectedFullTimeFlag(value)}
                      code={true}
                    />
                  ) : (
                    <span>{selectedFullTimeFlag}</span>
                  )}

                  {edit ? (
                    <TextField
                      className='salaryType'
                      style={{ borderRadius: '0px' }}
                      value={formik.values.joiningBonus?.replace(/\D/g, '') || ''}
                      onChange={(e) =>
                        formik.setFieldValue(
                          'joiningBonus',
                          `${selectedFullTimeFlag} ${e.target.value}`,
                        )
                      }
                      fullWidth
                      id='currentSalary'
                      size='small'
                      name='payFullTimeRate'
                      placeholder='Enter salary'
                    />
                  ) : (
                    <span>{formik.values.joiningBonus}</span>
                  )}

                  {edit ? (
                    <Select
                      className='input-field'
                      sx={{
                        width: '10.625rem',
                        height: 'var(--Font-size-h2, 2.5rem)',
                        marginRight: '10px',
                      }}
                      size='small'
                      value={formik.values.joiningBonusType || 1}
                      onChange={(e) => formik.setFieldValue('joiningBonusType', e.target.value)}
                      error={Boolean(
                        formik.errors.joiningBonusType && formik.touched.joiningBonusType,
                      )}>
                      <MenuItem value='' disabled>
                        Choose...
                      </MenuItem>
                      {globalObject.lookupvalues[LookupTypes.TAD_CANDIDATE_COMPENSATION_BONUS].map(
                        (payType: any, index: any) => (
                          <MenuItem key={index} value={payType.id}>
                            {payType.name}
                          </MenuItem>
                        ),
                      )}
                    </Select>
                  ) : (
                    <span>{formik.values.joiningBonusType}</span>
                  )}
                </div>
              </div>

              <div className='mt-0 form-err-message'>{getError(formik, 'joiningBonus')}</div>
            </div>

            <div className='field-container'>
              {mode === 'edit-page' ? (
                <div style={{ width: '50%' }}>
                  <Typography sx={{ fontWeight: 600 }}>Other Bonus</Typography>
                </div>
              ) : (
                <div style={{ width: '35%' }}>
                  <Typography sx={{ fontWeight: 600 }}>Other Bonus:</Typography>
                </div>
              )}

              <div
                style={{
                  width: '50%',
                  display: 'flex',
                  alignItems: 'center',
                }}>
                <div style={{ display: 'flex', width: '100%' }}>
                  {edit ? (
                    <CountryCodes
                      selectedCountry={selectedFullTimeFlag}
                      setSelectedCountry={(value) => setSelectedFullTimeFlag(value)}
                      code={true}
                    />
                  ) : (
                    <span>{selectedFullTimeFlag}</span>
                  )}

                  {edit ? (
                    <TextField
                      className='salaryType'
                      style={{ borderRadius: '0px' }}
                      value={formik.values.otherBonus?.replace(/\D/g, '') || ''}
                      onChange={(e) =>
                        formik.setFieldValue(
                          'otherBonus',
                          `${selectedFullTimeFlag} ${e.target.value}`,
                        )
                      }
                      fullWidth
                      id='currentSalary'
                      size='small'
                      name='payFullTimeRate'
                      placeholder='Enter salary'
                    />
                  ) : (
                    <span>{formik.values.otherBonus}</span>
                  )}

                  {edit ? (
                    <Select
                      className='input-field'
                      sx={{
                        width: '10.625rem',
                        height: 'var(--Font-size-h2, 2.5rem)',
                        marginRight: '10px',
                      }}
                      size='small'
                      value={formik.values.otherBonusType || 1}
                      onChange={(e) => formik.setFieldValue('otherBonusType', e.target.value)}
                      error={Boolean(
                        formik.errors.otherBonusType && formik.touched.otherBonusType,
                      )}>
                      <MenuItem value='' disabled>
                        Choose...
                      </MenuItem>
                      {globalObject.lookupvalues[LookupTypes.TAD_CANDIDATE_COMPENSATION_BONUS].map(
                        (payType: any, index: any) => (
                          <MenuItem key={index} value={payType.id}>
                            {payType.name}
                          </MenuItem>
                        ),
                      )}
                    </Select>
                  ) : (
                    <span>{formik.values.otherBonusType}</span>
                  )}
                </div>
              </div>

              <div className='mt-0 form-err-message'>{getError(formik, 'otherBonus')}</div>
            </div>

            <div className='field-container'>
              {mode === 'edit-page' ? (
                <div style={{ width: '50%' }}>
                  <Typography sx={{ fontWeight: 600 }}>Accommodation Required </Typography>
                </div>
              ) : (
                <div style={{ width: '35%' }}>
                  <Typography sx={{ fontWeight: 600 }}>Accommodation Required: </Typography>
                </div>
              )}
              {edit ? (
                <div style={{ width: '50%' }}>
                  <Autocomplete
                    fullWidth
                    className='visaType'
                    id='combo-box-demo'
                    options={
                      globalObject.lookupvalues[
                        LookupTypes.TAD_CANDIDATE_COMPENSATION_BONUS
                      ] as OptionType[]
                    }
                    value={
                      formik.values.accommodationRequired
                        ? globalObject.lookupvalues[
                            LookupTypes.TAD_CANDIDATE_COMPENSATION_BONUS
                          ].find(
                            (option: OptionType) =>
                              option.id === formik.values.accommodationRequired,
                          )
                        : null
                    }
                    size='small'
                    sx={{
                      background: 'var(--Secondary-colors-White, #FFF)',
                      border: '1px solid var(--Secondary-colors-Grey, #868686)',
                      borderRadius: '0.3125rem',
                    }}
                    limitTags={1}
                    getOptionLabel={(option: any) => option.name}
                    filterSelectedOptions
                    onBlur={() => formik.setFieldTouched('accommodationRequired', true, true)}
                    renderInput={(params) => (
                      <TextField
                        placeholder='Choose...'
                        {...params}
                        error={Boolean(
                          formik.errors.accommodationRequired &&
                            formik.touched.accommodationRequired,
                        )}
                        label=''
                      />
                    )}
                    onChange={(_e, val) => {
                      formik.setFieldValue('accommodationRequired', val ? val.id : undefined);
                    }}
                  />
                </div>
              ) : (
                <span>
                  {globalObject.lookupvalues[LookupTypes.TAD_CANDIDATE_COMPENSATION_BONUS].find(
                    (option: OptionType) => option.id === formik.values.accommodationRequired,
                  )?.name || ''}
                </span>
              )}
            </div>

            <div
              className='field-container'
              style={{ justifyContent: mode === 'details-page' ? 'flex-start' : 'space-between' }}>
              {mode === 'edit-page' ? (
                <div style={{ width: '50%' }}>
                  <Typography sx={{ fontWeight: 600 }}>Joining Date</Typography>
                </div>
              ) : (
                <div style={{ width: '35%' }}>
                  <Typography sx={{ fontWeight: 600 }}>Joining Date:</Typography>
                </div>
              )}

              <div style={{ width: '50%' }}>
                {edit ? (
                  <div>
                    <DatePicker
                      className='date-picker'
                      sx={{ width: '100%' }}
                      format='DD/MM/YYYY'
                      views={['year', 'month', 'day']}
                      value={formik.values.joiningDate ? dayjs(formik.values.joiningDate) : null}
                      onChange={(value) => {
                        formik.setFieldValue(
                          'joiningDate',
                          dateFormat(value?.toString(), 'isoDateTime'),
                          true,
                        );
                      }}
                    />
                    <div className='mt-0 form-err-message'>{getError(formik, 'joiningDate')}</div>
                  </div>
                ) : (
                  <span style={{ overflowWrap: 'anywhere' }}>
                    {formik.values.joiningDate &&
                      dateFormat(new Date(formik.values.joiningDate), 'dd/mm/yyyy')}
                  </span>
                )}
              </div>
            </div>
          </div>

          {mode === 'edit-page' && (
            <div style={{ padding: '1.87rem 1.88rem', borderTop: '1px solid #868686' }}>
              <div
                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Button
                  className='button'
                  onClick={() => {
                    navigate([
                      {
                        label: 'Candidates',
                        link: '/candidate',
                      },
                    ]);
                  }}
                  sx={{
                    width: '7.375rem',
                    height: '2.5rem',
                    borderRadius: '5px',
                    border: '1px solid #2D7DFE',
                    backgroundColor: '#FFF',
                    color: 'black',
                    textTransform: 'none',
                    fontSize: '1rem',
                    fontWeight: 400,
                  }}>
                  Cancel
                </Button>

                <div style={{ display: 'flex', gap: '15px' }}>
                  <Button
                    className='button'
                    onClick={() => {
                      previous();
                    }}
                    sx={{
                      width: '7.375rem',
                      height: '2.5rem',
                      borderRadius: '5px',
                      border: '1px solid #2D7DFE',
                      backgroundColor: '#FFF',
                      color: 'black',
                      textTransform: 'none',
                      fontSize: '1rem',
                      fontWeight: 400,
                    }}>
                    Previous
                  </Button>
                  <Button
                    className='button'
                    onClick={() => {
                      setSubmitted(true);
                      formik.handleSubmit();
                    }}
                    sx={{
                      textTransform: 'none',
                      fontWeight: 400,
                      fontSize: '1rem',
                      width: '7.375rem',
                      height: '2.5rem',
                      color: 'white !important',
                      background: '#2D7DFE !important',
                    }}>
                    Submit
                  </Button>
                </div>
              </div>
            </div>
          )}
        </Paper>
      </div>
    </>
  );
};

export default AdditionalDetails;
