import { Add, Search } from '@mui/icons-material';
import {
  Paper,
  Button,
  InputLabel,
  Box,
  InputAdornment,
  OutlinedInput,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Drawer,
  TableBody,
  TableFooter,
  TablePagination,
  MenuItem,
  Select,
  Collapse,
  IconButton,
  Backdrop,
  CircularProgress,
  ListItemIcon,
  ListItemText,
  Menu,
  DialogContent,
  Dialog,
  DialogContentText,
  DialogActions,
  AlertColor,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
// import ApprovalVendors from './approval-vendors';
import { ReactComponent as FilterListIcon } from '../../../tad-revamp-ui/assets/user-settings/filter_list.svg';
import { ReactComponent as ArrowIcons } from './../../assets/sjd/chevron_right.svg';
import { ReactComponent as MoreIcon } from './../../assets/dashboard-assets/more_vert.svg';

import Notify from '../../common/notify';
import Edit from './../../assets/sjd/Edit.svg';
// import Delete from './../../assets/sjd/delete_outline.svg';
import ContactCard from '../common/contact-card';
import AddNewClient from './addNew-client';
import {
  tadgetAllApprovals,
  tadDeleteClientApprovals,
  tadDeleteClientProjects,
  tadUpdateProjectClient,
  tadUpdateApprovals,
} from '../../service/tadApprovalService';

const Approvals = () => {
  type tabType = 'AllClients' | 'Vendors';
  const [selectedType, setSelectedType] = useState<tabType>('AllClients');
  const [rowData, setRowData] = useState([] as any[]);
  const [rowsToDisplay, setRowsToDisplay] = useState(5);
  const [displayRowData, setDisplayRowData] = useState(rowData);
  const [subTableFlag, setSubTableFlag] = useState(null);
  const [approval, setAllApprovals] = useState([] as any[]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  // const paginatedJobs = rowsToDisplay == -1 ? rowData : displayRowData.slice(0, rowsToDisplay);
  const paginatedJobs =
    rowsToDisplay == -1
      ? rowData
      : displayRowData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedClient, setSelectedClient] = useState(undefined);
  // const [approvalRequired, setApprovalRequired] = useState('false');
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  // const [isDelete, setIsDelete] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMsg, setToastMsg] = useState({
    severity: 'success',
    msg: '',
  } as {
    severity: AlertColor;
    msg: string;
  });
  const [open, setOpen] = useState(false);
  const [sortConfig, setSortConfig] = useState<{ field: string; direction: 'asc' | 'desc' | null }>(
    {
      field: '',
      direction: null,
    },
  );
  const [contact, setContact] = useState({} as any);
  const [contactAnchor, setContactAnchor] = useState<null | HTMLElement>(null);

  const handleAddClient = () => {
    setOpen(true);
    setIsEdit(false);
  };

  const handleDrawerClose = () => {
    fetchClients();
    setOpen(false);
  };

  const CLIENT_HEADERS = [
    `Client Name(${rowData.length})`,
    'Client Type',
    'Account Manager',
    'Created On',
    'Modified On',
    'Status',
    'Approval Required',
    'Created By',
    'Actions',
  ];

  const handleToastClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowToast(false);
  };

  useEffect(() => {
    setSelectedType('AllClients');
  }, []);

  const FILTERABLE_HEADERS = ['Created On', 'Modified On', 'Status'];

  useEffect(() => {
    fetchClients();
    // console.log('Approval', approval);
  }, []);

  const fetchClients = () => {
    setLoading(true);
    tadgetAllApprovals()
      .then((res) => {
        setAllApprovals(res.data);
        setRowData(res.data);
        setDisplayRowData(res.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setShowToast(true);
        setToastMsg({
          severity: 'error',
          msg: 'Something went wrong!!',
        });
      });
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const selectedValue = parseInt(event.target.value as string, 10);
    const isAll = selectedValue === -1;

    setRowsPerPage(isAll ? -1 : selectedValue);
    setRowsToDisplay(isAll ? -1 : Math.min(selectedValue, rowData.length));
    setPage(0);
  };

  const formatDate = (timestamp: number): string => {
    const date = new Date(timestamp);
    const options: Intl.DateTimeFormatOptions = {
      day: '2-digit',
      month: 'long',
      year: 'numeric',
    };
    return date.toLocaleDateString('en-GB', options);
  };

  const handleFilterClick = (header: string) => {
    const fieldMap: Record<string, string> = {
      'Created On': 'createdOn',
      Status: 'clientStatus',
      'Modified On': 'modifiedOn',
    };

    const field = fieldMap[header];
    if (field) {
      let direction: 'asc' | 'desc' | null = 'asc';
      if (sortConfig.field === field && sortConfig.direction === 'asc') {
        direction = 'desc';
      } else if (sortConfig.field === field && sortConfig.direction === 'desc') {
        direction = null;
      }

      setSortConfig({ field, direction });

      const sortedData = [...approval];

      if (field === 'createdOn' || field === 'modifiedOn') {
        if (direction === 'asc') {
          sortedData.sort((a, b) => new Date(a[field]).getTime() - new Date(b[field]).getTime());
        } else if (direction === 'desc') {
          sortedData.sort((a, b) => new Date(b[field]).getTime() - new Date(a[field]).getTime());
        }
      } else {
        if (direction === 'asc') {
          sortedData.sort((a, b) => (a[field] > b[field] ? 1 : a[field] < b[field] ? -1 : 0));
        } else if (direction === 'desc') {
          sortedData.sort((a, b) => (a[field] < b[field] ? 1 : a[field] > b[field] ? -1 : 0));
        }
      }

      if (field === 'clientStatus') {
        if (direction === 'asc') {
          sortedData.sort((a, b) =>
            a.clientStatus === b.clientStatus ? 0 : a.clientStatus ? -1 : 1,
          );
        } else if (direction === 'desc') {
          sortedData.sort((a, b) =>
            a.clientStatus === b.clientStatus ? 0 : a.clientStatus ? 1 : -1,
          );
        }
      }

      setRowData(sortedData);
      setDisplayRowData(sortedData);
    }
  };

  const requestSearch = (searchedVal: string) => {
    setPage(0);

    const filterData = approval.filter((item) => {
      const clientName = item.clientName.toLowerCase() || '';
      return (
        clientName.includes(searchedVal.toLowerCase()) ||
        (item.clientUserId &&
          item.clientUserId.firstName?.toLowerCase().includes(searchedVal.toLowerCase())) ||
        (item.clientUserId &&
          item.clientUserId.lastName?.toLowerCase().includes(searchedVal.toLowerCase()))
      );
    });
    setRowData(filterData);
    setDisplayRowData(filterData);
  };

  const showActionsList = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeActionsMenu = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    setOpen(true);
    setIsEdit(true);
    closeActionsMenu();
  };
  // const handleDelete = () => {
  //   setOpen(false);
  //   setIsDelete(!isDelete);
  //   setDialogOpen(true);
  //   closeActionsMenu();
  // };

  const handleDeleteClientApprovals = (clientId: any) => {
    setLoading(true);
    tadDeleteClientApprovals(clientId.clientId)
      .then((_resp) => {
        fetchClients();
        setToastMsg({
          severity: 'success',
          msg: 'Client Deleted successfully',
        });
        setShowToast(true);

        // location.reload();
      })
      .catch((_err) => {
        // console.log(err);
        setLoading(false);
        setShowToast(true);
        setToastMsg({
          severity: 'error',
          msg: 'Something went wrong!!',
        });
      });
  };

  const actionsList = [
    { name: 'Edit', icon: Edit, action: handleEdit },
    // { name: 'Delete', icon: Delete, action: handleDelete },
  ];

  const confirmDelete = () => {
    if (selectedClient) {
      handleDeleteClientApprovals(selectedClient);
    }
    setDialogOpen(false);
  };

  const SubTable = ({ index }: any) => {
    const [anchorElProject, setAnchorElProject] = React.useState<null | HTMLElement>(null);
    const [selectedProjectRow, setSelectedProjectRow] = useState(null);
    const [loading, setLoading] = useState(false);
    const [deleteProjectDialogOpen, setDeleteProjectDilaogOpen] = useState(false);

    const showProjectActionsList = (event: React.MouseEvent<HTMLElement>, row: any) => {
      setAnchorElProject(event.currentTarget);
      setSelectedProjectRow(row);
    };

    const closeActionsMenu = () => {
      setAnchorElProject(null);
      setSelectedProjectRow(null);
    };

    const deleteProjectApi = (projectId: any, clientId: any) => {
      setLoading(true);
      tadDeleteClientProjects(projectId, clientId)
        .then((_resp) => {
          fetchClients();
          setToastMsg({
            severity: 'success',
            msg: 'Project Deleted successfully',
          });
          setShowToast(true);
        })
        .catch((_err) => {
          // console.log(err);
          setLoading(false);
          setShowToast(true);
          setToastMsg({
            severity: 'error',
            msg: 'Something went wrong!!',
          });
        });
    };

    // const handleDeleteProject = () => {
    //   setDeleteProjectDilaogOpen(true);
    // };

    const confirmDeleteProject = () => {
      if (selectedProjectRow) {
        const { projectId, clientId } = selectedProjectRow;
        deleteProjectApi(projectId, clientId);
        closeActionsMenu();
      }
      // console.log('handleDelete');
    };

    const subTableProjectActionsList: any[] = [
      // { name: 'Delete', icon: Delete, action: handleDeleteProject },
    ];

    return (
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Table sx={{ margin: '0.5rem 0rem 1rem 0rem', width: '93%' }}>
          <TableHead>
            <TableRow>
              <TableCell>Project Name</TableCell>
              <TableCell>Hiring Manager</TableCell>
              <TableCell>Created On</TableCell>
              <TableCell>Modified On</TableCell>
              <TableCell>Status</TableCell>
              <TableCell sx={{ width: '5%' }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedJobs[index].projects.length > 0 ? (
              paginatedJobs[index].projects.map((member: any, index: any) => (
                <TableRow key={index}>
                  <TableCell
                    sx={{
                      borderBottom: 'none',
                    }}>
                    {member.projectName}
                  </TableCell>
                  <TableCell sx={{ borderBottom: 'none', color: 'rgba(25, 118, 210, 1)' }}>
                    {member.hiringManagerId
                      ? `${member.hiringManagerId.firstName} ${member.hiringManagerId.lastName}`
                      : '-'}
                  </TableCell>
                  <TableCell sx={{ borderBottom: 'none' }}>
                    {formatDate(member.createdOn)}
                  </TableCell>
                  <TableCell sx={{ borderBottom: 'none' }}>
                    {formatDate(member.modifiedOn)}
                  </TableCell>
                  <TableCell sx={{ borderBottom: 'none' }}>
                    <Select
                      sx={{
                        // width: '40%',
                        color: 'var(--Primary-Dark-grey-500, #333)',
                        fontFamily: 'var(--Font, Roboto)',
                        fontSize: 'var(--Font-size-small, 0.8125rem)',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: '1.25rem',
                        letterSpacing: '0.04063rem',
                        background:
                          member.status.toString() === 'true'
                            ? 'rgba(122, 224, 139, 0.59)'
                            : 'var(--Secondary-colors-Grey-3, #F1F4F8)',
                      }}
                      value={member.status}
                      onChange={(e: any) => {
                        member.status = e.target.value === 'true' ? true : false;
                        setLoading(true);
                        tadUpdateProjectClient(member)
                          .then((_resp) => {
                            setLoading(false);
                            setShowToast(true);
                            setToastMsg({
                              severity: 'success',
                              msg: 'Project Status Updated Successfully',
                            });
                          })
                          .catch((err) => {
                            member.status = !member.status;
                            console.log(err);
                            setLoading(false);
                            setShowToast(true);
                            setToastMsg({
                              severity: 'error',
                              msg: 'Something went wrong!!',
                            });
                          });
                      }}
                      displayEmpty>
                      <MenuItem value='true'>ACTIVE</MenuItem>
                      <MenuItem value='false'>IN ACTIVE</MenuItem>
                    </Select>
                  </TableCell>
                  {/* <TableCell sx={{ borderBottom: 'none' }}>{member.status}</TableCell> */}

                  <TableCell sx={{ borderBottom: 'none', cursor: 'none' }}>
                    <IconButton
                      onClick={(e) => {
                        showProjectActionsList(e, member);
                      }}>
                      <MoreIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6} sx={{ textAlign: 'center' }}>
                  No Records To Display
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <Menu
          anchorEl={anchorElProject}
          open={Boolean(anchorElProject)}
          onClose={closeActionsMenu}
          slotProps={{
            paper: {
              sx: {
                boxShadow:
                  '0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)',
              },
            },
          }}>
          {subTableProjectActionsList.map((action, index) => (
            <MenuItem
              key={index}
              onClick={() => {
                action.action();
              }}>
              <ListItemIcon>
                <img
                  src={action.icon}
                  alt={action.name}
                  style={{ width: '20px', height: '20px' }}
                />
              </ListItemIcon>
              <ListItemText primary={action.index} />
            </MenuItem>
          ))}
        </Menu>
        <Dialog open={deleteProjectDialogOpen} onClose={() => setDeleteProjectDilaogOpen(false)}>
          <DialogContent>
            <DialogContentText>Are you sure you want to delete this Project?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDeleteProjectDilaogOpen(false)}>No</Button>
            <Button onClick={confirmDeleteProject}>Yes</Button>
          </DialogActions>
        </Dialog>
        {loading && (
          <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
            <CircularProgress color='inherit' />
          </Backdrop>
        )}
      </div>
    );
  };

  return (
    <div style={{ width: '100%', display: 'flex', gap: '1.63rem', flexDirection: 'column' }}>
      <div
        style={{
          height: '8.8125rem',
          width: '100%',
        }}>
        <Paper
          className='new-job-requisition'
          sx={{
            width: '100%',
            height: '8.8125rem',
          }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginLeft: '1.88rem',
              gap: '7px',
            }}>
            <span style={{ fontSize: '1.25rem', fontWeight: 600 }}>Approvals</span>
            <span
              style={{
                fontSize: '0.875rem',
                fontWeight: 400,
                color: 'var(--Secondary-Grey-1, var(--Secondary-colors-Grey, #868686))',
              }}>
              Manage your candidate, job approvals seamlessly.
            </span>
          </div>
          <div
            style={{
              display: 'flex',
              marginRight: '2.5rem',
              alignItems: 'center',
            }}>
            <div>
              <Button
                className='button'
                fullWidth
                sx={{
                  textTransform: 'none',
                  width: '9.526rem',
                  fontWeight: 400,
                  fontSize: '1rem',
                  height: '3.125rem',
                  color: 'white !important',
                  background: '#1976D2 !important',
                }}
                onClick={() => {
                  handleAddClient();
                  // setIsEdit(false);
                  // setIsDelete(false);
                }}>
                <Add style={{ width: '15px', height: '15px', paddingRight: '4px' }} />
                <div>
                  <InputLabel sx={{ color: 'white', cursor: 'pointer' }}>Add Client</InputLabel>
                </div>
              </Button>
            </div>
          </div>
        </Paper>
      </div>

      <Drawer
        sx={{ width: '21.0625rem', height: '64rem', flexShrink: 0 }}
        anchor='right'
        open={open}
        onClose={() => setOpen(false)}>
        {
          <>
            {/* <AddNewUser user={selectedUser} isEdit={isEdit} handleClose={handleAddUserModalClose} /> */}
            <AddNewClient
              clientData={selectedClient}
              isEdit={isEdit}
              // isDelete={isDelete}
              handleClose={handleDrawerClose}
            />
          </>
        }
      </Drawer>

      <Paper className='paper' sx={{ width: '100%' }}>
        <Box sx={{ padding: '15px' }}>
          <div
            style={{
              height: '45px',
              display: 'flex',
              marginLeft: '35px',
              borderBottom: '1px solid rgba(150, 150, 150,1)',
              justifyContent: 'end',
            }}>
            <OutlinedInput
              sx={{ background: 'white', height: '40px' }}
              id='table-search'
              type='text'
              placeholder='Search...'
              onChange={(event) => requestSearch(event.target.value)}
              endAdornment={
                <InputAdornment position='end'>
                  <Search sx={{ color: '#1976D2' }} />
                </InputAdornment>
              }
            />
          </div>
          {selectedType == 'AllClients' ? (
            <div style={{ padding: '20px', marginTop: '10px' }}>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}></div>
              <TableContainer>
                <Table aria-label='simple table'>
                  <TableHead>
                    <TableRow>
                      {CLIENT_HEADERS.map((clientHeader) => (
                        <TableCell className='user-text' key={clientHeader}>
                          {clientHeader}
                          {FILTERABLE_HEADERS.includes(clientHeader) && (
                            <FilterListIcon
                              style={{
                                cursor: 'pointer',
                                marginLeft: '0.5rem',
                                height: '20px',
                                width: '20px',
                              }}
                              onClick={() => handleFilterClick(clientHeader)}
                            />
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {paginatedJobs.length > 0 ? (
                      paginatedJobs.map((item: any, index: any) => (
                        <React.Fragment key={index}>
                          <TableRow
                            sx={{
                              backgroundColor: subTableFlag == index ? '#F1F4F8' : 'white',
                              marginTop: '0.5rem',
                            }}>
                            <TableCell
                              style={{
                                color: 'rgba(25, 118, 210, 1)',
                                textDecoration: 'underline',
                              }}>
                              <IconButton
                                onClick={() =>
                                  setSubTableFlag(subTableFlag == index ? null : index)
                                }>
                                <ArrowIcons
                                  className={subTableFlag === index ? 'arrowRight' : 'arrowLeft'}
                                />
                              </IconButton>
                              {item.clientName}
                              {/* {getNameFromId(item.clientId, LookupTypes.TAD_CLIENT)} */}
                            </TableCell>
                            <TableCell>{item.projectTypeId.name}</TableCell>

                            <TableCell
                              style={{
                                color: 'rgba(25, 118, 210, 1)',
                                // textDecoration: 'underline',
                              }}>
                              {item.clientUserId
                                ? `${item.clientUserId.firstName} ${item.clientUserId.lastName}`
                                : '-'}
                            </TableCell>
                            <TableCell>{formatDate(item.createdOn)}</TableCell>
                            <TableCell>{formatDate(item.modifiedOn)}</TableCell>
                            <TableCell>
                              {/* {item.status ? 'Active' : 'Inactive'} */}
                              <Select
                                sx={{
                                  // width: '75%',
                                  color: 'var(--Primary-Dark-grey-500, #333)',
                                  fontFamily: 'var(--Font, Roboto)',
                                  fontSize: 'var(--Font-size-small, 0.8125rem)',
                                  fontStyle: 'normal',
                                  fontWeight: 400,
                                  lineHeight: '1.25rem',
                                  letterSpacing: '0.04063rem',
                                  borderRadius: '0.3125rem',
                                  background:
                                    item.clientStatus?.toString() === 'true'
                                      ? 'rgba(122, 224, 139, 0.59)'
                                      : 'var(--Secondary-colors-Grey-3, #F1F4F8)',
                                }}
                                value={item.clientStatus}
                                onChange={(e: any) => {
                                  item.clientStatus = e.target.value === 'true' ? true : false;
                                  const updatedItem = {
                                    ...item,
                                    projectTypeId: item.projectTypeId.id,
                                    clientStatus: item.clientStatus,
                                    clientUserId: item.clientUserId?.id,
                                    createdBy: item.createdBy.id,
                                  };
                                  setLoading(true);
                                  tadUpdateApprovals(updatedItem)
                                    .then((_resp) => {
                                      setLoading(false);
                                      setShowToast(true);
                                      setToastMsg({
                                        severity: 'success',
                                        msg: 'Client updated successfully',
                                      });
                                    })
                                    .catch((_err) => {
                                      item.clientStatus = !item.clientStatus;
                                      setLoading(false);
                                      setShowToast(true);
                                      setToastMsg({
                                        severity: 'error',
                                        msg: 'Something went wrong!!',
                                      });
                                    });
                                }}
                                displayEmpty>
                                <MenuItem value='true'>ACTIVE</MenuItem>
                                <MenuItem value='false'>IN ACTIVE</MenuItem>
                              </Select>
                            </TableCell>
                            <TableCell style={{ paddingLeft: '50px' }}>
                              <Select
                                sx={{
                                  color: 'var(--Primary-Dark-grey-500, #333)',
                                  fontFamily: 'var(--Font, Roboto)',
                                  fontSize: 'var(--Font-size-small, 0.8125rem)',
                                  fontStyle: 'normal',
                                  fontWeight: 400,
                                  lineHeight: '1.25rem',
                                  letterSpacing: '0.04063rem',
                                  borderRadius: '0.3125rem',
                                  background:
                                    item.approvalRequired.toString() === 'true'
                                      ? 'rgba(122, 224, 139, 0.59)'
                                      : 'var(--Secondary-colors-Grey-3, #F1F4F8)',
                                }}
                                value={item.approvalRequired}
                                onChange={(e: any) => {
                                  item.approvalRequired = e.target.value === 'true' ? true : false;
                                  const updatedItem = {
                                    ...item,
                                    projectTypeId: item.projectTypeId.id,
                                    approvalRequired: item.approvalRequired,
                                    clientUserId: item.clientUserId?.id,
                                    createdBy: item.createdBy.id,
                                  };
                                  setLoading(true);
                                  tadUpdateApprovals(updatedItem)
                                    .then((_resp) => {
                                      setLoading(false);
                                      setShowToast(true);
                                      setToastMsg({
                                        severity: 'success',
                                        msg: 'Client updated successfully',
                                      });
                                    })
                                    .catch((_err) => {
                                      item.approvalRequired = !item.approvalRequired;
                                      setLoading(false);
                                      setShowToast(true);
                                      setToastMsg({
                                        severity: 'error',
                                        msg: 'Something went wrong!!',
                                      });
                                    });
                                }}
                                displayEmpty>
                                <MenuItem value='true'>Yes</MenuItem>
                                <MenuItem value='false'> No</MenuItem>
                              </Select>
                            </TableCell>
                            <TableCell
                              key={index}
                              style={{
                                color: 'rgba(25, 118, 210, 1)',
                                // width: '9rem',
                                // textDecoration: 'underline',
                              }}>
                              {item.createdBy.firstName + ' ' + item.createdBy.lastName}
                              {/* <div style={{ display: 'flex', justifyContent: 'center' }}>
                                {item?.createdBy &&
                                handleCreatedByUsers(item?.createdBy)?.profilePicture ? (
                                  <Avatar
                                    sx={{
                                      bgcolor: lightBlue[700],
                                      height: 30,
                                      width: 30,
                                      cursor: 'pointer',
                                    }}
                                    onClick={(e) => {
                                      setContact(handleCreatedByUsers(item.createdBy));
                                      setContactAnchor(e.currentTarget);
                                    }}
                                    src={
                                      handleCreatedByUsers(item?.createdBy).profilePicture
                                    }></Avatar>
                                ) : (
                                  <Avatar
                                    sx={{
                                      bgcolor: lightBlue[700],
                                      height: 30,
                                      width: 30,
                                      cursor: 'pointer',
                                    }}
                                    onClick={(e) => {
                                      setContact(handleCreatedByUsers(item?.createdBy));
                                      setContactAnchor(e.currentTarget);
                                    }}>
                                    {item?.createdBy && handleCreatedByUsers(item?.createdBy)
                                      ? getInitials(handleCreatedByUsers(item?.createdBy))
                                      : 'N/A'}
                                  </Avatar>
                                )}

                                {/* {handleCreatedByUsers(candidate.createdBy)?.profilePicture !==
                                      null ||
                                    handleCreatedByUsers(candidate.createdBy)?.profilePicture >
                                      0 ? (
                                      <img
                                        className='user-icon'
                                        src={
                                          handleCreatedByUsers(candidate.createdBy)?.profilePicture
                                        }
                                      />
                                    ) : (
                                      <div
                                        className='user-icon'
                                        style={{
                                          backgroundColor: 'rgb(25, 118, 210)',
                                          color: 'white',
                                        }}>
                                        {getInitials(handleCreatedByUsers(candidate.createdBy))}
                                      </div>
                                    )} 
                              </div> */}
                            </TableCell>
                            <TableCell>
                              <IconButton
                                onClick={(e) => {
                                  setSelectedClient(item);
                                  // setSelectRowClientName(item.clientId);
                                  showActionsList(e);
                                }}>
                                <MoreIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ padding: 0 }} colSpan={6}>
                              <Collapse in={subTableFlag == index}>
                                <SubTable index={index} />
                              </Collapse>
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={CLIENT_HEADERS.length + 1} sx={{ textAlign: 'center' }}>
                          No Records To Display
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                        count={rowData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                          inputProps: {
                            'aria-label': 'rows per page',
                          },
                          native: true,
                        }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        className='pagination'
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
              <ContactCard
                contact={contact}
                anchorEl={contactAnchor}
                onClose={() => {
                  setContactAnchor(null);
                  setContact(null);
                }}
              />

              <Notify
                open={showToast}
                severity={toastMsg.severity}
                onClose={handleToastClose}
                message={toastMsg.msg}></Notify>
              {/* {openDeleteDialog && renderDialog()} */}
            </div>
          ) : null}
        </Box>
      </Paper>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeActionsMenu}
        slotProps={{
          paper: {
            sx: {
              boxShadow:
                '0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)',
            },
          },
        }}>
        {actionsList.map((action, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              action.action();
            }}>
            <ListItemIcon>
              <img src={action.icon} alt={action.name} style={{ width: '20px', height: '20px' }} />
            </ListItemIcon>
            <ListItemText primary={action.name} />
          </MenuItem>
        ))}
      </Menu>
      {/* <Dialog open={projectAddDialogOpen} onClose={() => setProjectAddDialogOpen(false)}>
        <form onSubmit={formik.handleSubmit} style={{ width: '350px' }}>
          <DialogTitle>Add Project to the Client</DialogTitle>
          <DialogContent>
            <Typography>Client Name</Typography>
            <FormControl variant='standard' fullWidth sx={{ marginBottom: '20px' }}>
              <TextField
                id='clientName'
                value={getNameFromId(selectRowClientName, LookupTypes.TAD_CLIENT) || ''}
                // disabled
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
              />
            </FormControl>

            <Typography>Project Name *</Typography>
            <FormControl
              error={!formik.errors.projectName}
              variant='standard'
              fullWidth
              sx={{ marginBottom: '20px' }}>
              <TextField
                id='projectName'
                placeholder='Enter Name....'
                name='projectName'
                className='input-field'
                value={formik.values.projectName}
                onChange={(e) => formik.setFieldValue('projectName', e.target.value)}
                onBlur={formik.handleBlur}></TextField>
              <div className='form-err-message'>{getError(formik, 'projectName')}</div>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setProjectAddDialogOpen(false)} color='secondary'>
              Cancel
            </Button>
            <Button type='submit' color='primary' variant='contained'>
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog> */}
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogContent>
          <DialogContentText>Are you sure you want to delete this client?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)}>No</Button>
          <Button onClick={confirmDelete}>Yes</Button>
        </DialogActions>
      </Dialog>

      {loading && (
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
          <CircularProgress color='inherit' />
        </Backdrop>
      )}
    </div>
  );
};

export default Approvals;
