import React, { useState } from 'react';
import {
  Paper,
  Typography,
  TextField,
  Button,
  IconButton,
  Autocomplete,
  Tab,
  Tabs,
} from '@mui/material';
import { FormikProps, useFormik } from 'formik';
import * as Yup from 'yup';
import globalObject from '../../../../common/global-variables';
import { LookupTypes } from '../../../../common/lookupTypes';
// import { getTadSkills } from '../../../../service/lookupService';
import { ReactComponent as MoreIcon } from '../../../assets/dashboard-assets/more_vert.svg';
import DeleteIcon from '@mui/icons-material/Delete'; // Import the delete icon
import '../../source-job-definition/job-definition-details.scss';
import { getLabel } from '../../../common/commonUtil';
import { getIdFromName, getNameFromId } from '../../../../common/commonUtil';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import useNavigate from '../../../../common/useNavigate';
import {
  TadCandidateCertifications,
  TadCandidateDetailsModel,
  TadCandidateEducations,
} from '../../../models/tad-candidate-form-model';
import dateFormat from 'dateformat';
import LocationAutoComplete from '../../../../components/common/location-autocomplete';

interface TadEducationCertificationsProps {
  mode?: 'edit-page' | 'details-page';
  next?: (value: TadCandidateDetailsModel) => void;
  values: TadCandidateDetailsModel;
  previous?: () => void;
}

interface OptionType {
  id: number;
  name: string;
}

const EducationCertificationDetails: React.FC<TadEducationCertificationsProps> = ({
  values,
  mode = 'edit-page',
  next = () => {},
  previous = () => {},
}: TadEducationCertificationsProps) => {
  const [submitted, setSubmitted] = useState(false);
  const [edit, setEdit] = useState(mode === 'edit-page');
  const [navigate] = useNavigate();
  const [type, setType] = useState('');

  type subTabType = 'Education' | 'Certification';
  const [selectedSubType, setSelectedSubType] = useState<subTabType>('Education');

  const handleNextClick = async () => {
    if (educationFormik.values.educations.length === 0) {
      educationFormik.setTouched({
        educations: educationFormik.values.educations.map(() => ({
          studyType: true,
          institution: true,
          degreeReceived: true,
          specialization: true,
          startDate: true,
          endDate: true,
          grade: true,
        })),
      });
      return;
    }

    educationFormik.setFieldValue(
      'educations',
      educationFormik.values.educations.map((education) => {
        const startDate = dayjs(education.startDate);
        const endDate = dayjs(education.endDate);

        return {
          ...education,
          startDate: startDate.isValid() ? startDate.toDate() : null,
          endDate: endDate.isValid() ? endDate.toDate() : null,
        };
      }),
    );

    certificationFormik.setFieldValue(
      'certifications',
      certificationFormik.values.certifications.map((certification) => {
        const startDate = dayjs(certification.startDate);
        const endDate = dayjs(certification.endDate);

        return {
          ...certification,
          startDate: startDate.isValid() ? startDate.toDate() : null,
          endDate: endDate.isValid() ? endDate.toDate() : null,
        };
      }),
    );

    const educationErrors = await educationFormik.validateForm();
    const certificationErrors = await certificationFormik.validateForm();
    educationFormik.setTouched({
      educations: educationFormik.values.educations.map(() => ({
        studyType: true,
        institution: true,
        degreeReceived: true,
        specialization: true,
        startDate: true,
        endDate: true,
        location: true,
        grade: true,
      })),
    });

    certificationFormik.setTouched({
      certifications: certificationFormik.values.certifications.map(() => ({
        studyType: true,
        issuedBy: true,
        certificationName: true,
        startDate: true,
        endDate: true,
        specialization: true,
        expiry: true,
      })),
    });
    setSubmitted(true);
    if (
      Object.keys(educationErrors).length === 0 &&
      Object.keys(certificationErrors).length === 0
    ) {
      console.log(
        'Candidate educations => ',
        educationFormik.values.educations,
        certificationFormik.values.certifications,
      );
      next({
        educationDetails: educationFormik.values.educations as TadCandidateEducations[],
        certificationDetails: certificationFormik.values
          .certifications as TadCandidateCertifications[],
      });
    }
  };

  const candidateEducationSchema = Yup.object().shape({
    educations: Yup.array().of(
      Yup.object().shape({
        studyType: Yup.number()
          .integer('The value must be a number')
          .typeError('The value must be a number')
          .min(0, 'Value must be positive'),
        institution: Yup.string().required('School / Institution Name is required'),
        degreeReceived: Yup.number()
          .integer('The value must be a number')
          .typeError('The value must be a number')
          .min(0, 'Value must be positive'),
        specialization: Yup.number()
          .integer('The value must be a number')
          .typeError('The value must be a number')
          .min(0, 'Value must be positive'),
        startDate: Yup.date().nullable(),
        endDate: Yup.date()
          .nullable()
          .test('is-different', 'Start date and end date cannot be the same.', function (endDate) {
            const { startDate } = this.parent;
            return !dayjs(startDate).isSame(dayjs(endDate), 'day');
          })
          .min(Yup.ref('startDate'), 'End Date cant be before Start Date'),
        location: Yup.string().max(1000, 'Location should not exceed 1000 characters'),
        grade: Yup.number()
          .nullable()
          .typeError('The value must be a number')
          .min(0, 'Grade cannot be less than 0')
          .max(10, 'Grade cannot be more than 10'),
      }),
    ),
  });

  const candidateCertificationSchema = Yup.object().shape({
    certifications: Yup.array().of(
      Yup.object().shape({
        studyType: Yup.number()
          .integer('The value must be a number')
          .typeError('The value must be a number')
          .min(0, 'Value must be positive'),
        issuedBy: Yup.string(),
        certificationName: Yup.string().required('Certification Name is required'),
        startDate: Yup.date().nullable(),
        endDate: Yup.date()
          .nullable()
          .test('is-different', 'Start date and end date cannot be the same.', function (endDate) {
            const { startDate } = this.parent;
            return !dayjs(startDate).isSame(dayjs(endDate), 'day');
          })
          .min(Yup.ref('startDate'), `End Date can'${''}t be before Start Date`),
        specialization: Yup.number()
          .integer('The value must be a number')
          .typeError('The value must be a number')
          .min(0, 'Value must be positive'),
        expiry: Yup.boolean(),
      }),
    ),
  });

  const educationFormik = useFormik({
    initialValues: {
      educations: values.educationDetails || [
        {
          studyType: '',
          institution: '',
          degreeReceived: null,
          specialization: null,
          startDate: '',
          endDate: '',
          location: '',
          grade: '',
        },
      ],
    },
    validationSchema: candidateEducationSchema,
    onSubmit: (_values) => {
      educationFormik.resetForm();
      setSubmitted(false);
    },
  });

  const certificationFormik = useFormik({
    initialValues: {
      certifications: values.certificationDetails || [
        {
          studyType: '',
          issuedBy: '',
          certificationName: '',
          startDate: '',
          endDate: '',
          specialization: 0,
          expiry: false,
        },
      ],
    },
    validationSchema: candidateCertificationSchema,
    onSubmit: (_values) => {
      certificationFormik.resetForm();
      setSubmitted(false);
    },
  });

  const setLocationCallback = (str?: string, index?: any) => {
    educationFormik.setFieldValue(`educations[${index}].location`, str);
  };

  function getError(formik: FormikProps<any>, fieldName: string) {
    const { touched, error } = formik.getFieldMeta(fieldName);

    if ((touched && error) || submitted) {
      return error ? error.toString() : null;
    }
    return null;
  }

  const handleAddEducation = () => {
    educationFormik.setFieldValue('educations', [
      ...educationFormik.values.educations,
      {
        studyType: '',
        institution: '',
        degreeReceived: null,
        specialization: null,
        startDate: '',
        endDate: '',
        grade: '',
      },
    ]);
  };

  const handleAddCertifications = () => {
    certificationFormik.setFieldValue('certifications', [
      ...certificationFormik.values.certifications,
      {
        studyType: '',
        issuedBy: '',
        certificationName: '',
        startDate: '',
        endDate: '',
        specialization: 0,
        expiry: false,
      },
    ]);
  };

  const handleRemoveEducation = (index: number) => {
    const educations = (educationFormik.values.educations || []) as TadCandidateEducations[];

    const updatedExperiences = educations.filter(
      (_: TadCandidateEducations, i: number) => i !== index,
    );
    educationFormik.setFieldValue('educations', updatedExperiences);
  };

  const handleRemoveCertification = (index: number) => {
    const certifications = (certificationFormik.values.certifications ||
      []) as TadCandidateCertifications[];
    const updatedExperiences = certifications.filter(
      (_: TadCandidateCertifications, i: number) => i !== index,
    );
    certificationFormik.setFieldValue('certifications', updatedExperiences);
  };

  return (
    <>
      <div style={{ height: '100%', width: '100%' }}>
        <Paper className='paper' sx={{ width: '100%' }}>
          {mode === 'details-page' && (
            <div className='header-container'>
              <div className='header-content-title'>
                <span>Education & Certifications</span>
              </div>
              <div className='header-content-icon'>
                <IconButton
                  onClick={() => {
                    edit == false ? setEdit(true) : setEdit(false);
                  }}>
                  <MoreIcon />
                </IconButton>
              </div>
            </div>
          )}

          {
            <>
              <Tabs
                className='candidate-tabs'
                sx={{
                  borderBottom: '1px solid #A2A2A2',
                  marginTop: '2rem',
                  color: '#1976D2 !important',
                }}
                value={selectedSubType}
                onChange={(_e, v) => {
                  setSelectedSubType(v);
                }}>
                <Tab
                  sx={{ marginLeft: '2rem' }}
                  className='candidate-tab'
                  label={'Educations'}
                  value={'Education'}
                />
                <Tab className='candidate-tab' label={'Certifications'} value={'Certification'} />
              </Tabs>
              <div style={{ padding: '2.69rem 3rem 2.19rem 3rem' }}>
                {selectedSubType === 'Education' ? (
                  <>
                    {educationFormik.values.educations.map((experience, index) => (
                      <div key={index}>
                        {edit && (
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                              marginBottom: '1rem',
                            }}>
                            <IconButton
                              onClick={() =>
                                type !== 'Certificates'
                                  ? handleRemoveEducation(index)
                                  : handleRemoveCertification(index)
                              }
                              sx={{ color: 'var(--Hyperlink, var(--Primary-Blue-500, #1976D2))' }}>
                              <DeleteIcon />
                              <span
                                style={{
                                  fontFamily: 'Roboto',
                                  fontSize: '1rem',
                                  fontStyle: 'normal',
                                  fontWeight: 400,
                                  lineHeight: '1.5625rem',
                                  textDecoration: 'underline',
                                  marginLeft: '0.5rem',
                                }}>
                                Remove
                              </span>
                            </IconButton>
                          </div>
                        )}

                        {
                          <div className='field-container'>
                            {mode === 'edit-page' ? (
                              <div style={{ width: '50%' }}>
                                <Typography sx={{ fontWeight: 600 }}>Type of Study</Typography>
                              </div>
                            ) : (
                              <div style={{ width: '35%' }}>
                                <Typography sx={{ fontWeight: 600 }}>Type of Study:</Typography>
                              </div>
                            )}

                            {edit && (
                              <div style={{ width: '50%' }}>
                                <Autocomplete
                                  fullWidth
                                  className='visaType'
                                  id='combo-box-demo'
                                  options={
                                    globalObject.lookupvalues[LookupTypes.TAD_TYPE_OF_STUDY].filter(
                                      (lookup: any) =>
                                        lookup.id !=
                                        getIdFromName(
                                          'Certificates',
                                          LookupTypes.TAD_TYPE_OF_STUDY,
                                        ),
                                    ) as OptionType[]
                                  }
                                  value={
                                    globalObject.lookupvalues[LookupTypes.TAD_TYPE_OF_STUDY].find(
                                      (option: any) => option.id === experience.studyType,
                                    ) || null
                                  }
                                  size='small'
                                  sx={{
                                    background: 'var(--Secondary-colors-White, #FFF)',
                                    border: '1px solid var(--Secondary-colors-Grey, #868686)',
                                    borderRadius: '0.3125rem',
                                  }}
                                  limitTags={1}
                                  getOptionLabel={(option: any) => option.name}
                                  filterSelectedOptions
                                  renderInput={(params) => (
                                    <TextField placeholder='Choose...' {...params} label='' />
                                  )}
                                  onChange={(_e, val) => {
                                    educationFormik.setFieldValue(
                                      `educations[${index}].studyType`,
                                      val ? val?.id : undefined,
                                    );
                                    setType(val.name);
                                  }}
                                />
                                <div className='mt-0 form-err-message'>
                                  {getError(educationFormik, `educations[${index}].studyType`)}
                                </div>
                              </div>
                            )}
                          </div>
                        }

                        {
                          <div className='field-container'>
                            {mode === 'edit-page' ? (
                              <div style={{ width: '50%' }}>
                                <Typography sx={{ fontWeight: 600 }}>
                                  School / Institution Name *
                                </Typography>
                                <span className='feildHeader'>
                                  Provide the Name of the Institution
                                </span>
                              </div>
                            ) : (
                              <div style={{ width: '35%' }}>
                                <Typography sx={{ fontWeight: 600 }}>
                                  School / Institution Name
                                </Typography>
                              </div>
                            )}
                            {edit ? (
                              <div style={{ width: '50%' }}>
                                <TextField
                                  className='input-field'
                                  fullWidth
                                  value={experience.institution}
                                  onChange={(e) => {
                                    educationFormik.setFieldValue(
                                      `educations[${index}].institution`,
                                      e.target.value,
                                    );
                                  }}
                                  onBlur={educationFormik.handleBlur}
                                  error={Boolean(
                                    getError(educationFormik, `educations[${index}].institution`),
                                  )}
                                />
                                <div className='mt-0 form-err-message'>
                                  {getError(educationFormik, `educations[${index}].institution`)}
                                </div>
                              </div>
                            ) : (
                              <span>{getLabel(experience.institution)}</span>
                            )}
                          </div>
                        }

                        {
                          <div className='field-container'>
                            {mode === 'edit-page' ? (
                              <div style={{ width: '50%' }}>
                                <Typography sx={{ fontWeight: 600 }}>Degree Received</Typography>
                                <span className='feildHeader'>Enter the Awarded Degree</span>
                              </div>
                            ) : (
                              <div style={{ width: '35%' }}>
                                <Typography sx={{ fontWeight: 600 }}>Degree Received:</Typography>
                              </div>
                            )}
                            {edit ? (
                              <div style={{ width: '50%' }}>
                                <Autocomplete
                                  fullWidth
                                  className='visaType'
                                  id='combo-box-demo'
                                  options={
                                    globalObject.lookupvalues[
                                      LookupTypes.TAD_CANDIDATE_DEGREE_NAMES
                                    ] as OptionType[]
                                  }
                                  value={
                                    globalObject.lookupvalues[
                                      LookupTypes.TAD_CANDIDATE_DEGREE_NAMES
                                    ].find(
                                      (option: any) => option.id === experience.degreeReceived,
                                    ) || null
                                  }
                                  size='small'
                                  sx={{
                                    background: 'var(--Secondary-colors-White, #FFF)',
                                    border: '1px solid var(--Secondary-colors-Grey, #868686)',
                                    borderRadius: '0.3125rem',
                                  }}
                                  limitTags={1}
                                  getOptionLabel={(option: any) => option.name}
                                  filterSelectedOptions
                                  renderInput={(params) => (
                                    <TextField
                                      placeholder='Choose...'
                                      {...params}
                                      error={Boolean(
                                        getError(
                                          educationFormik,
                                          `educations[${index}].degreeReceived`,
                                        ),
                                      )}
                                      label=''
                                    />
                                  )}
                                  onChange={(_e, val) => {
                                    educationFormik.setFieldValue(
                                      `educations[${index}].degreeReceived`,
                                      val ? val?.id : undefined,
                                    );
                                  }}
                                  onBlur={() =>
                                    educationFormik.setFieldTouched(
                                      `educations[${index}].degreeReceived`,
                                      true,
                                      true,
                                    )
                                  }
                                />
                                <div className='mt-0 form-err-message'>
                                  {getError(educationFormik, `educations[${index}].degreeReceived`)}
                                </div>
                              </div>
                            ) : (
                              <span>
                                {getNameFromId(
                                  Number(experience.degreeReceived),
                                  LookupTypes.TAD_DOMAIN,
                                )}
                              </span>
                            )}
                          </div>
                        }

                        {
                          <div className='field-container'>
                            {mode === 'edit-page' ? (
                              <div style={{ width: '50%' }}>
                                <Typography sx={{ fontWeight: 600 }}>Specialization</Typography>
                              </div>
                            ) : (
                              <div style={{ width: '35%' }}>
                                <Typography sx={{ fontWeight: 600 }}>Specialization:</Typography>
                              </div>
                            )}
                            {edit ? (
                              <div style={{ width: '50%' }}>
                                <Autocomplete
                                  fullWidth
                                  className='visaType'
                                  id='combo-box-demo'
                                  options={
                                    globalObject.lookupvalues[
                                      LookupTypes.TAD_CANDIDATE_DEGREE_SPECIALIZATIONS
                                    ] as OptionType[]
                                  }
                                  value={
                                    globalObject.lookupvalues[
                                      LookupTypes.TAD_CANDIDATE_DEGREE_SPECIALIZATIONS
                                    ].find(
                                      (option: any) => option.id === experience.specialization,
                                    ) || null
                                  }
                                  size='small'
                                  sx={{
                                    background: 'var(--Secondary-colors-White, #FFF)',
                                    border: '1px solid var(--Secondary-colors-Grey, #868686)',
                                    borderRadius: '0.3125rem',
                                  }}
                                  limitTags={1}
                                  getOptionLabel={(option: any) => option.name}
                                  filterSelectedOptions
                                  renderInput={(params) => (
                                    <TextField
                                      placeholder='Choose...'
                                      {...params}
                                      error={Boolean(
                                        getError(
                                          educationFormik,
                                          `educations[${index}].specialization`,
                                        ),
                                      )}
                                      label=''
                                    />
                                  )}
                                  onChange={(_e, val) => {
                                    educationFormik.setFieldValue(
                                      `educations[${index}].specialization`,
                                      val ? val.id : undefined,
                                    );
                                  }}
                                />
                                <div className='mt-0 form-err-message'>
                                  {getError(educationFormik, `educations[${index}].specialization`)}
                                </div>
                              </div>
                            ) : (
                              <span>
                                {getNameFromId(
                                  experience.specialization ?? undefined,
                                  LookupTypes.TAD_DOMAIN,
                                )}
                              </span>
                            )}
                          </div>
                        }

                        {
                          <div className='field-container'>
                            {mode === 'edit-page' ? (
                              <div style={{ width: '50%' }}>
                                <Typography sx={{ fontWeight: 600 }}>
                                  Start Date & End Date
                                </Typography>
                              </div>
                            ) : (
                              <div style={{ width: '35%' }}>
                                <Typography sx={{ fontWeight: 600 }}>
                                  Start Date & End Date
                                </Typography>
                              </div>
                            )}
                            {edit ? (
                              <div style={{ display: 'flex', gap: '1.88rem', width: '50%' }}>
                                <div>
                                  <DatePicker
                                    className='date-picker'
                                    format='DD/MM/YYYY'
                                    disableFuture
                                    value={
                                      experience.startDate ? dayjs(experience.startDate) : null
                                    }
                                    onChange={(e) =>
                                      educationFormik.setFieldValue(
                                        `educations[${index}].startDate`,
                                        dateFormat(e?.toString(), 'isoDateTime'),
                                        true,
                                      )
                                    }
                                  />
                                  <div className='mt-0 form-err-msg' style={{ color: '#de445d' }}>
                                    {getError(educationFormik, `educations[${index}].startDate`)}
                                  </div>
                                </div>
                                <div>
                                  <DatePicker
                                    className='date-picker'
                                    format='DD/MM/YYYY'
                                    value={experience.endDate ? dayjs(experience.endDate) : null}
                                    onChange={(e) =>
                                      educationFormik.setFieldValue(
                                        `educations[${index}].endDate`,
                                        dateFormat(e?.toString(), 'isoDateTime'),
                                        true,
                                      )
                                    }
                                  />
                                  <div className='mt-0 form-err-msg' style={{ color: '#de445d' }}>
                                    {getError(educationFormik, `educations[${index}].endDate`)}
                                  </div>{' '}
                                </div>
                              </div>
                            ) : (
                              <span style={{ overflowWrap: 'anywhere' }}>
                                {experience.startDate &&
                                  dateFormat(new Date(experience.startDate), 'dd/mm/yyyy')}{' '}
                                -
                                {experience.endDate &&
                                  dateFormat(new Date(experience.endDate), 'dd/mm/yyyy')}
                              </span>
                            )}
                          </div>
                        }

                        {
                          <div className='field-container'>
                            {mode === 'edit-page' ? (
                              <div style={{ width: '50%' }}>
                                <Typography sx={{ fontWeight: 600 }}>Location</Typography>
                              </div>
                            ) : (
                              <div style={{ width: '35%' }}>
                                <Typography sx={{ fontWeight: 600 }}>Location</Typography>
                              </div>
                            )}
                            {edit ? (
                              <div style={{ width: '50%' }}>
                                <LocationAutoComplete
                                  onSelect={(value) => setLocationCallback(value, index)}
                                  initialValue={experience.location}></LocationAutoComplete>
                                <div className='mt-0 form-err-message'>
                                  {getError(educationFormik, `educations[${index}].location`)}
                                </div>
                              </div>
                            ) : (
                              <span>{getLabel(experience.location)}</span>
                            )}
                          </div>
                        }

                        {
                          <div className='field-container'>
                            {mode === 'edit-page' ? (
                              <div style={{ width: '50%' }}>
                                <Typography sx={{ fontWeight: 600 }}>Grade</Typography>
                              </div>
                            ) : (
                              <div style={{ width: '35%' }}>
                                <Typography sx={{ fontWeight: 600 }}>Grade</Typography>
                              </div>
                            )}

                            <div style={{ width: '50%' }}>
                              <TextField
                                className='input-field'
                                fullWidth
                                value={experience.grade}
                                onChange={(e) => {
                                  educationFormik.setFieldValue(
                                    `educations[${index}].grade`,
                                    e.target.value,
                                  );
                                }}
                                onBlur={educationFormik.handleBlur}
                                error={Boolean(
                                  getError(educationFormik, `educations[${index}].grade`),
                                )}
                              />
                              <div className='mt-0 form-err-message' style={{ color: '#de445d' }}>
                                {getError(educationFormik, `educations[${index}].grade`)}
                              </div>
                            </div>
                          </div>
                        }
                        <hr style={{ background: '#868686' }} />
                      </div>
                    ))}
                    <Button onClick={handleAddEducation} color='primary'>
                      + Add Another
                    </Button>
                    <div>
                      <span
                        style={{
                          display:
                            educationFormik.values.educations.length === 0 ? 'inline' : 'none',
                          color: 'red',
                        }}>
                        Please fill the education details before proceeding.
                      </span>
                    </div>
                  </>
                ) : (
                  <>
                    {certificationFormik.values.certifications.map((certification, index) => (
                      <div key={index}>
                        {edit && (
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                              marginBottom: '1rem',
                            }}>
                            <IconButton
                              onClick={() => handleRemoveCertification(index)}
                              sx={{ color: 'var(--Hyperlink, var(--Primary-Blue-500, #1976D2))' }}>
                              <DeleteIcon />
                              <span
                                style={{
                                  fontFamily: 'Roboto',
                                  fontSize: '1rem',
                                  fontStyle: 'normal',
                                  fontWeight: 400,
                                  lineHeight: '1.5625rem',
                                  textDecoration: 'underline',
                                  marginLeft: '0.5rem', // Adds space between icon and text
                                }}>
                                Remove
                              </span>
                            </IconButton>
                          </div>
                        )}
                        {
                          <div className='field-container'>
                            {mode === 'edit-page' ? (
                              <div style={{ width: '50%' }}>
                                <Typography sx={{ fontWeight: 600 }}>Type of Study</Typography>
                              </div>
                            ) : (
                              <div style={{ width: '35%' }}>
                                <Typography sx={{ fontWeight: 600 }}>Type of Study:</Typography>
                              </div>
                            )}

                            {edit ? (
                              <div style={{ width: '50%' }}>
                                <Autocomplete
                                  fullWidth
                                  className='visaType'
                                  id='combo-box-demo'
                                  options={
                                    globalObject.lookupvalues[LookupTypes.TAD_TYPE_OF_STUDY].filter(
                                      (lookup: any) =>
                                        lookup.id ===
                                        getIdFromName(
                                          'Certificates',
                                          LookupTypes.TAD_TYPE_OF_STUDY,
                                        ),
                                    ) as OptionType[]
                                  }
                                  value={
                                    globalObject.lookupvalues[LookupTypes.TAD_TYPE_OF_STUDY].find(
                                      (option: any) => option.id === certification.studyType,
                                    ) || null
                                  }
                                  size='small'
                                  sx={{
                                    background: 'var(--Secondary-colors-White, #FFF)',
                                    border: '1px solid var(--Secondary-colors-Grey, #868686)',
                                    borderRadius: '0.3125rem',
                                  }}
                                  limitTags={1}
                                  getOptionLabel={(option: any) => option.name}
                                  filterSelectedOptions
                                  renderInput={(params) => (
                                    <TextField placeholder='Choose...' {...params} label='' />
                                  )}
                                  onChange={(_e, val) => {
                                    certificationFormik.setFieldValue(
                                      `certifications[${index}].studyType`,
                                      val ? val?.id : undefined,
                                    );
                                    setType(val.name);
                                  }}
                                />
                                <div className='mt-0 form-err-message'>
                                  {getError(
                                    certificationFormik,
                                    `certifications[${index}].studyType`,
                                  )}
                                </div>
                              </div>
                            ) : (
                              <span>
                                {getNameFromId(
                                  Number(certification.studyType),
                                  LookupTypes.TAD_TYPE_OF_STUDY,
                                )}
                              </span>
                            )}
                          </div>
                        }

                        {
                          <div className='field-container'>
                            {mode === 'edit-page' ? (
                              <div style={{ width: '50%' }}>
                                <Typography sx={{ fontWeight: 600 }}>Issued By</Typography>
                                <span className='feildHeader'>
                                  Provide the Name of the Institution
                                </span>
                              </div>
                            ) : (
                              <div style={{ width: '35%' }}>
                                <Typography sx={{ fontWeight: 600 }}> Issued By</Typography>
                              </div>
                            )}
                            {edit ? (
                              <div style={{ width: '50%' }}>
                                <TextField
                                  className='input-field'
                                  fullWidth
                                  value={certification.issuedBy}
                                  onChange={(e) => {
                                    certificationFormik.setFieldValue(
                                      `certifications[${index}].issuedBy`,
                                      e.target.value,
                                    );
                                  }}
                                  onBlur={certificationFormik.handleBlur}
                                  error={Boolean(
                                    getError(
                                      certificationFormik,
                                      `certifications[${index}].issuedBy`,
                                    ),
                                  )}
                                />
                                <div className='mt-0 form-err-message'>
                                  {getError(
                                    certificationFormik,
                                    `certifications[${index}].issuedBy`,
                                  )}
                                </div>
                              </div>
                            ) : (
                              <span>{getLabel(certification.issuedBy)}</span>
                            )}
                          </div>
                        }

                        {
                          <div className='field-container'>
                            {mode === 'edit-page' ? (
                              <div style={{ width: '50%' }}>
                                <Typography sx={{ fontWeight: 600 }}>
                                  Certification Name *
                                </Typography>
                                <span className='feildHeader'>
                                  Enter the awarded certification name
                                </span>
                              </div>
                            ) : (
                              <div style={{ width: '35%' }}>
                                <Typography sx={{ fontWeight: 600 }}>
                                  Certification Name:
                                </Typography>
                              </div>
                            )}
                            {edit ? (
                              <div style={{ width: '50%' }}>
                                <TextField
                                  className='input-field'
                                  fullWidth
                                  value={certification.certificationName}
                                  onChange={(e) => {
                                    certificationFormik.setFieldValue(
                                      `certifications[${index}].certificationName`,
                                      e.target.value,
                                    );
                                  }}
                                  onBlur={certificationFormik.handleBlur}
                                  error={Boolean(
                                    getError(
                                      certificationFormik,
                                      `certifications[${index}].certificationName`,
                                    ),
                                  )}
                                />
                                <div className='mt-0 form-err-message'>
                                  {getError(
                                    certificationFormik,
                                    `certifications[${index}].certificationName`,
                                  )}
                                </div>
                              </div>
                            ) : (
                              <span>
                                {getNameFromId(
                                  Number(certification.certificationName),
                                  LookupTypes.TAD_DOMAIN,
                                )}
                              </span>
                            )}
                          </div>
                        }

                        {
                          <div className='field-container'>
                            {mode === 'edit-page' ? (
                              <div style={{ width: '50%' }}>
                                <Typography sx={{ fontWeight: 600 }}>Specialization</Typography>
                              </div>
                            ) : (
                              <div style={{ width: '35%' }}>
                                <Typography sx={{ fontWeight: 600 }}>Specialization:</Typography>
                              </div>
                            )}
                            {edit ? (
                              <div style={{ width: '50%' }}>
                                <Autocomplete
                                  fullWidth
                                  className='visaType'
                                  id='combo-box-demo'
                                  options={
                                    globalObject.lookupvalues[
                                      LookupTypes.TAD_CANDIDATE_CERTIFICATION_SPECIALIZATIONS
                                    ] as OptionType[]
                                  }
                                  value={
                                    globalObject.lookupvalues[
                                      LookupTypes.TAD_CANDIDATE_CERTIFICATION_SPECIALIZATIONS
                                    ].find(
                                      (option: any) => option.id === certification.specialization,
                                    ) || null
                                  }
                                  size='small'
                                  sx={{
                                    background: 'var(--Secondary-colors-White, #FFF)',
                                    border: '1px solid var(--Secondary-colors-Grey, #868686)',
                                    borderRadius: '0.3125rem',
                                  }}
                                  limitTags={1}
                                  getOptionLabel={(option: any) => option.name}
                                  filterSelectedOptions
                                  renderInput={(params) => (
                                    <TextField
                                      placeholder='Choose...'
                                      {...params}
                                      error={Boolean(
                                        getError(
                                          certificationFormik,
                                          `certifications[${index}].specialization`,
                                        ),
                                      )}
                                      label=''
                                    />
                                  )}
                                  onChange={(_e, val) => {
                                    certificationFormik.setFieldValue(
                                      `certifications[${index}].specialization`,
                                      val ? val.id : undefined,
                                    );
                                  }}
                                />
                                <div className='mt-0 form-err-message'>
                                  {getError(
                                    certificationFormik,
                                    `certifications[${index}].specialization`,
                                  )}
                                </div>
                              </div>
                            ) : (
                              <span>
                                {getNameFromId(
                                  certification.specialization,
                                  LookupTypes.TAD_CANDIDATE_CERTIFICATION_SPECIALIZATIONS,
                                )}
                              </span>
                            )}
                          </div>
                        }

                        {
                          <div className='field-container'>
                            {mode === 'edit-page' ? (
                              <div style={{ width: '50%' }}>
                                <Typography sx={{ fontWeight: 600 }}>
                                  Start Date & End Date
                                </Typography>
                              </div>
                            ) : (
                              <div style={{ width: '35%' }}>
                                <Typography sx={{ fontWeight: 600 }}>
                                  Start Date & End Date
                                </Typography>
                              </div>
                            )}
                            {edit ? (
                              <div style={{ display: 'flex', gap: '1.88rem', width: '50%' }}>
                                <div>
                                  <DatePicker
                                    className='date-picker'
                                    format='DD/MM/YYYY'
                                    disableFuture
                                    value={
                                      certification.startDate
                                        ? dayjs(certification.startDate)
                                        : null
                                    }
                                    onChange={(e) =>
                                      certificationFormik.setFieldValue(
                                        `certifications[${index}].startDate`,
                                        dateFormat(e?.toString(), 'isoDateTime'),
                                        true,
                                      )
                                    }
                                  />
                                  <div className='mt-0 form-err-msg' style={{ color: '#de445d' }}>
                                    {getError(
                                      certificationFormik,
                                      `certifications[${index}].startDate`,
                                    )}
                                  </div>
                                </div>
                                <div>
                                  <DatePicker
                                    className='date-picker'
                                    format='DD/MM/YYYY'
                                    value={
                                      certification.endDate ? dayjs(certification.endDate) : null
                                    }
                                    onChange={(e) =>
                                      certificationFormik.setFieldValue(
                                        `certifications[${index}].endDate`,
                                        dateFormat(e?.toString(), 'isoDateTime'),
                                        true,
                                      )
                                    }
                                  />
                                  <div className='mt-0 form-err-msg' style={{ color: '#de445d' }}>
                                    {getError(
                                      certificationFormik,
                                      `certifications[${index}].endDate`,
                                    )}
                                  </div>{' '}
                                </div>
                              </div>
                            ) : (
                              <span style={{ overflowWrap: 'anywhere' }}>
                                {certification.startDate &&
                                  dateFormat(new Date(certification.startDate), 'dd/mm/yyyy')}{' '}
                                -
                                {certification.endDate &&
                                  dateFormat(new Date(certification.endDate), 'dd/mm/yyyy')}
                              </span>
                            )}
                          </div>
                        }
                        {
                          <div className='field-container'>
                            {mode === 'edit-page' ? (
                              <div style={{ width: '50%' }}>
                                <Typography sx={{ fontWeight: 600 }}>Expiry Date</Typography>
                              </div>
                            ) : (
                              <div style={{ width: '35%' }}>
                                <Typography sx={{ fontWeight: 600 }}>Expiry Date:</Typography>
                              </div>
                            )}

                            <div
                              style={{
                                width: '50%',
                                display: 'flex',
                                gap: '1rem',
                              }}>
                              <input
                                type='checkbox'
                                checked={certification.expiry}
                                onChange={(e) => {
                                  certificationFormik.setFieldValue(
                                    `certifications[${index}].expiry`,
                                    e.target.checked,
                                  );
                                }}
                              />
                              <span>No Expiry</span>
                            </div>
                          </div>
                        }
                        {<hr style={{ background: '#868686' }} />}
                      </div>
                    ))}
                    <Button onClick={handleAddCertifications} color='primary'>
                      + Add Another
                    </Button>
                  </>
                )}
              </div>
            </>
          }

          <div style={{ padding: '1.87rem 1.88rem', borderTop: '1px solid #868686' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Button
                className='button'
                onClick={() => {
                  navigate([
                    {
                      label: 'Candidate',
                      link: '/candidate',
                    },
                  ]);
                }}
                sx={{
                  width: '7.375rem',
                  height: '2.5rem',
                  borderRadius: '5px',
                  border: '1px solid #2D7DFE',
                  backgroundColor: '#FFF',
                  color: 'black',
                  textTransform: 'none',
                  fontSize: '1rem',
                  fontWeight: 400,
                }}>
                Cancel
              </Button>

              <div style={{ display: 'flex', gap: '15px' }}>
                <Button
                  className='button'
                  onClick={previous}
                  sx={{
                    width: '7.375rem',
                    height: '2.5rem',
                    borderRadius: '5px',
                    border: '1px solid #2D7DFE',
                    backgroundColor: '#FFF',
                    color: 'black',
                    textTransform: 'none',
                    fontSize: '1rem',
                    fontWeight: 400,
                  }}>
                  Previous
                </Button>
                <Button
                  className='button'
                  onClick={() => {
                    handleNextClick();
                  }}
                  sx={{
                    textTransform: 'none',
                    fontWeight: 400,
                    fontSize: '1rem',
                    width: '7.375rem',
                    height: '2.5rem',
                    color: 'white !important',
                    background: '#2D7DFE !important',
                  }}>
                  Next
                </Button>
              </div>
            </div>
          </div>
        </Paper>
      </div>
    </>
  );
};

export default EducationCertificationDetails;
