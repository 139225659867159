import { AlertColor, Paper } from '@mui/material';
import '../../../tad-revamp-ui/theme/variables.scss';
import UpCommingInterviews from './up-comming-interviews';
import ToDo from './to-do';
import HiringPipeLine from './hiring-pipe-line';
import SjdProgress from './sjd-progress';
import RecentJobDefinitions from './recent-job-definitions';
import Analytics from './analytics/analytics';
import ProfilerRatingAndAudit from './profiler-rating-and-audit';
import JobsClosedByRecruiter from './jobs-closed-by-recruiter';
import RecruiterPerformance from './recruiter-performance';
import './new-dashboard.scss';
import NotificationBar from './notification-bar';
import { useEffect, useState } from 'react';
import Notify from '../../common/notify';
import DashboardOverlay from './dashboard-overview';
import { TADMODULE } from '../../../constants/common';
import { isTadPermissions } from '../../common/commonUtil';
import useNavigate from '../../../common/useNavigate';
import globalObject from '../../../common/global-variables';

const TadDashboard = () => {
  const isLoginSuccefull = localStorage.getItem('login');
  const [navigate] = useNavigate();
  const [showToast, setShowToast] = useState(false);
  const [toastMsg, setToastMsg] = useState({
    severity: 'success',
    msg: '',
  } as {
    severity: AlertColor;
    msg: string;
  });
  useEffect(() => {
    if (isLoginSuccefull === 'true') {
      setShowToast(true);
      setToastMsg({
        severity: 'success',
        msg: 'Successfully Logged In',
      });
      localStorage.removeItem('login');
    }
    if (!isTadPermissions(TADMODULE.DASHBOARD, 'read')) {
      const existingConfig = localStorage.getItem('breadcrumb-config');
      if (existingConfig) {
        const parsed = JSON.parse(existingConfig);
        console.log(parsed);
        console.log(parsed[parsed.length - 1].label);
        navigate([
          {
            label: parsed[parsed.length - 1].label,
            link: parsed[parsed.length - 1].link,
          },
        ]);
      }
    }
  }, []);

  const handleToastClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowToast(false);
  };

  return (
    <div className='main-div'>
      <div className='dashboard-header'>
        <span className='heading'>{`Hello ${globalObject.userObject.firstName}, Good Morning!`}</span>
      </div>
      <div>
        <NotificationBar />
      </div>
      <div style={{ position: 'relative', display: 'flex', flexDirection: 'column', gap: '2rem' }}>
        <DashboardOverlay open={true} header={'dashboard'} />
        <div className='dashboard-container'>
          <div style={{ width: '33%' }}>
            <UpCommingInterviews />
          </div>

          <div style={{ width: '33%' }}>
            <ToDo />
          </div>
          <div style={{ width: '33%' }}>
            <HiringPipeLine />
          </div>
        </div>
        <div className='dashboard-container'>
          <div style={{ width: '67%' }}>
            <Paper sx={{ height: '26.125rem', width: '100%' }}>
              <SjdProgress />
            </Paper>
          </div>
          <div style={{ width: '33%' }}>
            <Paper sx={{ height: '26.125rem', width: '100%' }}>
              <RecentJobDefinitions />
            </Paper>
          </div>
        </div>
        <div className='dashboard-container'>
          <div style={{ width: '66%' }}>
            <Paper sx={{ height: '100%' }}>
              <Analytics />
            </Paper>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '1.5rem', width: '32.5%' }}>
            <div style={{ height: '50%' }}>
              <Paper sx={{ height: '100%' }}>
                <ProfilerRatingAndAudit />
              </Paper>
            </div>
            <div style={{ height: '50%' }}>
              <Paper sx={{ height: '100%' }}>
                <JobsClosedByRecruiter />
              </Paper>
            </div>
          </div>
        </div>
        <div className='dashboard-container'>
          <div style={{ width: '66.2%' }}>
            <Paper className='paper'>
              <RecruiterPerformance />
            </Paper>
          </div>
        </div>
      </div>

      <Notify
        open={showToast}
        severity={toastMsg.severity}
        onClose={handleToastClose}
        message={toastMsg.msg}></Notify>
    </div>
  );
};

export default TadDashboard;
