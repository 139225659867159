import { Breadcrumbs, Link } from '@mui/material';
import { useState, useEffect } from 'react';
import { BreadCrumbConfig } from '../../../../common/commonUtil';
import useNavigate from '../../../../common/useNavigate';

interface Props {
  title?: string;
  lastModifiedOn?: string;
}

const TadPageHeader = ({ title, lastModifiedOn }: Props) => {
  const [links, setLinks] = useState<BreadCrumbConfig[]>(() => {
    const storedConfig = localStorage.getItem('breadcrumb-config');
    return storedConfig ? JSON.parse(storedConfig) : [];
  });
  const [, redirect] = useNavigate();

  useEffect(() => {
    try {
      const storedConfig = localStorage.getItem('breadcrumb-config');
      if (storedConfig) {
        const parsedConfig: BreadCrumbConfig[] = JSON.parse(storedConfig);
        setLinks(removeDuplicateLinks(parsedConfig));
      }
    } catch (error) {
      console.error('Failed to load breadcrumbs from localStorage:', error);
      localStorage.removeItem('breadcrumb-config');
    }
  }, []);

  const getLastModifiedDate = (date?: string) => {
    if (date) {
      const lastUpdatedDate = new Date(date);
      return calculateLastUpdated(lastUpdatedDate);
    } else {
      const currentDate = new Date();
      return calculateLastUpdated(currentDate);
    }
  };

  const removeDuplicateLinks = (breadcrumbLinks: BreadCrumbConfig[]) => {
    const seenLinks = new Map<string, BreadCrumbConfig>();
    breadcrumbLinks.forEach((link) => {
      if (!seenLinks.has(link.link)) {
        seenLinks.set(link.link, link);
      }
    });
    return Array.from(seenLinks.values());
  };

  const handleClick = (index: number) => {
    const updatedLinks = links.slice(0, index + 1);
    localStorage.setItem('breadcrumb-config', JSON.stringify(updatedLinks));
    redirect(updatedLinks[index].link);
  };

  // const navigateToDashboard = () => {
  //   const dashboardLink: BreadCrumbConfig = { label: 'Dashboard', link: '/dashboard' };
  //   setLinks([dashboardLink]);
  //   localStorage.setItem('breadcrumb-config', JSON.stringify([dashboardLink]));
  //   navigate([dashboardLink], true);
  // };

  const calculateLastUpdated = (lastDate: Date): string => {
    const now = new Date();
    const nowDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const lastUpdatedDate = new Date(
      lastDate.getFullYear(),
      lastDate.getMonth(),
      lastDate.getDate(),
    );
    const timeDiff = nowDate.getTime() - lastUpdatedDate.getTime();
    const daysAgo = timeDiff / (1000 * 60 * 60 * 24);
    const formattedDate = lastDate.toISOString().slice(0, 16).replace('T', ' . ');
    if (daysAgo === 0) {
      return `Today (${formattedDate})`;
    } else if (daysAgo === 1) {
      return `1 day ago (${formattedDate})`;
    } else {
      return `${Math.floor(daysAgo)} days ago (${formattedDate})`;
    }
  };

  return (
    <div
      className='breadcrumb-block'
      id='breadcrumb-block'
      style={{
        background: 'var(--Secondary-colors-Grey-3)',
        display: 'flex',
        marginTop: '1rem',
        justifyContent: 'space-between',
      }}>
      <div style={{ display: 'flex' }}>
        <Breadcrumbs aria-label='breadcrumb'>
          {/* <Link
            underline='always'
            color='primary'
            onClick={navigateToDashboard}
            sx={{
              cursor: 'pointer',
              fontWeight: 'bold',
              '&:hover': { textDecoration: 'underline' },
            }}>
            DASHBOARD
          </Link> */}

          {links.map((link, index) => (
            <Link
              key={index}
              underline={index === links.length - 1 ? 'none' : 'always'}
              color={index === links.length - 1 ? 'inherit' : 'primary'}
              onClick={() => handleClick(index)}
              sx={{
                cursor: index === links.length - 1 ? 'default' : 'pointer',
                fontWeight: index === links.length - 1 ? 'normal' : 'bold',
                '&:hover': { textDecoration: 'underline' },
              }}
              aria-current={index === links.length - 1 ? 'page' : undefined}>
              {link.label}
            </Link>
          ))}

          {/* {title && (
            <Typography
              sx={{
                cursor: 'default',
                fontWeight: 'bold',
                color: 'gray',
              }}>
              {title}
            </Typography>
          )} */}
        </Breadcrumbs>
      </div>
      {(title == 'Sjd Details' || title == 'Candidate Details') && (
        <div style={{ textAlign: 'right', marginLeft: 'auto' }}>
          <span>Last updated on: {getLastModifiedDate(lastModifiedOn)}</span>
        </div>
      )}
    </div>
  );
};

export default TadPageHeader;
