import {
  AlertColor,
  // Backdrop,
  Box,
  Button,
  Collapse,
  Divider,
  Drawer,
  IconButton,
  InputBase,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ReactComponent as ArrowIcons } from './../../assets/sjd/chevron_right.svg';
import { ReactComponent as MoreIcon } from './../../assets/dashboard-assets/more_vert.svg';
import { ReactComponent as AddIcon } from './../../assets/sjd/add.svg';
import { ReactComponent as Close } from './../../assets/sjd/close.svg';
import Edit from './../../assets/sjd/Edit.svg';
import { Add, Done, Search } from '@mui/icons-material';
// import tadLoader from './../../assets/common-assets/Whole animation.gif';
import Notify from '../../common/notify';
import { LookupTypes } from '../../../common/lookupTypes';
import { TADROLES } from '../../../constants/common';
import { getIdFromName, getInitials } from '../../../common/commonUtil';
import { getAllTeams, saveTeam, updateTeam } from '../../service/tadTeamService';
import globalObject from '../../../common/global-variables';
import { formatDate } from '../../common/commonUtil';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { AxiosResponse } from 'axios';
import TadScreenLoader from '../common/tad-screen-loader';

type User = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  roles?: { name: string }[];
  phoneNumber?: string;
  active?: boolean;
  profilePicture: string;
};

const Teams = () => {
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [rowData, setRowData] = useState([] as any[]);
  const [rowsToDisplay, setRowsToDisplay] = useState(5);
  const [displayRowData, setDisplayRowData] = useState(rowData);
  const [subTableFlag, setSubTableFlag] = useState(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMsg, setToastMsg] = useState({
    severity: 'success',
    msg: '',
  } as {
    severity: AlertColor;
    msg: string;
  });
  const [selectedTeam, setSelectedTeam] = useState({} as any);
  const [editAction, setEditAction] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const actionsList = [
    // {
    //   name: 'Add Hiring Team',
    //   icon: AddHiringTeam,
    // },
    { name: 'Edit', icon: Edit },
    // { name: 'Share', icon: Share },
    // { name: 'Export', icon: Export },
    // { name: 'Duplicate', icon: Duplicate },
    // { name: 'Put On Hold', icon: PutOnHold },
    // { name: 'Publish', icon: Publish },
    // { name: 'Archive', icon: Archive },
  ];

  const showActionsList = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeActionsMenu = (flag: boolean) => {
    setOpen(flag);
    setEditAction(true);
    setAnchorEl(null);
  };
  // const [editTeam,setEditTeam] = useState(false);
  const paginatedJobs = rowsToDisplay == -1 ? rowData : displayRowData.slice(0, rowsToDisplay);

  const startIndex = rowData.length === 0 ? 0 : 1;
  const endIndex = Math.min(rowsToDisplay, rowData.length);

  const handleToastClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowToast(false);
  };

  useEffect(() => {
    setLoading(true);
    getAllTeams()
      .then((resp) => {
        setLoading(false);
        setRowData(resp.data);
        setDisplayRowData(resp.data);
      })
      .catch(() => {
        setLoading(false);
        setShowToast(true);
        setToastMsg({
          severity: 'error',
          msg: 'Something went wrong!!',
        });
      });
  }, [reload]);

  const handleChangeRowsPerPage = (event: SelectChangeEvent<any>) => {
    const selectedValue = parseInt(event.target.value as string, 10);
    const isAll = selectedValue === -1;

    setRowsPerPage(isAll ? -1 : selectedValue);
    setRowsToDisplay(isAll ? rowData.length : Math.min(selectedValue, rowData.length));
  };

  const filteredUserData = (data: any, roles: any[]) => {
    return data.filter(
      (e: any) =>
        (roles.includes(TADROLES.RECRUITER) &&
          e.roles[0]?.id === getIdFromName(TADROLES.RECRUITER, LookupTypes.TAD_ROLE)) ||
        (roles.includes(TADROLES.EVALUATOR) &&
          e.roles[0]?.id === getIdFromName(TADROLES.EVALUATOR, LookupTypes.TAD_ROLE)) ||
        (roles.includes(TADROLES.LEAD) &&
          e.roles[0]?.id === getIdFromName(TADROLES.LEAD, LookupTypes.TAD_ROLE)) ||
        (roles.includes(TADROLES.RECRUITER_MANAGER) &&
          e.roles[0]?.id === getIdFromName(TADROLES.RECRUITER_MANAGER, LookupTypes.TAD_ROLE)),
    );
  };

  const DrawerList = () => {
    const [selectedUsers, setSelectedUsers] = useState<User[]>(
      editAction ? selectedTeam.teamMembers.map((e: any) => e.member) : [],
    );
    const [requestSearch, setRequestSearch] = useState('');
    const [users, setUsers] = useState([] as any[]);
    const [filteredUsers, setFilteredUsers] = useState([] as any[]);

    useEffect(() => {
      setUsers(globalObject.users);
      setFilteredUsers(globalObject.users);
    }, []);

    const HandleSearch = (searchedVal: string, array: any) => {
      let c = array;
      if (searchedVal && searchedVal.length) {
        c = c.filter(
          (candidate: any) =>
            ((candidate.firstName || candidate.lastName) &&
              `${candidate.firstName || ''} ${candidate.lastName || ''}`
                .toLowerCase()
                .includes(searchedVal.toLowerCase())) ||
            (candidate?.email &&
              candidate?.email.toLowerCase().includes(searchedVal.toLowerCase())),
        );
      }
      setFilteredUsers(c);
    };

    const teamValidations = Yup.object().shape({
      teamName: Yup.string()
        .max(1000, 'Name should not exceed 1000 characters')
        .required('Name is required'),
      recruitingManager: Yup.number(),
    });

    const formik = useFormik({
      initialValues: editAction
        ? {
            id: selectedTeam.id,
            teamName: selectedTeam.teamName || '',
            recruitingManager: selectedTeam.recruitingManager.id || '',
          }
        : {
            id: undefined,
            teamName: '',
            recruitingManager: '',
          },
      validationSchema: teamValidations,
      validateOnBlur: true,
      enableReinitialize: true,

      onSubmit: (values) => {
        teamApi(values);
      },
    });

    const addUser = (teamMember: any) => {
      const filteredItem = (prevSelected: any[]) => {
        const isSelected = prevSelected.some((user) => user.id === teamMember.id);
        if (isSelected) {
          return prevSelected.filter((user) => user.id !== teamMember.id);
        } else {
          return [...prevSelected, teamMember];
        }
      };
      setSelectedUsers((prevSelected) => filteredItem(prevSelected));
      // setSearchList((prevSelected) => filteredItem(prevSelected));
    };

    const teamApi = (values: any) => {
      setLoading(true);
      const teamMemberObj = selectedUsers.map((e) => ({ member: e, createdOn: new Date() }));
      const recruitingManager = users.filter((e) => e.id == values.recruitingManager)[0];
      const obj = {
        id: editAction ? values.id : undefined,
        teamName: values.teamName,
        recruitingManager: recruitingManager,
        teamMembers: teamMemberObj,
        createdOn: new Date(),
      };
      const apiCall: Promise<AxiosResponse<any, any>> = editAction
        ? updateTeam(obj)
        : saveTeam(obj);
      apiCall
        .then((resp) => {
          setLoading(false);
          setShowToast(true);
          setToastMsg({
            severity: 'success',
            msg: `<div style="textAlign: center">Team created successfully.<br/><br/><span style="color: red;">${resp.data.result?.join(
              '<br/>',
            )}</span></div>`,
          });
          formik.resetForm();
          setOpen(false);
          setReload(!reload);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          setShowToast(true);
          setToastMsg({
            severity: 'error',
            msg: 'Something Went Wrong!!',
          });
        });
    };

    return (
      <form
        onSubmit={formik.handleSubmit}
        style={{
          padding: '2rem',
          paddingBottom: 0,
          display: 'flex',
          flexDirection: 'column',
          gap: '2rem',
          height: '95%',
        }}>
        <div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              borderBottom: '1px solid #868686',
              paddingBottom: '0.5rem',
            }}>
            <Typography sx={{ fontWeight: 600, fontSize: '1.25rem' }}>Create Team</Typography>
            <IconButton style={{ marginLeft: '28rem' }} onClick={() => setOpen(false)}>
              <Close />
            </IconButton>
          </div>
          <div
            style={{ marginBottom: '0.8rem', padding: '1rem', borderBottom: '1px solid #868686' }}>
            <div style={{ display: 'flex', alignItems: 'center', margin: '1rem' }}>
              <Typography sx={{ width: '35%' }}>Team Name : </Typography>
              <TextField
                fullWidth
                className='input-field'
                placeholder='Enter Team Name...'
                value={formik.values.teamName}
                name='teamName'
                // onChange={(e) => formik.setFieldValue('teamName', e.target.value)}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {/* <TextField
                fullWidth
                className='input-field'
                placeholder='Enter Team Name...'
                name='teamName'
                value={name}
                onChange={(e) => setName(e.target.value)}
              /> */}
            </div>
            <div style={{ display: 'flex', alignItems: 'center', margin: '1rem' }}>
              <Typography sx={{ width: '35%' }}>Recruiting Manager : </Typography>
              <Select
                fullWidth
                className='input-field'
                name='recruitingManager'
                value={formik.values.recruitingManager}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}>
                {filteredUserData(users, [TADROLES.RECRUITER_MANAGER]).map((user: any) => (
                  <MenuItem key={user.id} value={user.id}>
                    {`${user.firstName} ${user.lastName}`}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </div>
          <div
            style={{ display: 'flex', justifyContent: 'space-between', gap: '5%', width: '100%' }}>
            <div style={{ width: '100%' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '0.8rem',
                  marginLeft: '1.06rem',
                }}>
                <Search />
                <InputBase
                  value={requestSearch}
                  onChange={(e) => {
                    setRequestSearch(e.target.value);
                    HandleSearch(e.target.value, users);
                  }}
                  sx={{ ml: '10px', width: '100%' }}
                  placeholder='Search'
                  inputProps={{ 'aria-label': 'Search a keyword' }}
                />
              </div>
              <div>
                <Divider sx={{ display: 'flex' }} />
              </div>
            </div>
          </div>
        </div>
        <div>
          <Typography sx={{ fontWeight: 600, fontSize: '1.25rem', color: '#000000' }}>
            Recently Added Users
          </Typography>
        </div>
        <div style={{ maxHeight: '70%', overflow: 'auto' }}>
          {filteredUserData(filteredUsers, [
            TADROLES.RECRUITER,
            TADROLES.EVALUATOR,
            TADROLES.LEAD,
          ])?.map((user: any) => (
            <div
              key={user.id}
              style={{
                marginBottom: '0.63rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                backgroundColor: '#F1F4F8',
                height: '5.4375rem',
              }}>
              <div
                style={{
                  display: 'flex',
                  gap: '10px',
                  marginLeft: '1.25rem',
                  alignItems: 'center',
                }}>
                <div
                  className='user-icon'
                  style={{
                    backgroundColor: 'rgb(25, 118, 210)',
                    color: 'white',
                    marginBottom: 0,
                    height: '3rem',
                    width: '3rem',
                  }}>
                  {getInitials(user)}
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                  <span
                    style={{
                      color: '#1976D2',
                      textDecoration: 'underline',
                    }}>{`${user.firstName} ${user.lastName}`}</span>
                  <span>{user.roles[0]?.name}</span>
                </div>
              </div>
              <div style={{ marginRight: '1.25rem' }}>
                <IconButton onClick={() => addUser(user)}>
                  {selectedUsers.some((e) => e.id === user.id) ? <Done /> : <Add />}
                </IconButton>
              </div>
            </div>
          ))}
        </div>
        <div
          style={{
            display: 'flex',
            gap: '1rem',
            justifyContent: 'flex-end',
            padding: '1rem',
            borderTop: '1px solid #868686',
          }}>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button type='submit'>Add</Button>
        </div>
      </form>
    );
  };

  const SubTable = ({ index }: any) => {
    return (
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Table sx={{ margin: '0.5rem 0rem 1rem 0rem', width: '93%' }}>
          <TableHead>
            <TableRow>
              <TableCell>Member Name</TableCell>
              <TableCell>Role</TableCell>
              <TableCell>Created On</TableCell>
              <TableCell>Status</TableCell>
              <TableCell sx={{ width: '5%' }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedJobs[index].teamMembers.map((member: any, index: any) => (
              <TableRow key={index}>
                <TableCell
                  sx={{
                    borderBottom: 'none',
                  }}>{`${member.member.firstName} ${member.member.lastName}`}</TableCell>
                <TableCell sx={{ borderBottom: 'none' }}>{member.member.roles[0].name}</TableCell>
                <TableCell sx={{ borderBottom: 'none' }}>{formatDate(member.createdOn)}</TableCell>
                <TableCell sx={{ borderBottom: 'none' }}>
                  {member.member.active ? 'Active' : 'In Active'}
                </TableCell>
                <TableCell sx={{ borderBottom: 'none' }}>
                  <IconButton>
                    <MoreIcon />
                    {/* <MenuItem onClick={()=>editTeam(member)}>Edit</MenuItem> */}
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    );
  };

  return (
    <div style={{ width: '100%', gap: '1.87rem', display: 'flex', flexDirection: 'column' }}>
      <div
        className='paper'
        style={{
          height: '8.8125rem',
          width: '100%',
        }}>
        <Paper
          className='new-job-requisition'
          sx={{
            width: '100%',
            height: '100%',
          }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginLeft: '1.88rem',
              gap: '7px',
            }}>
            <span style={{ fontSize: '1.25rem', fontWeight: 600 }}>Teams</span>
            <span
              style={{
                fontSize: '0.875rem',
                fontWeight: 400,
                color: 'var(--Secondary-Grey-1, var(--Secondary-colors-Grey, #868686))',
              }}>
              Create or manage teams
            </span>
          </div>
          <div
            style={{
              display: 'flex',
              marginRight: '2.5rem',
              alignItems: 'center',
            }}>
            <Button
              className='button'
              fullWidth
              sx={{
                textTransform: 'none',
                fontWeight: 400,
                fontSize: '1rem',
                height: '3.125rem',
                color: 'white !important',
                background: '#1976D2 !important',
              }}
              onClick={() => {
                setOpen(true);
                setEditAction(false);
              }}>
              <AddIcon style={{ width: '15px', height: '15px', paddingRight: '4px' }} />
              {'Create Team'}
            </Button>
          </div>
        </Paper>
      </div>
      <div
        className='paper'
        style={{
          width: '100%',
        }}>
        <Paper
          sx={{
            width: '100%',
            height: '100%',
          }}>
          <Box sx={{ padding: '50px' }}>
            {' '}
            <TableContainer>
              <Table sx={{ minWidth: 650 }} aria-label='simple table'>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ borderBottom: '2px solid #A2A2A2' }}>Team Name</TableCell>
                    <TableCell sx={{ borderBottom: '2px solid #A2A2A2' }}>
                      Recruiting Manager
                    </TableCell>
                    {/* <TableCell>Created By</TableCell> */}
                    <TableCell sx={{ borderBottom: '2px solid #A2A2A2' }}>Created On</TableCell>
                    <TableCell sx={{ borderBottom: '2px solid #A2A2A2', width: '5%' }}>
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paginatedJobs.length > 0 ? (
                    paginatedJobs.map((team: any, index: any) => (
                      <React.Fragment key={index}>
                        <TableRow
                          sx={{
                            backgroundColor: subTableFlag == index ? '#F1F4F8' : 'white',
                            marginTop: '0.5rem',
                          }}>
                          <TableCell sx={{ width: '30%' }}>
                            <IconButton
                              onClick={() => setSubTableFlag(subTableFlag == index ? null : index)}>
                              <ArrowIcons
                                className={subTableFlag === index ? 'arrowRight' : 'arrowLeft'}
                              />
                            </IconButton>
                            {team.teamName}
                          </TableCell>
                          <TableCell sx={{ width: '30%' }}>
                            {`${team.recruitingManager.firstName} ${team.recruitingManager.lastName}`}
                          </TableCell>
                          <TableCell sx={{ width: '30%' }}>{formatDate(team.createdOn)}</TableCell>
                          <TableCell>
                            <IconButton
                              onClick={(e) => {
                                setSelectedTeam(team);
                                showActionsList(e);
                              }}>
                              <MoreIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ padding: 0 }} colSpan={6}>
                            <Collapse in={subTableFlag == index}>
                              <SubTable index={index} />
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={4} sx={{ textAlign: 'center' }}>
                        No Records To Display
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <div
              style={{
                // width: '100%',
                marginTop: '30px',
                display: 'flex',
                alignItems: 'center',
                gap: '2rem',
                justifyContent: 'flex-end',
              }}>
              <span>
                Showing {startIndex}-{endIndex} of {rowData.length}
              </span>
              <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <label htmlFor='rows-per-page'>Rows</label>
                <Select
                  sx={{
                    alignItems: 'center',
                    '& .MuiSelect-icon': {
                      top: 'calc(50% - 0.8em)',
                    },
                  }}
                  value={rowsPerPage}
                  onChange={handleChangeRowsPerPage}
                  IconComponent={ArrowIcons}
                  style={{ height: '1.5rem' }}>
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={-1}>All</MenuItem>
                </Select>
              </div>
            </div>
          </Box>
        </Paper>
      </div>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => closeActionsMenu(false)}
        slotProps={{
          paper: {
            sx: {
              border: '1px solid #e5e5e5',
              boxShadow: '0px 4px 6px -1px rgba(0, 0, 0, 0.5), 0px 2px 4px -1px rgba(0, 0, 0, 0.5)',
            },
          },
        }}>
        {actionsList.map((action, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              closeActionsMenu(true);
            }}>
            <ListItemIcon>
              <img src={action.icon} alt={action.name} style={{ width: '20px', height: '20px' }} />
            </ListItemIcon>
            <ListItemText primary={action.name} />
          </MenuItem>
        ))}
      </Menu>
      <Drawer
        sx={{ width: '21.0625rem', flexShrink: 0 }}
        anchor='right'
        open={open}
        onClose={() => setOpen(false)}>
        <DrawerList />
      </Drawer>
      <TadScreenLoader loading={loading} />
      <Notify
        open={showToast}
        severity={toastMsg.severity}
        onClose={handleToastClose}
        message={toastMsg.msg}></Notify>
    </div>
  );
};

export default Teams;
