import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { debounce } from '@mui/material/utils';
import { getLocations } from '../../../service/commonService';

interface PlaceType {
  label: string;
  value: string;
}

interface Prop {
  onSelect: (selectedValues?: string[]) => void;
  initialValue?: string[];
  width?: string;
  error?: any;
}

export default function NewLocationAutoComplete({ onSelect, initialValue = [], error }: Prop) {
  const [value, setValue] = React.useState<PlaceType[]>([]);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState<readonly PlaceType[]>([]);

  const fetch = React.useMemo(
    () =>
      debounce((request: { input: string }, callback: (results?: PlaceType[]) => void) => {
        getLocations(request.input).then((resp: any) => {
          let data = resp.data.results
            .filter((item: any) => !!item.city)
            .map((item: any) => {
              return {
                label: `${item.city}, ${item.country}`,
                value: `${item.city}, ${item.country}`,
              };
            });
          data = data.filter(
            (value: any, index: number, self: any) =>
              index === self.findIndex((t: PlaceType) => t.label === value.label),
          );
          callback(data);
        });
      }, 400),
    [],
  );

  React.useEffect(() => {
    if (inputValue) {
      fetch({ input: inputValue }, (results?: PlaceType[]) => {
        if (results) setOptions([...results]);
      });
    }
  }, [inputValue, fetch]);

  React.useEffect(() => {
    if (initialValue.length > 0) {
      const initialSelectedOptions = initialValue.map((val) => ({
        label: val,
        value: val,
      }));
      setValue(initialSelectedOptions);
    } else {
      setValue([]);
    }
  }, [initialValue]);

  return (
    <Autocomplete
      multiple
      id='location-autocomplete'
      size='small'
      sx={{ width: '100%' }}
      getOptionLabel={(option) => option.label}
      filterOptions={(options, { inputValue }) => {
        return options.filter((option) =>
          option.label.toLowerCase().startsWith(inputValue.toLowerCase()),
        );
      }}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      defaultValue={initialValue.map((val) => ({ label: val, value: val }))}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      noOptionsText='No locations'
      onChange={(event: any, newValue: PlaceType[]) => {
        setOptions([]);
        setValue(newValue);
        const formattedString = newValue.map((val) => val.value);
        onSelect(formattedString);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          error={error}
          placeholder='Start typing to search...'
          label=''
          fullWidth
        />
      )}
      classes={{
        popper: 'custom-popper',
      }}
    />
  );
}
