import axiosClient from '../../common/axiosClient';

export async function verifyEmail(email: any) {
  const flag = localStorage.getItem('switch');
  return axiosClient.post('/security/verify-email', {
    flag,
    email,
  });
}

export async function resetPassword(req: any) {
  return axiosClient.patch('/security/reset-password', req);
}
