import { Backdrop } from '@mui/material';
import React from 'react';
import tadLoader from './../../assets/common-assets/Whole animation.gif';

interface Props {
  loading: any;
}

const TadScreenLoader = ({ loading }: Props) => {
  return (
    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          background: '#fff',
          alignItems: 'center',
          paddingBottom: '10px',
        }}>
        <img src={tadLoader} alt='Loading' style={{ width: '14rem', height: '7rem' }} />
        <span
          style={{
            color: 'var(--Primary-Dark-grey-500,#333)',
            fontFamily: 'Roboto',
            fontSize: 'var(--Font-size-small, 1.75rem)',
            fontStyle: 'normal',
            fontWeight: '500',
            letterSpacing: '0.42rem',
            textAlign: 'center',
          }}>
          LOADING...
        </span>
      </div>
    </Backdrop>
  );
};

export default TadScreenLoader;
