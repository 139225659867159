import { Breadcrumbs, Link, Typography } from '@mui/material';
import './page-header.scss';
import { useEffect, useState } from 'react';
import useNavigate from './../../../common/useNavigate';
import { BreadCrumbConfig, isUserRole } from '../../../common/commonUtil';
import { ROLES } from '../../../constants/common';
import globalObject from '../../../common/global-variables';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { postPowerBiAccessDetails } from '../../../service/candidateService';

const PageHeader = ({ title }: { title: string }) => {
  const [links, setLinks] = useState([] as BreadCrumbConfig[]);
  const [, redirect] = useNavigate();

  useEffect(() => {
    const existingConfig = localStorage.getItem('breadcrumb-config');
    if (existingConfig) {
      setLinks(JSON.parse(existingConfig));
    }
  }, []);

  const handleClick = (index: number) => {
    const existingConfig = localStorage.getItem('breadcrumb-config');
    if (existingConfig) {
      const parsed = JSON.parse(existingConfig);
      localStorage.setItem('breadcrumb-config', JSON.stringify(parsed.slice(0, index + 1)));
      redirect(parsed[index].link);
    }
  };
  const handlePowerBiAccess = (name: string) => {
    const request = {
      email: name,
    };
    postPowerBiAccessDetails(request);
  };

  return (
    <div
      className='breadcrumb-block'
      id='breadcrumb-block'
      style={{
        display: 'flex',
        // position: 'sticky',
        top: 52,
        zIndex: 100,
        justifyContent: 'space-between',
      }}>
      <div style={{ display: 'flex' }}>
        <Typography variant='h1'>
          {/* {title} */}
          &nbsp;&nbsp;&nbsp;&nbsp;
        </Typography>
        {!isUserRole(ROLES.EVALUATOR, true) &&
          !isUserRole(ROLES.MANAGER, true) &&
          !isUserRole(ROLES.INTERN, true) && (
            <Breadcrumbs aria-label='breadcrumb'>
              {links.slice(0, links.length - 1).map((link, index) => (
                <Link
                  key={index}
                  underline='always'
                  color='primary'
                  onClick={() => handleClick(index)}
                  sx={{ cursor: 'pointer' }}>
                  {link.label}
                </Link>
              ))}
              {links && links.length > 0 && (
                <Link underline='none' color='inherit'>
                  {links[links.length - 1].label}
                </Link>
              )}
            </Breadcrumbs>
          )}
      </div>
      <div style={{ display: 'flex', gap: '1rem' }}>
        {title === 'Dashboard' &&
          !isUserRole(ROLES.EVALUATOR, true) &&
          !isUserRole(ROLES.MANAGER, true) &&
          !isUserRole(ROLES.INTERN, true) && (
            <div>
              <Link
                sx={{ cursor: 'pointer', marginRight: '40px' }}
                onClick={() => {
                  handlePowerBiAccess(globalObject.userObject.email);
                  window.open(globalObject.properties.power_bi_access_url, '_blank');
                }}>
                <span style={{ fontWeight: '700' }}>Data Analytics</span>
                <OpenInNewIcon sx={{ height: '15px' }} />
              </Link>
            </div>
          )}
      </div>
    </div>
  );
};

export default PageHeader;
