import { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Select,
  MenuItem,
  Box,
  Typography,
  Button,
  IconButton,
  Drawer,
  List,
  TextField,
  AlertColor,
  ListItemText,
  Menu,
} from '@mui/material';
import { Add } from '@mui/icons-material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { toUpperFirstChar } from '../../common/commonUtil';
import globalObject from '../../../common/global-variables';
import {
  addNewRole,
  getPermissionsForUser,
  postPermissions,
  updateRoleActions,
} from '../../../service/RolesAndPermissionsService';
import { LookupTypes } from '../../../common/lookupTypes';
import { getIdFromName } from '../../../common/commonUtil';
import Notify from '../../common/notify';
import { TADROLES } from '../../../constants/common';
import { ReactComponent as DoneIcon } from '../../assets/user-settings/done.svg';
import TadScreenLoader from '../common/tad-screen-loader';

interface PermissionValue {
  read: number;
  write: number;
  delete: number;
}

interface Permission {
  id: number;
  value: PermissionValue;
  subModules: any;
}

interface PermissionsData {
  [moduleName: string]: Permission;
}

// interface CheckedState {
//   [key: string]: boolean;
// }

const UserPermission = () => {
  const recruiter: any = localStorage.getItem('role') ? localStorage.getItem('role') : 'employee';
  const [selectedRole, setSelectedRole] = useState(recruiter);
  const [open, setOpen] = useState(false);
  const [permissionsData, setPermissionsData] = useState<PermissionsData>(
    globalObject.permissions[selectedRole] || [],
  );
  const [loading, setLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMsg, setToastMsg] = useState({
    severity: 'success',
    msg: '',
  } as {
    severity: AlertColor;
    msg: string;
  });
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  // const [checkedState, setCheckedState] = useState<Record<string, any>>(() => {
  //   const initialState = Object.entries(globalObject.roleActions[selectedRole]).reduce(
  //     (acc, [key, value]) => {
  //       acc[key] = !!value;
  //       return acc;
  //     },
  //     {} as Record<string, any>,
  //   );
  //   return initialState;
  // });
  // const [checkedStateWrite, setCheckedStateWrite] = useState<CheckedState>({});
  // const [checkedStateDelete, setCheckedStateDelete] = useState<CheckedState>({});

  // const handleCheckboxChange = (subModuleId: string, isChecked: boolean, type: string) => {
  //   if (type == 'read') {
  //     setCheckedState((prevState) => ({
  //       ...prevState,
  //       [subModuleId]: isChecked,
  //     }));
  //   } else if (type == 'write') {
  //     setCheckedStateWrite((prevState) => ({
  //       ...prevState,
  //       [subModuleId]: isChecked,
  //     }));
  //   } else if (type == 'delete') {
  //     setCheckedStateDelete((prevState) => ({
  //       ...prevState,
  //       [subModuleId]: isChecked,
  //     }));
  //   }
  // };

  useEffect(() => {
    localStorage.setItem('role', selectedRole);
  }, [selectedRole]);

  // const handleClick = (event: React.MouseEvent<HTMLElement>, module: any) => {
  //   setAnchorEl(event.currentTarget);
  //   setModuleName(module);
  // };
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  const handleCheckboxChange = (key: string) => {
    const updatedActions = {
      ...globalObject.roleActions[selectedRole],
      [key]: !globalObject.roleActions[selectedRole][key],
    };
    setLoading(true);
    updateRoleActions(updatedActions).then((res) => {
      globalObject.roleActions[selectedRole] = res.data.result;
      setLoading(false);
    });
  };

  const handlePermissionChange = (
    moduleName: string,
    type: keyof PermissionValue,
    value: number,
  ) => {
    setPermissionsData((prevPermissions: any) => {
      const updatedPermissions: PermissionsData = {
        ...prevPermissions,
        [moduleName]: {
          ...prevPermissions[moduleName],
          value: {
            ...prevPermissions[moduleName].value,
            [type]: value,
          },
        },
      };
      const previousPermissions = prevPermissions;
      setLoading(true);
      postPermissions(updatedPermissions, selectedRole, null, null, null, null, null, true)
        .then((_res) => {
          refreshGlobalObject();
        })
        .catch(() => {
          setPermissionsData(previousPermissions);
          setLoading(false);
        });

      return updatedPermissions;
    });
  };

  useEffect(() => {
    const rolePermissions = globalObject.permissions[selectedRole] || [];
    setPermissionsData(rolePermissions);
  }, [selectedRole]);

  const refreshGlobalObject = () => {
    getPermissionsForUser(true)
      .then((res) => {
        globalObject.permissions = res.data;
        const permission = res.data;
        setPermissionsData(permission[selectedRole]);
        setLoading(false);
      })
      .catch((_err) => {
        setLoading(false);
      });
  };

  const AddNewRole = () => {
    const [roleName, setRoleName] = useState('');
    const [clonedRole, setClonedRole] = useState(getIdFromName('employee', LookupTypes.TAD_ROLE));

    const handleRole = () => {
      setLoading(true);
      addNewRole(roleName, clonedRole)
        .then((res) => {
          setLoading(false);
          setShowToast(true);
          setToastMsg({ severity: 'success', msg: res.data.message });
          refreshGlobalObject();
          window.location.reload();
        })
        .catch(() => {
          setLoading(false);
          setShowToast(true);
          setToastMsg({ severity: 'error', msg: 'Something went wrong!' });
        });
    };

    return (
      <Box role='presentation' sx={{ width: '30.0625rem', height: '64rem' }}>
        <List sx={{ padding: '3.19rem 1.88rem 0rem 1.69rem' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography sx={{ fontWeight: 600, fontSize: '1.25rem' }}>Add Role</Typography>
          </div>
          <div
            style={{
              gap: '24.56rem',
              alignItems: 'center',
              marginBottom: '1.17rem',
            }}>
            <hr style={{ background: '#868686', marginTop: '10px' }} />
          </div>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
            <span style={{ marginRight: '0.5rem' }}>Role Name : </span>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <TextField
                placeholder='Role Name...'
                sx={{ width: '17rem' }}
                className='input-field'
                value={roleName}
                onChange={(e) => setRoleName(e.target.value)}></TextField>
              {clonedRole && clonedRole.length > 0 && (
                <span style={{ color: 'red' }}>Required</span>
              )}
            </div>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
            <span style={{ marginRight: '0.5rem' }}>Select Role : </span>
            <Select
              value={clonedRole}
              onChange={(e) => {
                setClonedRole(e.target.value);
              }}
              displayEmpty
              sx={{ width: '17rem', height: '2.5rem' }}>
              <MenuItem value='' disabled selected>
                Roles
              </MenuItem>
              {globalObject.lookupvalues[LookupTypes.TAD_ROLE]?.map((role: any) => (
                <MenuItem key={role.id} value={role.id}>
                  {toUpperFirstChar(role.name)}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div style={{ gap: '20px', display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              className='button'
              sx={{
                width: '8.25rem',
                height: '2.5rem',
                borderRadius: '5px',
                border: '1px solid #2D7DFE',
                backgroundColor: '#FFF',
                color: 'black',
                textTransform: 'none',
                fontSize: '1rem',
                fontWeight: 400,
              }}
              onClick={() => {
                setOpen(false);
              }}>
              Cancel
            </Button>
            <Button
              className='button'
              sx={{
                textTransform: 'none',
                fontWeight: 400,
                fontSize: '1rem',
                width: '8.25rem',
                height: '2.5rem',
                marginRight: '2rem',
                color: 'white !important',
                background: '#2D7DFE !important',
              }}
              onClick={() => {
                handleRole();
              }}
              type='submit'>
              Save
            </Button>
          </div>
        </List>
      </Box>
    );
  };

  return (
    <div style={{ padding: '30px' }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: 2,
        }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ gap: '1rem', marginLeft: '10px' }}>
            <Typography sx={{ marginBottom: '10px' }}>Select Role</Typography>
            <Select
              value={selectedRole}
              onChange={(e: any) => {
                setSelectedRole(e.target.value);
                // setCheckedState({});
                // setCheckedStateDelete({});
                // setCheckedStateWrite({});
              }}
              displayEmpty
              sx={{ width: '17rem', height: '2.5rem' }}>
              <MenuItem value='' disabled selected>
                Roles
              </MenuItem>
              {globalObject.lookupvalues[LookupTypes.TAD_ROLE]
                ?.filter((e: any) => e.id !== getIdFromName(TADROLES.ADMIN, LookupTypes.TAD_ROLE))
                .map((role: any) => (
                  <MenuItem key={role.id} value={role.name}>
                    {toUpperFirstChar(role.name)}
                  </MenuItem>
                ))}
            </Select>
          </div>
          {
            <Button sx={{ marginTop: '30px', marginLeft: '50px' }} onClick={() => setOpen(true)}>
              <Add />
              <Typography>Add Role</Typography>
            </Button>
          }
        </Box>
      </Box>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell className='user-text'>Features</TableCell>
            <TableCell className='user-text'>Read</TableCell>
            <TableCell className='user-text'>Write</TableCell>
            <TableCell className='user-text'>Delete</TableCell>
            <TableCell className='user-text'>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {globalObject.lookupvalues[LookupTypes.TAD_MODULE].map((module: any) => {
            const permission = permissionsData[module.name];
            return (
              <TableRow key={module.id}>
                <TableCell>
                  <div>
                    <div>{module.name}</div>
                  </div>
                </TableCell>
                <TableCell key={module.id}>
                  <Select
                    value={permission?.value?.read || 4}
                    disabled={!permission}
                    onChange={(e: any) => {
                      handlePermissionChange(module.name, 'read', Number(e.target.value));
                    }}
                    displayEmpty
                    sx={{ minWidth: 120 }}>
                    {globalObject.lookupvalues[LookupTypes.TAD_PERMISSION_TYPE].map(
                      (permType: any) => (
                        <MenuItem key={permType.id} value={permType.id}>
                          {toUpperFirstChar(permType.name)}
                        </MenuItem>
                      ),
                    )}
                  </Select>
                </TableCell>
                <TableCell>
                  <Select
                    disabled={!permission}
                    value={permissionsData[module.name]?.value?.write || 4}
                    onChange={(e: any) => {
                      handlePermissionChange(module.name, 'write', Number(e.target.value));
                    }}
                    displayEmpty
                    sx={{ minWidth: 120 }}>
                    {globalObject.lookupvalues[LookupTypes.TAD_PERMISSION_TYPE].map(
                      (permType: any) => (
                        <MenuItem key={permType.id} value={permType.id}>
                          {toUpperFirstChar(permType.name)}
                        </MenuItem>
                      ),
                    )}
                  </Select>
                </TableCell>
                <TableCell>
                  <Select
                    disabled={!permission}
                    value={permissionsData[module.name]?.value?.delete || 4}
                    onChange={(e: any) => {
                      handlePermissionChange(module.name, 'delete', Number(e.target.value));
                    }}
                    displayEmpty
                    sx={{ minWidth: 120 }}>
                    {globalObject.lookupvalues[LookupTypes.TAD_PERMISSION_TYPE].map(
                      (permType: any) => (
                        <MenuItem key={permType.id} value={permType.id}>
                          {toUpperFirstChar(permType.name)}
                        </MenuItem>
                      ),
                    )}
                  </Select>
                </TableCell>
                <TableCell>
                  <IconButton
                    aria-controls='simple-menu'
                    onClick={(e) => {
                      if (module.name === 'Job Definitions') setAnchorEl(e.currentTarget);
                      else setAnchorEl(null);
                    }}
                    size='small'>
                    <MoreVertIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <Drawer
        sx={{ width: '21.0625rem', height: '64rem', flexShrink: 0 }}
        anchor='right'
        open={open}
        onClose={() => setOpen(false)}>
        <AddNewRole />
      </Drawer>
      {/* <Menu
        sx={{
          maxHeight: '300px',
          overflow: 'visible',
          maxWidth: '300px',
        }}
        anchorEl={anchorEl}
        id='simple-menu'
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
        <MenuItem disabled sx={{ fontSize: 'small' }}>
          Sub Modules :{' '}
        </MenuItem>
        {permissionsData[moduleName]?.subModules.length == 0 && (
          <MenuItem sx={{ fontSize: 'small' }} disabled>
            No Sub Modules
          </MenuItem>
        )}
        {permissionsData[moduleName]?.subModules.length > 0 &&
          permissionsData[moduleName]?.subModules?.map((subModule: any, index: number) => (
            <MenuItem
              key={index}
              value={subModule?.subModuleId.id}
              sx={{ fontSize: 'small', width: '100%', minWidth: '350px' }}>
              <div>
                <span style={{ width: '50%' }}>{subModule?.subModuleId.name}</span>
                <div style={{ width: '50%' }}>
                  Read:
                  <Checkbox
                    onChange={(e) => {
                      setLoading(true);
                      handleCheckboxChange(subModule?.subModuleId.id, e.target.checked, 'read');

                      postPermissions(
                        permissionsData,
                        selectedRole,
                        subModule?.module,
                        subModule?.subModuleId.id,
                        e.target.checked ?? false,
                        subModule?.write ?? false,
                        subModule?.delete ?? false,
                        true,
                      )
                        .then((_res) => {
                          refreshGlobalObject();
                        })
                        .catch(() => setLoading(false));
                    }}
                    checked={
                      checkedState[subModule?.subModuleId.id] ?? subModule?.isActive ?? false
                    }
                    size='small'
                    sx={{ padding: '0px 8px 0px 0px' }}
                  />
                  Write:
                  <Checkbox
                    onChange={(e: any) => {
                      setLoading(true);
                      handleCheckboxChange(subModule?.subModuleId.id, e.target.checked, 'write');
                      postPermissions(
                        permissionsData,
                        selectedRole,
                        subModule?.module,
                        subModule?.subModuleId.id,
                        subModule?.isActive ?? false,
                        e.target.checked ?? false,
                        subModule?.delete ?? false,
                        true,
                      )
                        .then((_res) => {
                          refreshGlobalObject();
                        })
                        .catch(() => setLoading(false));
                    }}
                    checked={
                      checkedStateWrite[subModule?.subModuleId.id] ?? subModule?.write ?? false
                    }
                    size='small'
                    sx={{ padding: '0px 8px 0px 0px' }}
                  />
                  Delete:
                  <Checkbox
                    onChange={(e) => {
                      setLoading(true);
                      handleCheckboxChange(subModule?.subModuleId.id, e.target.checked, 'delete');
                      postPermissions(
                        permissionsData,
                        selectedRole,
                        subModule?.module,
                        subModule?.subModuleId.id,
                        subModule?.isActive ?? false,
                        subModule?.write ?? false,
                        e.target.checked ?? false,
                        true,
                      )
                        .then((_res) => {
                          refreshGlobalObject();
                        })
                        .catch(() => setLoading(false));
                    }}
                    checked={
                      checkedStateDelete[subModule?.subModuleId.id] ?? subModule?.delete ?? false
                    }
                    size='small'
                    sx={{ padding: '0px 8px 0px 0px' }}
                  />
                </div>
              </div>
            </MenuItem>
          ))}
      </Menu> */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        slotProps={{
          paper: {
            sx: {
              padding: '0.5rem',
              border: '1px solid #e5e5e5',
              boxShadow:
                '0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)',
            },
          },
        }}>
        {globalObject.roleActions[selectedRole] &&
          Object.entries(globalObject.roleActions[selectedRole])
            .filter(([key]) => key !== 'role')
            .map(([key, value]: any, index: any) => (
              <MenuItem key={index} onClick={() => handleCheckboxChange(key)}>
                <ListItemText
                  primary={key}
                  sx={{
                    marginRight: '1.5rem',
                    '& span': {
                      fontWeight: value ? '600' : '400',
                    },
                  }}
                />
                {value && <DoneIcon style={{ color: '#1976d2' }} />}
              </MenuItem>
            ))}
      </Menu>
      <Notify
        open={showToast}
        severity={toastMsg.severity}
        onClose={() => setShowToast(false)}
        message={toastMsg.msg}></Notify>
      <TadScreenLoader loading={loading} />
    </div>
  );
};

export default UserPermission;
